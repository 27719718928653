const airConditionoptions = [
    { value: 'Centralized', label: 'Centralized' },
    { value: 'Room controlled', label: 'Room controlled' },
    { value: 'Temperature will be fixed as per Govt.Norms', label: 'Temperature will be fixed as per Govt.Norms' },
    { value: 'Window AC', label: 'Window AC' },
    { value: 'Split AC', label: 'Split AC' },
];

const heateroptions = [
    { value: 'Free', label: 'Free' },
    { value: 'Paid', label: 'Paid' },
];

const houseKeepingoptions = [
    { value: '24 hours', label: '24 hours' },
    { value: 'Limited duration', label: 'Limited duration' },
    { value: 'Daily', label: 'Daily' },
];

const roomDiningoptions = [
    { value: '24 hours', label: '24 hours' },
    { value: 'Limited duration', label: 'Limited duration' },
];

const mineralWateroptions = [
    { value: 'Free', label: 'Free' },
    { value: 'Paid', label: 'Paid' },
];

const roomserviceoptions = [
    { value: '24 hours', label: '24 hours' },
    { value: 'Limited duration', label: 'Limited duration' },
];

const wifioptions = [
    { value: 'Free', label: 'Free' },
    { value: 'Paid', label: 'Paid' },
];

const bathroomoptions = [
    { value: 'Shared', label: 'Shared' },
    { value: 'Private', label: 'Private' },
];
const amenitiesTypeOptions = [
    { value: 'Bathroom', label: 'Bathroom' },
    { value: 'Features', label: 'Features' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Bathroom', label: 'Bathroom' },
    { value: 'Features', label: 'Features' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Bathroom', label: 'Bathroom' },
    { value: 'Features', label: 'Features' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Bathroom', label: 'Bathroom' },
    { value: 'Features', label: 'Features' },
    { value: 'Entertainment', label: 'Entertainment' },
];
export { airConditionoptions };
export { heateroptions };
export { houseKeepingoptions };
export { roomDiningoptions };
export { mineralWateroptions };
export { roomserviceoptions };
export { wifioptions };
export { bathroomoptions };
export { amenitiesTypeOptions };