import React from 'react';
import './voucher.css';

export default function Voucher() {
    return (
        <>
            <div className="voucher-container shadow-md mx-auto py-3">
                <div className="flex  justify-between border-b-2 border-pink pb-3 px-3">
                    <div className="text-start">
                        <p className='fs-2 font-bold'>Hotelier Voucher</p>
                        <p className='fs-6 font-semibold'>Everland Hotel Booking ID - <b>NH12084307229844</b></p>
                        <p className='fs-6 font-semibold'>Vendor Booking ID - 0116813838</p>
                        <p className='fs-7 font-medium'>Booking Date - Wed, 07 February 24 09:16 PM</p>
                    </div>
                    <div className="">
                        <img className='hotel-logo' src="../SIte_logos/Hotel Everland-2xLogo.png" alt="" />
                    </div>
                </div>

                <div className="bs-mix-pink text-start flex flex-col gap-2 px-3 py-2">
                    <div className="">
                        <p className='tx-white fs-7 font-semibold'>This is Everland Hotel Corporate Channel Booking. hotel Gross charges have been collected for this Booking. Kindly issue GST invoice to the guest upon checkout as per below mentioned details.</p>
                    </div>
                    <div className="flex gap-4 items-start">
                        <div className="w-fit">
                            <p className='tx-white font-semibold fs-7'>Company Name</p>
                            <p className='tx-white opacity-80 font-semibold fs-8'>TATA AIG GENERAL INSURANCE CO LTD</p>
                        </div>
                        <div className="w-fit">
                            <p className='tx-white font-semibold fs-7'>	GSTN Number</p>
                            <p className='tx-white opacity-80 font-semibold fs-8'>24AABCA2951N1ZO</p>
                        </div>
                        <div className="col">
                            <p className='tx-white font-semibold fs-7'>Company Name</p>
                            <p className='tx-white opacity-80 font-semibold fs-8'>	207/1 OPP-SATYAM CORPORATE SQUARE ASTRAL HOUSE BH-RAJPATH CLUB OFF SG HIGHWAY AHMEDABAD Ahmedabad Gujarat 380059,AHMEDABAD,380059</p>
                        </div>
                    </div>
                </div>


                <div className="flex justify-between mt-3 px-3">
                    <div className="">
                        <p className='fs-6 font-semibold'> Dear Partner,</p>
                    </div>
                    <div className="">
                        <div className="w-fit bs-mix-pink py-1 px-2 rounded">
                            <p className='tx-white fs-7 font-semibold'>Confirm Booking & Get Payment</p>
                        </div>
                    </div>
                </div>


                <div className="text-start p-3">
                    <p className='fs-7'>For your reference, Everland Hotel Booking ID is <b>NH93252306727406</b> .</p>
                    <p className='fs-7'>PNR: <b>0116636261</b>.</p>
                    <b className='fs-7'>The amount payable to hotel for this booking is INR 7978.18 as per the details below.</b>
                    <p className='fs-7'>Kindly consider this e-voucher for booking confirmation with the following inclusions and services.</p>
                </div>

                <div className="mx-3 border-1 text-start">
                    <div className="flex justify-between px-3 py-1 bs-white border-b-1 ">
                        <p className='w-fit fs-6 font-semibold'>Payment Breakup</p>
                        <p className='w-fit fs-6 font-semibold'>All prices indicated below are in INR</p>
                    </div>
                    <div className="bs-white px-3 py-2 text-start">
                        <div className="border-b-[2px] px-1 border-pink">
                            <p className='fs-7 font-semibold'>TARIFF</p>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium">Hotel Sell Price</p>
                                <p className="fs-7 font-medium">1 Room x 2 Nights</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold">10260</p>
                            </div>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <p className="fs-7 font-medium">Extra Adult / Child Charges</p>
                            <p className="fs-7 font-semibold">0</p>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium">Voluntary Hotel Driven</p>
                                <p className="fs-7 font-medium">Coupon Discount</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold">1026.0</p>
                            </div>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <p className="fs-7 font-medium">Effective Hotel Sell Price</p>
                            <p className="fs-7 font-semibold"> 9234</p>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <p className="fs-7 font-medium">Hotel Taxes</p>
                            <p className="fs-7 font-semibold">1108.08</p>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <p className="fs-7 font-medium">(A) Hotel Gross Charges</p>
                            <p className="fs-7 font-semibold">10342.08</p>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <p className="fs-7 font-medium">MMT Commission</p>
                            <p className="fs-7 font-semibold">1846.8</p>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium">GST @ 18%</p>
                                <p className="fs-7 font-medium"> (Including IGST or (SGST & CGST))</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold">332.42</p>
                            </div>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium">(B) MMT Commission</p>
                                <p className="fs-7 font-medium">(Including GST)</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold">2179.22</p>
                            </div>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium">(C) TCS *</p>
                                <p className="fs-7 font-medium">(1% of 9234)</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold"> 92.34</p>
                            </div>
                        </div>

                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium"> (E) TDS **</p>
                                <p className="fs-7 font-medium"> (1.0% of 9234)</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold"> 92.34</p>
                            </div>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium">Hotel discount including tax considered in</p>
                                <p className="fs-7 font-medium">coupon promotion</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold">1149.12</p>
                            </div>
                        </div>
                        <div className="flex items-end justify-between opacity-85 border-b-[2px] px-1 border-pink py-1">
                            <div className="">
                                <p className="fs-7 font-medium">MMT to Pay Hotel</p>
                                <p className="fs-7 font-medium">(A-B-C)-E</p>
                            </div>
                            <div className="">
                                <p className="fs-7 font-semibold">7978.18</p>
                            </div>
                        </div>

                        <div className="">
                            <p className='font-semibold fs-9 opacity-80'>GST Regn. No - 06AADCM5146R1ZZ | PAN No - AADCM5146R | CIN No - U63040DL2000PTC105210
                                Service Category - Reservation of hotel booking</p>
                        </div>


                    </div>
                </div>

                <div className="px-3 text-start my-2">
                    <div className="">
                        <div className="px-1">
                            <p className='fs-8 mt-1 font-semibold'>Hotel discount including tax considered in coupon promotion is 1195.08</p>
                            <p className='fs-8 mt-1 font-bold'> * TCS amount is subject to reconciliation at month end.</p>
                            <p className='fs-8 mt-1 font-bold'>** Where TDS has already been deducted from the advance deposited with the hotel, no further TDS will be deducted at the time of booking.</p>

                            <p className='fs-8 mt-1 font-bold'> ** TDS amount is subject to reconciliation.</p>

                            <p className='fs-8 mt-1 font-bold'>Booking Cancellation Policy: </p>
                            <p className='fs-8 font-semibold'>Free Cancellation (100% refund) if you cancel this booking before 2024-02-13 11:59:59 (destination time).
                                Cancellations made after 2024-02-13 11:59:59 (destination time) will be subject to a hotel fee equal to the 100% of the booking amount.</p>
                        </div>
                    </div>
                </div>


                <div className="bs-white text-start p-3 ">
                    <div className="border-2">
                        <div className="bg-[#f8f9fa] border-b-2 p-2">
                            <p className='fs-7 font-semibold'>Booking Details</p>
                        </div>

                        <div className="flex gap-3 px-2 items-start py-3">
                            <div className="w-fit">
                                <p className='fs-8 font-semibold'>2 NIGHTS</p>
                            </div>
                            <div className="col flex gap-3 items-center">
                                <div className="w-fit">
                                    <p className='fs-8'> Check-in</p>
                                    <p className='fs-8'><b>13 Feb 2024</b>, 12:00 PM</p>
                                </div>
                                <div className="mx-4 max-w-[50px] bs-pink px-4 rounded-full h-[2px]"></div>
                                <div className="w-fit">
                                    <p className='fs-8'> Check-in</p>
                                    <p className='fs-8'><b>13 Feb 2024</b>, 12:00 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#f8f9fa] border-b-2 p-2">
                            <p className='fs-7 font-semibold'> Room Details</p>
                        </div>

                        <div className="px-3 py-2">
                            <p className='fs-8 font-medium'>Room 1: PREMIUM KING SIZE WITH LARGE BED - ROOM</p>
                            <p className='fs-8 font-medium'>Breakfast</p>
                            <p className='fs-8 font-medium'>2 Adults</p>
                            <p className='fs-8 font-bold mt-2'>Inclusions :</p>
                            <ul className='list-disc'>
                                <li className='ms-4 fs-8 font-semibold opacity-75'>Complimentary Room Upgrade to PRESIDENT ROOM is available. This service is subject to availability.</li>
                                <li className='ms-4 fs-8 font-semibold opacity-75'>Complimentary Breakfast is available.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="p-3">
                    <div className="border-b-2 border-pink py-1 text-start">
                        <p className='fs-6 font-bold '>Hotel Details</p>
                    </div>
                    <div className="flex justify-between mt-3">
                        <div className="col text-start">
                            <p className='fs-6 font-bold'>HOTEL EVERLAND, Rajkot ⭐⭐⭐</p>
                            <p className="fs-8"><b>Fax :</b>----</p>
                            <p className="fs-8"><b>Contact :</b> 7035703554</p>
                            <p className="fs-8"><b>E-mail : </b> hoteleverland@yahoo.com </p>
                            <p className="fs-8"><b>E-mail : </b> hoteleverland.reservation@gmail.com </p>
                            <p className="fs-8"><b>Address :</b> Moti Tanki Chowk Rajkot, Rajkot, IN </p>



                            <p className="fs-8 mt-4"><b>Meal Plan : </b> Breakfast </p>
                            <p className="fs-8"><b>Guest : </b> Yogesh Shah(Primary) </p>
                            <p className="fs-8"><b>Room Category : </b> PREMIUM KING SIZE WITH LARGE BED - ROOM </p>

                        </div>
                        <div className="w-fit">
                            <div className="flex justify-end items-center gap-2 my-1">
                                <p className='fs-7 font-semibold'>View Map</p>
                                <i className="fa-regular fa-map-location-dot fs-7 tx-pink"></i>
                            </div>
                            <div className="flex justify-end items-center gap-2 my-1">
                                <p className='fs-7 font-semibold'>Photo Gallery</p>
                                <i className="fa-solid fa-photo-film fs-7 tx-pink"></i>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="p-3">
                    <div className="border-b-2 border-pink py-1 text-start">
                        <p className='fs-6 font-bold '>Hotel Details</p>
                    </div>
                    <div className="my-2">
                        <p className='fs-8 font-bold text-start'>PAYMENT INFORMATION: THIS BOOKING IS GUARANTEED BY VIRTUAL CREDIT CARD/ WIRE.
                            (FOR VIRTUAL CREDIT CARD - CONFIRM THIS BOOKING TO GET THE CARD DETAILS FOR INR 10342.08)</p>
                    </div>
                    <div className="bs-white border-1 border-pink mt-3">
                        <div className="flex border-t-1 border-pink py-1">
                            <div className="col">
                                <p className='fs-7 font-bold px-2 text-start'>Payment Breakup</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold px-2 text-start'>Room Charges</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold px-2 text-start'>	Extra Guest/Child</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold px-2 text-start'>Taxes</p>
                            </div>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold opacity-70 px-2 text-start'>Room 1: PREMIUM KING SIZE WITH LARGE BED - ROOM</p>
                        </div>
                        <div className="flex py-1 border-t-1 border-pink">
                            <div className="col">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>14 Feb 2024</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>INR 4617.0</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>INR 0.0</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>INR 554.04</p>
                            </div>
                        </div>
                        <div className="flex border-t-1 border-pink py-1">
                            <div className="col">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>15 Feb 2024</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>INR 4617.0</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>INR 0.0</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>INR 554.04</p>
                            </div>
                        </div>
                        <div className="flex border-t-1 border-pink py-1">
                            <div className="col">
                                <p className='fs-7 font-bold opacity-70 px-2 text-start'>Total</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold px-2 text-start'>	9234</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold px-2 text-start'>	0</p>
                            </div>
                            <div className="w-[20%] border-l-1 border-pink">
                                <p className='fs-7 font-bold px-2 text-start'>1108.08</p>
                            </div>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold px-2 text-start'>(A) Hotel Gross Charge (Room charges + extra guest + taxes) : INR 10342.08</p>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold opacity-70 px-2 text-start'>MMT Commission : INR 1846.8</p>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold opacity-70 px-2 text-start'>MMT GST @ 18 % ( includes IGST/CGST/SGST) : INR 332.42</p>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold px-2 text-start'>(B) MMT Commission (Including GST) : INR 2179.22</p>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold px-2 text-start'>(C) TCS * : INR 92.34</p>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold px-2 text-start'>(D) TDS ** : INR 92.34</p>
                        </div>
                        <div className=" border-t-1 border-pink py-1">
                            <p className='fs-7 font-bold px-2 text-start'>MMT to Pay Hotel (A - B - C - D) : INR 7978.18</p>
                        </div>
                        <div className=" border-t-1 border-pink py-1.5">
                            <p className='fs-8 font-bold px-2 text-center'>Booking Cancellation Policy : Free Cancellation (100% refund) if you cancel this booking before 2024-02-14 11:59:59 (destination time).
                                Cancellations made after 2024-02-14 11:59:59 (destination time) will be subject to a hotel fee equal to the 100% of the booking amount.</p>
                        </div>
                    </div>
                </div>

                <div className="p-3">
                    <div className="border-b-2 border-pink py-1 text-start">
                        <p className='fs-7 font-bold '>Note</p>
                    </div>
                    <p></p>
                    <ul className="list-disc ms-4 mt-2 text-start">
                        <li className='fs-8 font-medium'>
                            If your contact details have changed, please notify us so that the same can be updated in our records.
                        </li>

                        <li className='fs-8 font-medium'>
                            If the booking is cancelled or changed by guest at a later stage, you will be notified and this confirmation 
                            email & Everland Hotel Booking ID will be null and void.
                        </li>

                        <li className='fs-8 font-medium'>
                            Hotel has to issue invoice to customers for 'Hotel Gross Charges' in compliance with applicable tax laws. 
                            The customer details including GST related info are mentioned below for this purpose.
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )
}
