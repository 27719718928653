import { Modal, ModalBody, ModalContent, useDisclosure } from "@nextui-org/react";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import Horizontalscroll from '../../../components/Horizontalscroll';
import { getAllRoomsAction } from '../../../redux/actions/room';


export default function RoomList() {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [startdate, setStartDate] = useState(new Date());
    const [roomDetailsModal, setroomDetailsModal] = useState(false);
    const dispatch = useDispatch();
    const { getAllRooms } = useSelector((state) => state.roomsState);
    const [roomData, setRoomData] = useState([]);
    const naviget = useNavigate();

    const roomSubImgsSlider = {
        arrows: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        speed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const amenities = [
        {
            title: "Popular with Guests",
            data: [
                { name: "Air Conditioning" },
                { name: "Housekeeping" },
                { name: "In-room Dining" },
                { name: "Wi-Fi" },
                { name: "Bathroom" },
                { name: "Room Service" },
                { name: "Mineral Water - additional charge" },
            ]
        },
        {
            title: "Room Features",
            data: [
                { name: "Telephone" },
                { name: "Sofa" },
                { name: "Closet" },
                { name: "Seating Area" },
                { name: "Mini Fridge" },
                { name: "Mirror" },
                { name: "Chair" },
                { name: "Work Desk" },
            ]
        },
        {
            title: "Beds and Blanket",
            data: [
                { name: "Blanket" },
                { name: "Pillows" },
                { name: "Cushions" },
            ]
        },
        {
            title: "Safety and Security",
            data: [
                { name: "Safety" },
            ]
        },
        {
            title: "Media and Entertainment",
            data: [
                { name: "TV" },
            ]
        },
        {
            title: "Bathroom",
            data: [
                { name: "Towels" },
                { name: "Shower" },
                { name: "Geyser/Water Heater" },
                { name: "Western Toilet Seat" },
                { name: "Dustbins" },
                { name: "Toilet Paper" },
                { name: "Hot & Cold Water" },
            ]
        },
        {
            title: "Other Facilities",
            data: [
                { name: "Fan" },
                { name: "Security" },
            ]
        },
    ];

    const roomlist = [
        { id: '1', img: "./Hotel-imgs/1688571025601.jpg" },
        { id: '2', img: "./Hotel-imgs/1688571025601.jpg" },
    ];

    const roomlistimgs = [
        { id: '1', img: "./Hotel-imgs/1688571025601.jpg" },
        { id: '2', img: "./Hotel-imgs/1688571025601.jpg" },
        { id: '3', img: "./Hotel-imgs/1688571025601.jpg" },
        { id: '4', img: "./Hotel-imgs/1688571025601.jpg" },
    ];

    const roomDetaiLslist = [
        {
            id: '1',
            sqft: "120 sq.ft",
            discount: "50.0% off",
            coverimg: "./Hotel-imgs/1688571025601.jpg",
            roomName: "PREMIUM KING SIZE WITH LARGE BED - ROOM",
            otherImgs: [
                { img: "./Hotel-imgs/1688571025601.jpg" },
                { img: "./Hotel-imgs/1688571025601.jpg" },
                { img: "./Hotel-imgs/1688571025601.jpg" },
                { img: "./Hotel-imgs/1688571025601.jpg" },
            ],
            plandetails: [
                {
                    price: "₹ 3,698",
                    delprice: "₹ 7,600",
                    recommended: "true",
                    tax: "+ ₹ 874 taxes & fees",
                    planName: "Room Only | Free Cancellation",
                    aminities: [
                        {
                            name: "No meals included",
                            icon: "fa-sharp fa-solid fa-circle-xmark ",
                            color: "tx-red"
                        },
                        {
                            name: "Free Early Check in",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Late check-out",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Cancellation till check-in",
                            icon: "fa-sharp fa-solid fa-badge-check ",
                            color: "tx-green"
                        }
                    ]
                },
                {
                    price: "₹ 3,798",
                    delprice: "₹ 7,500",
                    recommended: "false",
                    tax: "+ ₹ 898 taxes & fees",
                    planName: "Free Breakfast | Free Cancellation",
                    aminities: [
                        {
                            name: "Free Breakfast",
                            icon: "fa-solid fa-pot-food",
                            color: "tx-green"
                        },
                        {
                            name: "Free Early Check in",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Late check-out",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Cancellation till check-in",
                            icon: "fa-sharp fa-solid fa-badge-check ",
                            color: "tx-green"
                        }
                    ]
                }
            ],
        },
        {
            id: '2',
            sqft: "250 sq.ft",
            discount: "50.0% off",
            roomName: "Premium Twin Bed Room",
            coverimg: "./Hotel-imgs/1688571025601.jpg",
            otherImgs: [
                { id: '1', img: "./Hotel-imgs/1688571025601.jpg" },
                { id: '2', img: "./Hotel-imgs/1688571025601.jpg" },
                { id: '3', img: "./Hotel-imgs/1688571025601.jpg" },
                { id: '4', img: "./Hotel-imgs/1688571025601.jpg" },
            ],
            plandetails: [
                {
                    price: "₹ 3,749",
                    delprice: "₹ 7,500",
                    recommended: "true",
                    tax: "+ ₹ 886 taxes & fees",
                    planName: "Room Only | Free Cancellation",
                    aminities: [
                        {
                            name: "No meals included",
                            icon: "fa-sharp fa-solid fa-circle-xmark ",
                            color: "tx-red"
                        },
                        {
                            name: "Free Early Check in",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Late check-out",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Cancellation till check-in",
                            icon: "fa-sharp fa-solid fa-badge-check ",
                            color: "tx-green"
                        }
                    ]
                },
                {
                    price: "₹ 3,848",
                    delprice: "₹ 7,700",
                    recommended: "false",
                    tax: "+ ₹ 909 taxes & fees",
                    planName: "Free Breakfast | Free Cancellation",
                    aminities: [
                        {
                            name: "Free Breakfast",
                            icon: "fa-solid fa-pot-food",
                            color: "tx-green"
                        },
                        {
                            name: "Free Early Check in",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Late check-out",
                            icon: "fa-solid fa-timer",
                            color: "tx-black"
                        },
                        {
                            name: "Free Cancellation till check-in",
                            icon: "fa-sharp fa-solid fa-badge-check ",
                            color: "tx-green"
                        }
                    ]
                }
            ],
        },
    ];

    const handleroomDetailsModal = (id) => {
        setroomDetailsModal(true)
        const roomDetails = getAllRooms?.find((item) => {
            return item?._id === id;
        });
        setRoomData(roomDetails);
    };

    const closeroomDetailsModal = () => {
        setroomDetailsModal(false)
    }

    const handleBookDetails = (id) =>{
        const bookRoomDetails = getAllRooms?.find((item) => {
            return item?._id === id;
        });
        // setBookRoomData(bookRoomDetails);
        localStorage.setItem('bookRoomDetails', JSON.stringify(bookRoomDetails));
        naviget("/Booking-Review")
    }
console.log("getAllRooms",getAllRooms);

    useEffect(() => {
        dispatch(getAllRoomsAction());
    }, [dispatch]);

    useEffect(() => {
        if (getAllRooms) {
            const roomsDataString = JSON.stringify(getAllRooms);
            localStorage.setItem('roomsData', roomsDataString);
        }
    }, [getAllRooms]);

    return (
        <>
            <div className="w-100 pt-[80px] md:!px-[80px] px-3">
                <div className="flex flex-col gap-3 h-fit w-[100%]">
                    <div className="w-100 h-fit flex md:!flex-wrap py-4">
                        {getAllRooms?.length > 0 && getAllRooms?.map((item) => {
                            return (
                                <div key={item.id} className="w-100 md:!w-[50%] p-2">
                                    <div className="col relative z-10 flex flex-col shadow-1 border-[2px] border-pink rounded-md overflow-hidden">
                                        <div className="flex flex-col md:!flex-row gap-2 w-100">
                                            <div className="min-w-[230px] md:!max-w-[230px] h-fit p-2.5 border-b-[2px] md:!border-b-[0px] md:!border-r-[2px] border-pink flex flex-col gap-1">
                                                <div className="h-fit w-100 md:!max-h-[140px] relative">
                                                    <img className='md:!max-h-[140px] w-100 object-cover rounded-md shadow-md' src={item?.cover_image} alt="" />
                                                    <div className="absolute top-0 start-50 rounded-b-md translate-middle-x bs-mix-pink flex justify-start ">
                                                        <p className='w-fit whitespace-nowrap m-0 fs-8 font-semibold px-3 tx-white'>Discount - {item?.discount}</p>
                                                    </div>
                                                </div>
                                                <div className="w-100">
                                                    <div className="flex w-100 h-fit gap-1 overflow-x-scroll scroll-d-none">
                                                        <Horizontalscroll sensitivity={80}>
                                                            {item?.images?.map((image) => (
                                                                <>
                                                                    <div key={image} className="mr-2 md:!min-h-[40px] md:!max-h-[40px] md:!min-w-[40px] md:!max-w-[40px] shadow-md rounded-md overflow-hidden">
                                                                        <img className='w-100 h-100 col' src={image} alt="" />
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </Horizontalscroll>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col flex flex-col justify-between">
                                                <div className="flex col justify-between">
                                                    <div className="col text-start">
                                                        <div className="flex col justify-between">
                                                            <div className="w-fit flex items-center relative h-[24px] pe-[20px] z-10 mt-[-9px] ml-[-2px] md:!mt-[-2px] md:!ml-[-9px] overflow-hidden">
                                                                <div className="flex items-center gap-2 bs-mix-pink">
                                                                    <div className="pl-2">
                                                                        <i className="fa-regular fa-arrow-up-right fs-6 tx-white"></i>
                                                                    </div>
                                                                    <div className="pr-2">
                                                                        <p className='tx-white fs-7 font-bold'>SPONSORED</p>
                                                                    </div>
                                                                </div>
                                                                <div className="absolute top-0 end-0 origin-top-right -z-10 bs-pink w-[30px] h-[30px] rotate-[30deg] "></div>
                                                            </div>
                                                            <div className="w-fit">
                                                                <p className='text-[1.1rem] font-bold px-1'> ⭐⭐⭐⭐⭐</p>
                                                            </div>
                                                        </div>
                                                        <div className="col flex items-center justify-between mt-1 px-2 md:!px-0">
                                                            <div className="w-fit">
                                                                <p className='text-[0.9rem] md:!text-[1.1rem] font-bold '>{item.display_name}</p>
                                                            </div>
                                                            <div className="w-fit flex items-center gap-2 py-1 px-2">
                                                                {/* <i className="fa-light fa-objects-column text-[17px] tx-pink" /> */}
                                                                <p className='text-[0.8rem] md:!text-[1rem] font-bold whitespace-nowrap'>{item.area} {item.area_type}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col flex items-start justify-center md:!justify-start text-center md:!text-start gap-2 py-1 fs-7">
                                                            <i className="fa-duotone fa-location-dot mt-0.5 tx-pink"></i>
                                                            <p className='text-[0.6rem] md:!text-[0.7rem] opacity-70 font-bold'>Near Rajkot Train Station, Rajkot | 900 m from Yagnik Road</p>
                                                        </div>
                                                        <ul className="flex flex-wrap justify-center md:!justify-start gap-1 px-1 list-disc">
                                                            {/* {item?.aminities?.map((amenitiesData) => {
                                                                return (
                                                                    <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>{amenitiesData}</li>
                                                                )
                                                            })} */}
                                                            {/* <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Housekeeping</li>
                                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Bathroom</li>
                                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Wi-Fi</li>
                                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>In-room Dining</li>
                                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Room Service</li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col flex  items-end text-start justify-center md:!justify-end px-1 py-2">
                                                    <div onClick={() => handleroomDetailsModal(item?._id)} >
                                                        <p className='bs-mix-pink tx-white border-2 w-fit border-white px-4 py-1 rounded-md fs-6 font-bold'> MORE DETAILS</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:!flex-row col h-fit border-t-[2px] border-pink">
                                            <div className="w-100 md:!min-w-[50%] md:!max-w-[50%] h-fit text-start">
                                                <div className="fs-8 bs-mix-pink tx-white w-fit px-2 mt-[-2px] font-semibold rounded-br-md">RECOMMENDED</div>
                                                    {item?.plans?.map((plnData)=>{
                                                        console.log("plnDataplnData",plnData);
                                                        return(
                                                            plnData?.active_status === 'true' ? 
                                                             <p className='text-[1.1rem] font-bold px-3 py-1'>{plnData?.plan_name} - {plnData?.meal_plan}</p>
                                                              : ""
                                                    )
                                                })}
                                                  
                                                <div className="flex gap-1 items-center px-2 tx-red">
                                                    <i className={`fa-sharp fa-solid fa-circle-xmark fs-6 min-w-[30px] max-w-[30px] text-center`}></i>
                                                    <p className={`fs-7 font-semibold`}>No meals included </p>
                                                </div>
                                                <div className="flex gap-1 items-center px-2 tx-green">
                                                    <i className={`fa-sharp fa-solid fa-badge-check fs-6 min-w-[30px] max-w-[30px] text-center`}></i>
                                                    <p className={`fs-7 font-semibold`}>Free Cancellation till check-in </p>
                                                </div>
                                            </div>
                                            <div className="bs-pink rounded-full min-w-[2px]"></div>
                                            <div className="w-100 md:!min-w-[50%] md:!max-w-[50%] h-fit text-start">
                                                <div className="relative">
                                                    <div className="bs-mix-pink ml-[-2px] mt-[-2px] relative z-10 py-1 px-2">
                                                        <p className='tx-white fs-8 font-semibold relative'>Deal Applied: MMTBIGSAVER. Use MMTBIGSAVER to get a Discount of INR 6026 off.</p>
                                                    </div>
                                                    <div className="absolute top-100 start-[20%] origin-center min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rotate-[45deg] mt-[-10px] bs-dpink"></div>
                                                </div>
                                                <div className="col h-fit my-2 font-Montserrat">
                                                    <div className="flex items-end">
                                                        <div className="col ps-3">
                                                            <del className='fs-6 font-semibold'>₹ 7,000</del>
                                                            <div className="flex gap-2 items-center">
                                                                <p className='fs-5 font-bold'>₹ 7,000</p>
                                                                <div className="col min-w-[2px] max-w-[2px] py-2 h-100 rounded-full bs-pink"></div>
                                                                <p className='fs-7 font-bold'>Per Night</p>
                                                            </div>
                                                            <p className='fs-6 font-semibold'>₹ 700 tax & service fees</p>
                                                        </div>
                                                        <div className="flex mb-2 ms-auto items-center relative h-[27px] pl-[24px] w-fit overflow-hidden">
                                                            <div className="flex h-[30px] items-center relative z-[1] gap-2 bs-mix-pink" onClick={()=>handleBookDetails(item?._id)}>
                                                                <div className="px-2">
                                                                    <p className='tx-white fs-7 font-bold'>SELECT ROOM</p>
                                                                </div>
                                                            </div>
                                                            <div className="absolute top-0 left-0 origin-center bs-dpink w-[62px] h-[30px] rotate-[490deg]"></div>
                                                        </div>
                                                    </div>
                                                    <p className='fs-9 col my-2 tracking-wider text-gray-500 text-center opacity-80 font-semibold'>To Get this @ INR3,671 or less <span className='  tx-blue'>LOGIN NOW</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>

            <Modal isOpen={roomDetailsModal} backdrop={"blur"} scrollBehavior={"inside"} onOpenChange={closeroomDetailsModal}>
                <ModalContent className='min-w-[800px] min-h-[90vh] py-5'>
                    {(closeroomDetailsModal) => (

                        <>
                            <ModalBody className='py-3'>
                                <div className="h-100 w-100 pb-5  mt-3">
                                    <div className="min-w-[230px] md:!max-w-[100%] h-fit p-2.5 flex flex-col gap-1">
                                        <Slider {...roomSubImgsSlider}>

                                            {roomData?.images?.map((imgList) => {
                                                return (
                                                    <div className="col px-2 relative">
                                                        <img className='h-100 w-100 object-cover rounded-md shadow-md' src={imgList} alt="" />
                                                        <div className="absolute top-0 start-50 rounded-b-md translate-middle-x bs-mix-pink flex justify-start ">
                                                            <p className='w-fit whitespace-nowrap m-0 fs-8 font-semibold px-3 tx-white'>Discount - 32% Off</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                        <div className="flex w-100 h-fit gap-1 justify-between">
                                            {roomData?.images?.map((item) => {
                                                return (
                                                    <div className=" md:!min-h-[30px]  rounded-md overflow-hidden">
                                                        <img className='w-100 h-100 col' src={item} alt="" />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="w-100 my-4">
                                        <p className='fs-5 font-bold'>Room Amenities</p>
                                        <div className="col my-2">
                                            {/* <p className='fs-6 font-bold py-2'>{item}</p> */}
                                            <ul className="col list-disc flex flex-wrap gap-2">
                                                {/* {roomData?.aminities?.map((amenitiesData) => (
                                                    <li className='w-fit fs-7 mx-3 font-semibold'>{amenitiesData}</li>
                                                ))} */}
                                            </ul>
                                        </div>
                                    </div>
                                    {roomData?.plans?.map((items) => (
                                        <div className="flex flex-col md:!flex-row col h-fit border-t-[2px] border-x-[2px] border-pink">
                                            <div className="w-100 md:!min-w-[50%] md:!max-w-[50%] h-fit text-start">
                                                {items.recommended === "true" && <div className="fs-8 bs-mix-pink tx-white w-fit px-2 mt-[-2px] font-semibold rounded-br-md">RECOMMENDED</div>}
                                                <p className='text-[1.1rem] font-bold px-3 py-1'>{items?.plan_name}</p>

                                                {/* {items?.aminities.map((amini) => (
                                                    <div key={amini} className="flex gap-1 items-center px-2 mt-1">
                                                        <i className={`${amini.icon} ${amini?.color}  fs-6 min-w-[30px] max-w-[30px] text-center`}></i>
                                                        <p className={`fs-7 font-semibold  ${amini?.color}`}>{amini.name} </p>
                                                    </div>
                                                ))} */}
                                                <p className='text-[0.8rem] my-3 font-bold tx-blue pointer px-3' onClick={() => handleroomDetailsModal(items?._id)}> MORE DETAILS</p>
                                            </div>
                                            <div className="bs-pink rounded-full min-w-[2px]"></div>
                                            <div className="w-100 md:!min-w-[50%] md:!max-w-[50%] h-fit text-start">
                                                <div className="relative">
                                                    <div className="bs-mix-pink ml-[-2px] mt-[-2px] relative z-10 py-1 px-2">
                                                        <p className='tx-white fs-8 font-semibold relative'>Deal Applied: MMTBIGSAVER. Use MMTBIGSAVER to get a Discount of INR 6026 off.</p>
                                                    </div>
                                                    <div className="absolute top-100 start-[20%] origin-center min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rotate-[45deg] mt-[-10px] bs-dpink"></div>
                                                </div>

                                                <div className="col h-fit my-2 font-Montserrat">
                                                    <div className="flex ms-auto items-center relative h-[24px] pl-[24px] w-fit overflow-hidden">
                                                        <div className="flex h-[30px] items-center relative z-[1] gap-2 bs-mix-pink">
                                                            <div className="px-2">
                                                                <p className='tx-white fs-7 font-bold'>Per Night</p>
                                                            </div>
                                                        </div>
                                                        <div className="absolute top-0 left-0 origin-center bs-dpink w-[55px] h-[30px] rotate-[490deg] "></div>
                                                    </div>
                                                    <div className="flex items-end">
                                                        <div className="col ps-3">
                                                            <del className='fs-6 font-semibold'>₹ 7,200</del>
                                                            <p className='fs-5 font-bold'>{items.price}</p>
                                                            <p className='fs-6 font-semibold'>{items.tax}</p>
                                                        </div>
                                                        <div className="flex mb-2 ms-auto items-center relative h-[27px] pl-[24px] w-fit overflow-hidden">
                                                            <div className="flex h-[30px] items-center relative z-[1] gap-2 bs-mix-pink">
                                                                <Link to="/Booking-Review" className="px-2">
                                                                    <p className='tx-white fs-7 font-bold'>SELECT ROOM</p>
                                                                </Link>
                                                            </div>
                                                            <div className="absolute top-0 left-0 origin-center bs-dpink w-[62px] h-[30px] rotate-[490deg]"></div>
                                                        </div>
                                                    </div>
                                                    <p className='fs-9 col my-2 tracking-wider text-gray-500 text-center opacity-80 font-semibold'>To Get this @ INR3,671 or less <span className='  tx-blue'>LOGIN NOW</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
