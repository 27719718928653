import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, checkbox, useDisclosure } from "@nextui-org/react";
import { render } from '@testing-library/react';
import React, { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { airConditionoptions, bathroomoptions, heateroptions, houseKeepingoptions, mineralWateroptions, roomDiningoptions, roomserviceoptions, wifioptions } from '../../../Amenities';
import { BedType, RoomTypes, RoomView, amenitiesNames, amenitiesTypes, dimentionsoption, ratePlansTypes } from '../../../RoomDetails';
import Horizontalscroll from '../../../components/Horizontalscroll';
import { BaseURL } from '../../../helper/axios';
import { imageUploadAction, multipleUploadImagesAction } from '../../../redux/actions/image';
import { addRoomsAction, deleteImageAction, deleteRoomAction, getAllRoomsAction, updateRoomsAction } from '../../../redux/actions/room';
import { addRatePlanAction, deleteRatePlanAction, getAllRatePlanAction, statusRatePlanAction, updateRatePlanAction } from "../../../redux/actions/ratePlan";
import moment from "moment";

export default function AddRoom() {
    const [startdate, setStartDate] = useState(new Date());
    const [coverSelectedImages, setCoverSelectedImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [genralSelectedImages, setGenralSelectedImages] = useState([]);
    const [ActiveTabState, setActiveTabState] = useState("rooms");
    const [filtredAmenitiesType, setFiltredAmenitiesType] = useState("Bathroom");
    const [filtredAmenitiesOption, setFiltredAmenitiesOption] = useState("Bathroom");
    const [dimension, setDimension] = useState("size");
    const [selectedBedType, setSelectedBedType] = useState();
    const [selectedRoomView, setSelectedRoomView] = useState();
    const [selectedRatePlanTypes, setSelectedRatePlanTypes] = useState();
    const [selectedRoomTypes, setSelectedRoomTypes] = useState();
    const [selectedDimensionsize, setSelectedDimensionsize] = useState();
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    // const [startdate, setStartDate] = useState(new Date());
    const [roomselector, setRoomselector] = useState(new Date());
    const [adultnum, setAdultNum] = useState(0);
    const [childrennum, setChildrenNum] = useState(0);
    const [addrateplanTypeModal, setAddRateplanTypeModal] = useState(false);
    const [roomInfoModal, setRoomInfoModal] = useState(false);
    const [addRoomData, setAddRoomData] = useState({
        _id: "",
        display_name: "",
        description: "",
        total_rooms: "",
        length: "",
        breadth: "",
        displayNameErr: "",
        totalRoomsErr: "",
        room_type: "",
        room_view: "",
        room_type_err: "",
        size_type: "",
        images: "",
        videos: [],
        amenities: "",
        cover_image: "",
        isUpdate: false,
        dimensions: "size",

    })
    const [baseAdultnum, setBaseAdultNum] = useState(1);
    const [maxAdultnum, setMaxAdultNum] = useState(1);
    const [maxChildrentnum, setMaxChildrentNum] = useState(1);
    const [maxOccupancy, setMaxOccupancy] = useState();
    const dispatch = useDispatch();
    const [imagesUpdate, setImagesUpdate] = useState(null);
    const [coverImageUpdate, setCoverImageUpdate] = useState(null);
    const images = useSelector((state) => state.galleryState?.images);
    const { getAllRooms } = useSelector((state) => state?.roomsState)
    const [coverimageSrc, setCoverImageSrc] = useState('');
    const [coverPreviewimageSrc, setCoverImagePreviewSrc] = useState('');
    const [coverImageErrorMsgVisible, setCoverImageErrorMsgVisible] = useState(false);
    const [imageSrcs, setImageSrcs] = useState([]);
    const [imageErrorMsgVisible, setImageErrorMsgVisible] = useState(false);
    // const [totalRoom, setTotalRoom] = useState();
    const [PlanDatas, setPlanDatas] = useState({
        plan_id: "",
        room_id: "",
        isUpdate: false,
        plan_name: "",
        active_status: false,
        meal_plan: "",

    });
    const [roomId, setRoomId] = useState('');
    const [selectAminites, setSelectAminites] = useState([]);
    const [roomData, setRoomData] = useState();

    const handleAminites = (e) => {
        const option = e.target.value;
        if (selectAminites.length === 0) {
            let newArr = []
            const newObj = {
                amenetName: filtredAmenitiesType,
                options: [{ optionName: option }]
            };
            newArr.push(newObj)
            setSelectAminites(newArr)

        }
        else {
            const cpAminitiArr = [...selectAminites]
            const category = cpAminitiArr.find((item) => item.amenetName === filtredAmenitiesType);
            const optionIndex = category?.options?.findIndex(item => item.optionName === option)

            if (optionIndex === -1)
                category?.options.push({ optionName: option })
            else
                category?.options.splice(optionIndex, 1)
            setSelectAminites(cpAminitiArr)

        }
        // const categoryIndex = selectAminites.findIndex((item) => item.amenetName === categoryNam);
    }

    const validateInput = (name, value) => {
        if (name === 'display_name') {
            const displaynameRegex = /^[a-z]^$/;
            if (!displaynameRegex.test(value) || displaynameRegex.value === "") {
                return 'Please enter a valid display name ';
            }
        }
        else if (name === 'total_rooms') {
            const totalRoomsRegex = /^\d*[1-9]\d*$/;
            if (!totalRoomsRegex.test(value)) {
                return 'TotalRoom must be at least 8 characters long';
            }
        }
        return '';
    };

    const handleRateplanTypeModal = (id) => {
        setAddRateplanTypeModal(true)
        setRoomId(id);

    }

    const selectedPlanData = (selectedOption) => {
        setPlanDatas((prevData) => ({
            ...prevData,
            meal_plan: selectedOption?.value,

        }));
    };

    const closeRateplanTypeModal = () => {
        setAddRateplanTypeModal(false)
    }

    const closeInformationOfRoomTypeModal = () => {
        setRoomInfoModal(false)
    }

    const selectedRoomTypesHandler = (selectedOption) => {
        setAddRoomData((prevData) => ({
            ...prevData,
            room_type: selectedOption?.value,

        }));
    };

    const selectedRoomViewHandler = (selectedOption) => {
        setAddRoomData((prevData) => ({
            ...prevData,
            room_view: selectedOption?.value,
        }));
    };

    const selectedBedTypeHandler = (selectedOption) => {
        setAddRoomData((prevData) => ({
            ...prevData,
            bed_type: selectedOption?.value,
        }));
    }

    const selectedDimensionHandler = (selectedOption) => {
        setAddRoomData((prevData) => ({
            ...prevData,
            dimensions: selectedOption,
        }));
    }

    const selectedDimensionSizeType = (selectedOption) => {
        setAddRoomData((prevData) => ({
            ...prevData,
            size_type: selectedOption?.value,
        }));
    };

    const preViewImgSlider = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const baseadultincNum = () => {
        // if (adultnum < 1) {
        setBaseAdultNum(Number(baseAdultnum) + 1);
        // }
    };

    const baseadultdecNum = () => {
        if (baseAdultnum > 1) {
            setBaseAdultNum(baseAdultnum - 1);
        }
    }

    const baseadulthandleChange = (e) => {
        setBaseAdultNum(e?.target?.value);
    }

    const maxadultincNum = () => {
        // if (maxAdultnum < 5) {
        setMaxAdultNum(Number(maxAdultnum) + 1);
        // }
    };

    const maxadultdecNum = () => {
        if (maxAdultnum > 1) {
            setMaxAdultNum(maxAdultnum - 1);
        }
    }

    const maxadulthandleChange = (e) => {
        setMaxAdultNum(e.target.value);
    }

    const maxchildrenincNum = () => {
        // if (maxChildrentnum < 5) {
        setMaxChildrentNum(Number(maxChildrentnum) + 1);
        // }
    };

    const maxchildrendecNum = () => {
        if (maxChildrentnum > 1) {
            setMaxChildrentNum(maxChildrentnum - 1);
        }
    }

    const maxchildrenhandleChange = (e) => {
        setMaxChildrentNum(e?.target?.value);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddRoomData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value,
                // [`${name}Error`]: validateInput(name, value),
            };
            return updatedData;
        });
        // setAddRoomData((prevData) => ({
        //     ...prevData,
        //     [name]: value,
        //     [`${name}Error`]: validateInput(name, value),

        // }))
    }

    const handleSubmitAddRooms = (e) => {
        e.preventDefault();
        const displayNameErr = validateInput('display_name', addRoomData.display_name);
        const totalRoomsErr = validateInput('total_rooms', addRoomData.total_rooms);

        setAddRoomData((prevData) => ({
            ...prevData,
            displayNameErr,
            totalRoomsErr

        }))

        if (addRoomData?.isUpdate) {
            let body = {
                _id: addRoomData?._id,
                isUpdate: true,
                display_name: addRoomData?.display_name,
                description: addRoomData?.description,
                room_type: addRoomData?.room_type,
                total_rooms: Number(addRoomData?.total_rooms),
                room_view: addRoomData?.room_view,
                bed_type: addRoomData?.bed_type,
                length: Number(addRoomData?.length),
                breadth: Number(addRoomData?.breadth),
                size_type: addRoomData?.size_type,
                area: addRoomData?.area,
                base_adult: addRoomData?.base_adult,
                maximum_adults: addRoomData?.maximum_adults,
                max_children: addRoomData?.max_children,
                max_occupancy: addRoomData?.max_occupancy,
                images: imageSrcs,
                videos: [],
                amenities: addRoomData?.amenities,
                cover_image: coverimageSrc || addRoomData?.cover_image,
                dimensions: addRoomData?.dimensions
            }
            dispatch(updateRoomsAction(body));
            setTimeout(() => {
                setAddRoomData({
                    _id: "",
                    display_name: "",
                    description: "",
                    total_rooms: "",
                    length: "",
                    breadth: "",
                    displayNameErr: "",
                    totalRoomsErr: "",
                    room_type: "",
                    room_view: "",
                    room_type_err: "",
                    size_type: "",
                    images: "",
                    videos: [],
                    amenities: "",
                    cover_image: "",
                    isUpdate: false,

                })
            }, 1000);

        } else {
            let body = {
                _id: "",
                isUpdate: false,
                display_name: addRoomData?.display_name,
                description: addRoomData?.description,
                room_type: addRoomData?.room_type,
                total_rooms: Number(addRoomData?.total_rooms),
                room_view: addRoomData?.room_view,
                bed_type: addRoomData?.bed_type,
                length: Number(addRoomData?.length),
                breadth: Number(addRoomData?.breadth),
                size_type: addRoomData?.size_type,
                area: addRoomData?.area,
                base_adult: baseAdultnum,
                maximum_adults: maxAdultnum,
                max_children: maxChildrentnum,
                max_occupancy: maxOccupancy,
                images: imageSrcs,
                videos: [],
                amenities: selectAminites,
                cover_image: coverimageSrc || addRoomData?.cover_image,
                dimensions: addRoomData?.dimensions
            }
            dispatch(addRoomsAction(body));
            setTimeout(() => {
                setAddRoomData({
                    _id: "",
                    display_name: "",
                    description: "",
                    total_rooms: "",
                    length: "",
                    breadth: "",
                    displayNameErr: "",
                    totalRoomsErr: "",
                    room_type: "",
                    room_view: "",
                    room_type_err: "",
                    size_type: "",
                    images: "",
                    videos: [],
                    amenities: "",
                    cover_image: "",
                    isUpdate: false,

                })
            }, 1000);
        }
    }

    const addMultipleImageFile = (e) => {
        const files = e.target.files;
        const newImageSrcs = [];
        // const preview1 = [];
        const newImageSrcs1 = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const size = file.size;

            if (size > 2000000) {
                setImageErrorMsgVisible(true);
                return;
            }

            const reader = new FileReader();
            reader.onload = function () {
                newImageSrcs1.push(reader.result);
                newImageSrcs.push(file);
                // preview1.push(render.result);
                if (newImageSrcs.length === files.length) {
                    setImageErrorMsgVisible(false);
                    setImageSrcs((previousImgs) => previousImgs.concat(newImageSrcs));
                    setImagePreviews((previousImgs) => previousImgs.concat(newImageSrcs1));
                }
            };
            reader.readAsDataURL(file);
        }
    }

    const filteredAmenitiesData = amenitiesNames.filter(type => type.amenitiesType === filtredAmenitiesType)

    const coverImagePreviewFile = (event) => {
        const file = event.target.files[0];
        const size = file?.size;

        if (size > 2000000) {
            setCoverImageErrorMsgVisible(true);
        } else {
            setCoverImageErrorMsgVisible(false);

            if (file) {
                const reader = new FileReader();
                reader.onload = function () {
                    setCoverImageSrc(file);
                    setCoverImagePreviewSrc(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleDeleteSingleImage = () => {
        setCoverImagePreviewSrc('')
        if (addRoomData?.isUpdate) {
            setAddRoomData({
                ...addRoomData,
                cover_image: "",
            })
        }

    }

    const handleDeleteImage = async (index) => {
        const updatedPreviews = [...imagePreviews];
        updatedPreviews.splice(index, 1);
        setImagePreviews(updatedPreviews);
        if (addRoomData?.isUpdate && addRoomData.images.length > 0) {
            const updatedImages = [...addRoomData.images];
            updatedImages.splice(index, 1);
            setAddRoomData({
                ...addRoomData,
                images: updatedImages,
            })
        }
    };

    const handleDeleteRoom = async (Data) => {

        if (!Data?.id) {
            console.error("Please Enter Id for Delete the Room value");
            return;
        } else {
            confirmAlert({
                title: "Warning",
                message: "Are you sure want to delete : " + Data?.name,
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            dispatch(deleteRoomAction(Data?.id));
                        }

                    },
                    {
                        label: "Cancel",
                        onClick: () => "",
                    },
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,

            });
        }
    };

    const handleChangePlanData = (e) => {
        const { name, value } = e.target;
        setPlanDatas((prevData) => ({
            ...prevData,
            [name]: value,
            // [`${name}Error`]: validateInput(name, value),

        }))
    }

    const handleSubmitPlanData = (e) => {
        e.preventDefault()
        if (PlanDatas?.isUpdate) {
            let body = {
                isUpdate: true,
                room_id: PlanDatas?.room_id,
                plan_id: PlanDatas?.plan_id,
                plan_name: PlanDatas?.plan_name,
                meal_plan: PlanDatas?.meal_plan,
            }
            // console.log("bodybodybody::::::", body);
            dispatch(updateRatePlanAction(body))
            setAddRateplanTypeModal(false)

        } else {
            let body = {
                isUpdate: false,
                room_id: roomId,
                plan_name: PlanDatas?.plan_name,
                meal_plan: PlanDatas?.meal_plan,
                active_status: false,
            }
            dispatch(addRatePlanAction(body));
            setAddRateplanTypeModal(false)
        }
    }

    const handleDeleteRatePlanData = (id, PlanId) => {
        let body = {
            room_id: id,
            plan_id: PlanId,
        }
        if (!body?.room_id) {
            console.error("Please Enter Id for Delete the image value");
            return;
        } else {
            confirmAlert({
                title: "Warning",
                message: "Are you sure want to delete : " + body?.plan_id,
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            console.log("1708768675", body);
                            dispatch(deleteRatePlanAction(body));
                        }

                    },
                    {
                        label: "Cancel",
                        onClick: () => "",
                    },
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,

            });
        }
    }

    const handlEditRoomData = (id) => {
        const FilterRoomData = getAllRooms?.find((item) => {
            return item?._id === id;
        });
        console.log("FilterRoomData", FilterRoomData, getAllRooms, typeof id);
        // setCoverImageUpdate(FilterRoomData?.cover_image); 
        // setImagesUpdate(FilterRoomData?.images)
        let body = {
            _id: FilterRoomData?._id,
            isUpdate: true,
            display_name: FilterRoomData?.display_name,
            description: FilterRoomData?.description,
            room_type: FilterRoomData?.room_type,
            total_rooms: Number(FilterRoomData?.total_rooms),
            room_view: FilterRoomData?.room_view,
            bed_type: FilterRoomData?.bed_type,
            length: Number(FilterRoomData?.length),
            breadth: Number(FilterRoomData?.breadth),
            size_type: FilterRoomData?.size_type,
            area: FilterRoomData?.area,
            base_adult: FilterRoomData?.base_adult,
            maximum_adults: FilterRoomData?.maximum_adults,
            max_children: FilterRoomData?.max_children,
            max_occupancy: FilterRoomData?.max_occupancy,
            images: FilterRoomData?.images,
            videos: [],
            amenities: FilterRoomData?.amenities,
            cover_image: FilterRoomData?.cover_image,
            dimensions: FilterRoomData?.dimensions
        };
        setAddRoomData(body);
        // setEditData(body)
    };

    const handleActiveDeactivePlan = (roomId, planId, state) => {
        const body = {
            room_id: roomId,
            plan_id: planId,
            active_status: state

        };
        dispatch(statusRatePlanAction(body));
    };

    const handlEditRatePlan = (roomid, id) => {
        const FilterProduct = getAllRooms?.find((item) => {
            return item?.plans?.some((plan) => plan.plan_id === id
            );
            // return item?.plans?.some((plan) => plan.plan_name === plansName);
        });
        // console.log("FilterProduct",FilterProduct,roomid);
        if (FilterProduct) {
            const foundPlan = FilterProduct.plans.find((plan) => plan.plan_id === id);
            let body = {
                isUpdate: true,
                room_id: roomid,
                plan_id: foundPlan?.plan_id,
                plan_name: foundPlan?.plan_name,
                meal_plan: foundPlan?.meal_plan?.value
            };
            setPlanDatas(body)
        }
    };

    const dropDownOptionShow = useCallback((aminitiName, optionName) => {

        if (selectAminites.length !== 0) {
            const cpAminiti = [...selectAminites]
            const filterAmini = cpAminiti.find(_ => _.amenetName === aminitiName)
            const isPresent = filterAmini.options.some(_ => _.optionName === optionName);
            return isPresent
        }
        else return false

    }, [selectAminites])

    const handleChangeDropDown = (e, aminitiName, option, drop) => {
        const cpAminitiArr = [...selectAminites]
        const newArr = cpAminitiArr?.map((item) => {
            if (item.amenetName === aminitiName) {
                item.options?.map(ele => {
                    if (ele.optionName === option) {
                        ele[drop] = [e]
                    }
                    return ele
                })
            }
            return item
        })
        setSelectAminites(newArr)
    }

    const handleRoomDetails = (id) => {
        const roomDetails = getAllRooms?.find((item) => {
            return item?._id === id;
        });
        setRoomData(roomDetails);
        setRoomInfoModal(true);
    }

    useEffect(() => {
        const totaloccupancy = maxAdultnum + maxChildrentnum
        setMaxOccupancy(totaloccupancy)
    }, [maxadulthandleChange, maxchildrenhandleChange])

    useEffect(() => {
        localStorage.setItem('Room Detail page', ActiveTabState);
    }, [ActiveTabState]);

    useEffect(() => {
        dispatch(getAllRoomsAction());
        dispatch(getAllRatePlanAction());
    }, []);

    console.log("active_status", PlanDatas?.active_status);

    return (
        <>
            <div className="w-[90%] h-100 mx-auto flex flex-col items-start gap-3 rounded-lg">
                <div className="w-100 mx-auto text-center relative py-4 scroll-d-none overflow-y-scroll">
                    {ActiveTabState === "rooms" &&
                        <>
                            <div className="w-fit flex gap-1 hover:bg-[#f9e6da] text-[#fe7d32] pointer rounded items-center my-2 px-3"
                                onClick={() => setActiveTabState("room-list")}>
                                <i className="fa-solid fs-7 fa-angle-left"></i>
                                <p className="fs-7  font-semibold">BACK TO ROOMS</p>
                            </div>
                            <div className="flex flex-col mx-auto bs-white w-100 rounded border-2 h-fit">
                                <div className="w-100 px-3 py-2 bg-[#f8f8f8] border-b-2">
                                    <p className='font-semibold fs-4 text-left font-Roboto'>{addRoomData?.isUpdate ? "Edit New Room" : "Add New Room"}</p>
                                </div>
                                <form className='flex flex-col w-100 py-4'>
                                    <div className="w-100 px-4">
                                        <div className="flex items-center gap-3">
                                            <div className="">
                                                {/* 
                                            <label htmlhtmlFor="coverphoto" className="relative pointer border-3 shadow-1 border-dashed rounded-lg border-blue w-fit px-[90px] py-[80px]">
                                                        <input className='hidden'
                                                            onChange={coverImagePreviewFile}
                                                            type="file" id="coverphoto" name="coverphoto" accept=".jpg,.jpeg,.png" />
                                                        <p className='bs-blue tx-white absolute top-0 start-50 translate-middle-x whitespace-nowrap px-3 fs-7 rounded-b-lg font-bold'>Cover Photo </p>
                                                        <i className="fa-solid fa-plus tx-blue"></i>
                                                    </label>
                                                    {/* {addRoomData?.isUpdate === true ?(coverPreviewimageSrc?.le)} */}
                                                {console.log("addRoomDatacover_image", addRoomData?.cover_image, coverPreviewimageSrc?.length, addRoomData?.isUpdate)}
                                                {(!addRoomData?.isUpdate && coverPreviewimageSrc?.length === 0 || addRoomData?.cover_image === "") &&
                                                    <label htmlhtmlFor="coverphoto" className="relative pointer border-3 shadow-1 border-dashed rounded-lg border-blue w-fit px-[90px] py-[80px]">
                                                        <input className='hidden'
                                                            onChange={coverImagePreviewFile}
                                                            type="file" id="coverphoto" name="coverphoto" accept=".jpg,.jpeg,.png" />
                                                        <p className='bs-blue tx-white absolute top-0 start-50 translate-middle-x whitespace-nowrap px-3 fs-7 rounded-b-lg font-bold'>Cover Photo </p>
                                                        <i className="fa-solid fa-plus tx-blue"></i>
                                                    </label>}
                                                {((addRoomData?.isUpdate && addRoomData?.cover_image) || coverPreviewimageSrc) && (
                                                    <div className="relative rounded-md shadow-1 w-fit h-fit m-3">
                                                        <div className="hover:!opacity-75 duration-300 ease-in-out h-100 w-100 bs-blue opacity-0 rounded-md absolute z-0 tx-white flex items-center justify-center fs-3 font-bold">Cover Img</div>
                                                        <img id="Upload-img" className=" min-w-[190px] max-w-[190px] min-h-[190px] max-h-[190px] rounded-md object-cover tx-blue fs-6 font-bold text-center" src={addRoomData?.isUpdate ? addRoomData?.cover_image : coverPreviewimageSrc} alt="Preview" />
                                                        <div className="absolute pointer z-10 top-0 start-100 translate-middle" onClick={handleDeleteSingleImage}>
                                                            <i className="fa-solid fa-trash tx-blue"></i>
                                                        </div>
                                                    </div>
                                                )}

                                                {coverImageErrorMsgVisible && (
                                                    <div id="imageErrorMsg" className="error-message">
                                                        Image size exceeds the limit.
                                                    </div>
                                                )}
                                            </div>
                                            <div className="">
                                                {console.log("::::::::::", addRoomData?.isUpdate, addRoomData?.images?.length, addRoomData?.images, imagePreviews)}
                                                {(addRoomData?.images?.length === 0 && !addRoomData?.isUpdate) &&
                                                    <label htmlhtmlFor="roommultiplephoto" className="relative pointer border-3 shadow-1 border-dashed rounded-lg border-blue w-fit px-[90px] py-[80px]">
                                                        <input className='hidden' onChange={addMultipleImageFile} type="file" id="roommultiplephoto" name="roommultiplephoto" multiple />
                                                        <p className='bs-blue tx-white absolute top-0 start-50 translate-middle-x whitespace-nowrap px-3 fs-7 rounded-b-lg font-bold'>Only 3 Photo </p>
                                                        <i className="fa-solid fa-plus tx-blue"></i>
                                                    </label>}
                                            </div>
                                            {addRoomData.images.length > 0 && (
                                                <Horizontalscroll sensitivity={80}>
                                                    <div className="flex col w-fit me-5 pe-5 gap-3 relative">
                                                        {(((addRoomData?.isUpdate && addRoomData?.images?.length > 0) ? addRoomData?.images : imagePreviews?.length > 0 && imagePreviews) || []).map((imageSrc, index) => {
                                                            return (
                                                                <div key={index} className="relative rounded-md shadow-1 w-100 h-100 m-3 flex items-center justify-center">
                                                                    <div className="hover:!opacity-75 duration-300 ease-in-out h-100 w-100 bs-blue opacity-0 rounded-md absolute z-0 tx-white flex items-center justify-center fs-1 font-bold">{index + 1}</div>
                                                                    <img className=' min-w-[190px] max-w-[190px] min-h-[190px] max-h-[190px] rounded-md object-cover tx-blue fs-6 font-bold text-center' src={addRoomData?.isUpdate ? addRoomData?.images[index] : imageSrc} alt={`Preview ${index + 1}`} />
                                                                    <div className="absolute pointer z-10 top-0 start-100 translate-middle"
                                                                        onClick={() => handleDeleteImage(index)}>
                                                                        <i className="fa-solid fa-trash tx-red"></i>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {/* {(addRoomData?.isUpdate && addRoomData?.images ) || imagePreviews?.length > 0 && imagePreviews?.map((imageSrc, index) => {
                                                        return (
                                                            <div key={index} className="relative rounded-md shadow-1 w-100 h-100 m-3 flex items-center justify-center">
                                                                <div className="hover:!opacity-75 duration-300 ease-in-out h-100 w-100 bs-blue opacity-0 rounded-md absolute z-0 tx-white flex items-center justify-center fs-1 font-bold">{index + 1}</div>
                                                                <img className=' min-w-[190px] max-w-[190px] min-h-[190px] max-h-[190px] rounded-md object-cover tx-blue fs-6 font-bold text-center' src={addRoomData?.isUpdate ? addRoomData?.images[0] : imageSrc} alt={`Preview ${index + 1}`} />
                                                                <div className="absolute pointer z-10 top-0 start-100 translate-middle"
                                                                    onClick={() => handleDeleteImage(index)}>
                                                                    <i className="fa-solid fa-trash tx-red"></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })} */}
                                                    </div>
                                                </Horizontalscroll>
                                            )}

                                        </div>
                                        {imageErrorMsgVisible && (
                                            <div id="imageErrorMsg" className="error-message">
                                                Image size exceeds the limit.
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-fit mt-4">
                                        <span className='w-fit fs-6 font-bold tracking-tight py-3 px-4 font-Roboto'>ROOM INFO</span>
                                    </div>


                                    <div className="flex flex-col mt-3 w-100 gap-3">
                                        <div className="flex w-100 px-4 h-fit gap-5">
                                            <div className="flex flex-col h-fit w-50 gap-3">
                                                <div className="h-fit text-start m-0">
                                                    <p className='fs-7 font-semibold my-1 opacity-90 manded'>Display Name</p>
                                                    <input className='w-100 drop-shadow- border-[1px] border-grey rounded-md px-2 py-1 focus:!outline-none' placeholder='Room Name' type='text' name='display_name' value={addRoomData?.display_name} onChange={handleChange} />
                                                    <span className='tx-red fs-7 leading-3'>{addRoomData.displayNameErr}</span>
                                                </div>

                                                <div className="text-start  m-0">
                                                    <p className='fs-7 font-semibold my-1 opacity-90 '>Disciption</p>
                                                    <textarea className='w-100 rounded-md vertical-lg border-[1px] border-grey h-100 p-2 focus:!outline-none ' placeholder='Add Discription Here' type='text' name='description' value={addRoomData?.description} onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="w-50 flex gap-4 justify-between">
                                                <div className="w-50 flex flex-col justify-between">
                                                    <div className="relative z-30 text-start drop-shadow-">
                                                        <p className='fs-7 font-semibold my-1 opacity-90 manded'>Room Type</p>

                                                        <Select
                                                            placeholder="Select Rooms Type"
                                                            options={RoomTypes}
                                                            onChange={selectedRoomTypesHandler}
                                                            value={addRoomData?.isUpdate ? { label: addRoomData.room_type, value: addRoomData.room_type } : RoomTypes.find(option => option.value === addRoomData?.room_type)}
                                                            className="w-100 border-[1px] border-grey rounded-md bs-transparent focus:outline-0"
                                                        />
                                                    </div>
                                                    <div className="relative z-20 text-start drop-shadow-">
                                                        <p className='fs-7 font-semibold my-1 opacity-90'>Room View</p>
                                                        <Select
                                                            placeholder="Select Rooms View"
                                                            options={RoomView}
                                                            onChange={selectedRoomViewHandler}
                                                            value={RoomView?.isUpdate ?
                                                                { label: addRoomData.room_view, value: addRoomData?.room_view } : RoomView.find(option => option.value === addRoomData?.room_view)}
                                                            className="w-100 border-[1px] border-grey rounded-md bs-transparent focus:outline-0"
                                                        />
                                                    </div>
                                                    <div className="relative z-10 text-start drop-shadow-">
                                                        <p className='fs-7 font-semibold my-1 opacity-90'>Bed Type</p>

                                                        <Select
                                                            placeholder="Select Rooms View"
                                                            options={BedType}
                                                            onChange={selectedBedTypeHandler}
                                                            value={addRoomData?.isUpdate ? { label: addRoomData.bed_type, value: addRoomData?.bed_type } : BedType.find(option => option.value === addRoomData.bed_type)}
                                                            className="w-100 border-[1px] border-grey rounded-md bs-transparent focus:outline-0"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-50 text-start h-fit m-0">
                                                    <p className='fs-7 font-semibold my-1 opacity-90 manded'>Total Rooms</p>
                                                    <input pattern="[0-8]*" className='drop-shadow- border-[1px] border-grey rounded-md w-100 px-2 py-1 focus:!outline-none' placeholder='Total Rooms' type='number' name='total_rooms' value={addRoomData?.total_rooms} onChange={handleChange} />
                                                    <span className='tx-red fs-7 leading-3'>{addRoomData.totalRoomsErr}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100 px-4 h-fit gap-5">
                                            <div className="text-start">
                                                <p className='fs-7 font-semibold my-1 opacity-90 '>Dimensions</p>
                                                <div className=" flex gap-5 mt-3 justify- items-center text-start">
                                                    <div className="flex gap-3">
                                                        <div className={`${addRoomData?.dimensions === "size" ? "border-org tx-org" : "border-grey"}  border-[1px] py-1.5 px-4 rounded-[4px] pointer`} onClick={() => selectedDimensionHandler("size")}>
                                                            <p className='text-[12px] font-Roboto font-bold px-1 my-1 opacity-90'>Size</p>
                                                        </div>
                                                        <div className={`${addRoomData?.dimensions === "area" ? "border-org tx-org" : "border-grey"}  border-[1px] py-1.5 px-4 rounded-[4px] pointer`} onClick={() => selectedDimensionHandler("area")}>
                                                            <p className='text-[12px] font-Roboto font-bold px-1 my-1 opacity-90'>Area</p>
                                                        </div>
                                                    </div>
                                                    {addRoomData?.dimensions === "size" &&
                                                        <div className="flex items-center gap-3">
                                                            <div className="max-w-[100px] drop-shadow- border-[1px] border-grey rounded-[4px] h-fit overflow-hidden m-0">
                                                                <input className='w-100 px-2  my-0.5 py-1 focus:!outline-none' placeholder='Length' type='number' name="length" value={addRoomData?.length} onChange={handleChange} />
                                                            </div>
                                                            <i className="fa-regular fa-xmark fs-5"></i>
                                                            <div className="max-w-[100px] drop-shadow- border-[1px] border-grey rounded-[4px] h-fit overflow-hidden m-0">
                                                                <input className='w-100 px-2  my-0.5 py-1 focus:!outline-none' placeholder='Breadth' type='number' name="breadth" value={addRoomData?.breadth} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {addRoomData?.dimensions === "area" &&
                                                        <div className="flex items-center gap-3">
                                                            <div className="drop-shadow- border-[1px] border-grey rounded-[4px] h-fit overflow-hidden m-0">
                                                                <input className='w-100 px-2  my-0.5 py-1 focus:!outline-none' placeholder='Area' type='number' name="area" value={addRoomData?.area} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="relative min-w-[100px] z-[9] text-start drop-shadow-">

                                                        <Select
                                                            placeholder="Select Rooms View"
                                                            options={dimentionsoption}
                                                            onChange={selectedDimensionSizeType}
                                                            value={addRoomData?.isUpdate ?
                                                                { label: addRoomData.size_type, value: addRoomData.size_type } : dimentionsoption.find(option => option.value === addRoomData?.size_type)}
                                                            className="w-100 border-[1px] border-grey rounded-md bs-transparent focus:outline-0"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100 text-start">
                                            <p className='w-fit fs-6 font-bold tracking-tight py-3 px-4 font-Roboto'>OCCUPANCY</p>
                                            <div className="flex py-3 px-4 w-100 gap-5 items-center">
                                                <div className="flex justify-between col gap-3 items-center">
                                                    <div className="text-start col">
                                                        <p className='w-fit fs-6 font-medium tracking-tight font-Roboto'>Base Adults</p>
                                                        <p className='w-fit fs-7 font-medium tracking-tight font-Roboto opacity-80'>Ideal number of adults that can be accommodated in this room</p>
                                                    </div>

                                                    <div className="flex items-center rounded-md border-[1px] border-grey  w-fit h-fit mx-auto">
                                                        <div className="px-3 py-1 rounded-l border-r-[1px] border-grey pointer" onClick={baseadultdecNum}>
                                                            <i className="fa-solid fa-minus fs-6"></i>
                                                        </div>
                                                        <input type="text" className="font-semibold text-center min-w-[35px] !max-w-[35px]" value={addRoomData?.isUpdate ? addRoomData?.max_children : baseAdultnum} onChange={baseadulthandleChange} />
                                                        <div className="px-3 py-1 rounded-r border-l-[1px] border-grey pointer" onClick={baseadultincNum}>
                                                            <i className="fa-solid fa-plus fs-6"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between col gap-3 items-center">
                                                    <div className="text-start col">
                                                        <p className='w-fit fs-6 font-medium tracking-tight font-Roboto'>Maximum Adults</p>
                                                        <p className='w-fit fs-7 font-medium tracking-tight font-Roboto opacity-80'>Maximum number of adults that can be accommodated in this room</p>
                                                    </div>

                                                    <div className="flex items-center rounded-md border-[1px] border-grey  w-fit h-fit mx-auto">
                                                        <div className="px-3 py-1 rounded-l border-r-[1px] border-grey pointer" onClick={maxadultdecNum}>
                                                            <i className="fa-solid fa-minus fs-6"></i>
                                                        </div>
                                                        <input type="text" className="font-semibold text-center min-w-[35px] !max-w-[35px]" value={addRoomData?.isUpdate ? addRoomData?.maximum_adults : maxAdultnum} onChange={maxadulthandleChange} />
                                                        <div className="px-3 py-1 rounded-r border-l-[1px] border-grey pointer" onClick={maxadultincNum}>
                                                            <i className="fa-solid fa-plus fs-6"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex py-3 px-4 w-100 gap-5 items-center">
                                                <div className="flex justify-between col gap-3 items-center">
                                                    <div className="text-start col">
                                                        <p className='w-fit fs-6 font-medium tracking-tight font-Roboto'>Number of Max Children</p>
                                                        <p className='w-fit fs-7 font-medium tracking-tight font-Roboto opacity-80'>Mention the maximum number of children who can stay in the room</p>
                                                    </div>
                                                    <div className="flex items-center rounded-md border-[1px] border-grey  w-fit h-fit mx-auto">
                                                        <div className="px-3 py-1 rounded-l border-r-[1px] border-grey pointer" onClick={maxchildrendecNum}>
                                                            <i className="fa-solid fa-minus fs-6"></i>
                                                        </div>
                                                        <input type="text" className="font-semibold text-center min-w-[35px] !max-w-[35px]" value={addRoomData?.isUpdate ? addRoomData?.maximum_adults : maxChildrentnum} onChange={maxchildrenhandleChange} />
                                                        <div className="px-3 py-1 rounded-r border-l-[1px] border-grey pointer" onClick={maxchildrenincNum}>
                                                            <i className="fa-solid fa-plus fs-6"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between col gap-3 items-center">
                                                    <div className="text-start col">
                                                        <p className='w-fit fs-6 font-medium tracking-tight font-Roboto'>Maximum Occupancy (adults & children)</p>
                                                        <p className='w-fit fs-7 font-medium tracking-tight font-Roboto opacity-80'>Mention the total number of adults & children that can be accommodated in this room</p>
                                                    </div>

                                                    <div className="flex items-center rounded-md border-[1px] border-grey  w-fit h-fit mx-auto">
                                                        <div className="px-3 py-1 rounded-l border-r-[1px] border-grey cursor-not-allowed" >
                                                            <i className="fa-solid fa-minus fs-6"></i>
                                                        </div>
                                                        <input type="text" className="font-semibold text-center min-w-[35px] !max-w-[35px]" disabled value={addRoomData?.isUpdate ? addRoomData?.max_occupancy : maxOccupancy} />
                                                        <div className="px-3 py-1 rounded-r border-l-[1px] border-grey cursor-not-allowed" >
                                                            <i className="fa-solid fa-plus fs-6"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 my-4 px-4">
                                        <div className="rounded border-2">
                                            <div className="w-100 border-b-1 text-start py-2">
                                                <span className=' fs-5 font-bold px-3 whitespace-nowrap'>All Amenities</span>
                                            </div>
                                            <div className="w-100 flex rounded-md">
                                                <div className="w-[20%] flex flex-col overflow-y-scroll pe-2 scroll-d-none max-h-[500px] gap- ">
                                                    {amenitiesTypes.map((item, index) => (
                                                        <div key={index} className={`${item?.amenitiesType === filtredAmenitiesType ? "tx-white bs-blue drop-shadow-md  indicate-active " : "border-b-2"}  rounded-sm pointer h-fit relative `}>
                                                            <p value={item?.amenitiesType} onClick={() => setFiltredAmenitiesType(item?.amenitiesType)}
                                                                className={`${item?.amenitiesType === filtredAmenitiesType ? " " : " "} 
                                                            fs-6 text-start font-semibold px-3 py-2`}>{item?.amenitiesType}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="col flex flex-col ms-[-8px] border-l-2 overflow-y-scroll pe-2 scroll-d-non max-h-[500px] gap-1">
                                                    {filteredAmenitiesData.map((item, index) => (
                                                        console.log("itemitemitemitemitem", item),
                                                        <div key={index} className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center border-b-2 border- gap-3">
                                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleAminites} />
                                                            <div className=" h-fit">
                                                                <p className='fs-6 font-bold'>{item?.amenitiesname}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {filtredAmenitiesType === "Popular with Guests" &&
                                                        <>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value='airConditionings' onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>Air Conditioning</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'airConditionings') ? "" : "hidden"}  relative z-[9] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={airConditionoptions[0]}
                                                                        options={airConditionoptions}
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'airConditionings', 'drp1')}
                                                                        value={airConditionoptions.find(option => option.value === airConditionoptions) || airConditionoptions.find(option => option === airConditionoptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value='heater' onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>Heater</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'heater') ? "" : "hidden"}  relative z-[8] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={heateroptions[0]}
                                                                        options={heateroptions}
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'heater', 'drp2')}
                                                                        value={heateroptions.find(option => option.value === heateroptions) || heateroptions.find(option => option === heateroptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="housekeeping" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>Housekeeping</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'housekeeping') ? "" : "hidden"}  relative z-[7] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={houseKeepingoptions[0]}
                                                                        options={houseKeepingoptions}
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'housekeeping', 'drp3')}
                                                                        value={houseKeepingoptions.find(option => option.value === houseKeepingoptions) || houseKeepingoptions.find(option => option === houseKeepingoptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="inroomdining" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>In Room dining</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'inroomdining') ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={roomDiningoptions[0]}
                                                                        options={roomDiningoptions}
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'inroomdining', 'drp1')}
                                                                        value={roomDiningoptions.find(option => option.value === roomDiningoptions) || roomDiningoptions.find(option => option === roomDiningoptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="ironingboard" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'> Iron/Ironing Board </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="mineralwater" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'> Mineral Water</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'mineralwater') ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={mineralWateroptions[0]}
                                                                        options={mineralWateroptions}
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'mineralwater', 'drp1')}
                                                                        value={mineralWateroptions.find(option => option.value === mineralWateroptions) || mineralWateroptions.find(option => option === mineralWateroptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="roomservice" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>Room service</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'roomservice') ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={roomserviceoptions[0]}
                                                                        options={roomserviceoptions}
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'roomservice', 'drp1')}
                                                                        value={roomserviceoptions.find(option => option.value === roomserviceoptions) || roomserviceoptions.find(option => option === roomserviceoptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="studyroom" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'> Study Room </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="kettle" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>Kettle</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="wifi" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>Wifi</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'wifi') ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={wifioptions[0]}
                                                                        options={wifioptions}
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'wifi', 'drp1')}
                                                                        value={wifioptions.find(option => option.value === wifioptions) || wifioptions.find(option => option === wifioptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="bathroom" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'>Bathroom</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`${dropDownOptionShow('Popular with Guests', 'bathroom') ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                                                    <Select
                                                                        defaultValue={bathroomoptions[0]}
                                                                        options={bathroomoptions}
                                                                        //  onChange={selectedRoomViewHandler} 
                                                                        onChange={(e) => handleChangeDropDown(e, 'Popular with Guests', 'bathroom', 'drp1')}
                                                                        value={bathroomoptions.find(option => option.value === bathroomoptions) || bathroomoptions.find(option => option === bathroomoptions)}
                                                                        className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                                                <div className="flex items-center gap-2">
                                                                    <input className='accent-[#2c74b3] pointer' type="checkbox" value="airpurifier" onChange={handleAminites} />
                                                                    <div className="h-fit">
                                                                        <p className='fs-6 font-bold'> Air Purifier </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-5 h-fit w-fit mx-auto bs-blue rounded-md pointer">
                                        <button className='w-fit tx-white text-center py-1 px-5 font-bold' onClick={(e) => handleSubmitAddRooms(e)} type='submit'>Submit & Save</button>
                                    </div>
                                </form>
                            </div>
                        </>
                    }

                    {ActiveTabState === "room-list" &&
                        <div className="w-100 h-100 mx-auto text-center scroll-d-none overflow-y-scroll">
                            <div className="text-start">
                                <p className="fs-1 font-bold">Property</p>
                            </div>
                            <div className="flex flex-col my-4 gap- h-100 w-100 mx-auto">
                                <div className="flex bs-white py-1 px-2 items-center justify-between">
                                    <div className="w-fit px-2 py-2 ">
                                        <p className='font-semibold fs-5 text-left font-Roboto'>Existing Rooms ()</p>
                                    </div>
                                    <div className="w-fit bs-blue px-4 py-1 rounded pointer" onClick={() => setActiveTabState("rooms")}>
                                        <p className="tx-white fs-6 font-semibold">CREATE NEW ROOM</p>
                                    </div>
                                </div>

                                <div className="flex flex-col w-[100%] border-2 border-[#9595955b]">
                                    <div className="h-fit ">
                                        <div className="flex flex-col gap-3">
                                            {getAllRooms?.length > 0 && getAllRooms?.map((RoomData) => {
                                                return (
                                                    <>
                                                        <div className="rounded form-shadow-1">
                                                            <div className="col flex gap-3 py-3 px-3 rounded-sm overflow-hidden">
                                                                <div className="w-[20%] min-w-[140px] min-h-[140px] p-1 flex flex-col">
                                                                    <div className="col">
                                                                        <img className='min-h-[150px] max-h-[150px] w-100 object-cover rounded-md shadow-md' src={RoomData.cover_image} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="bs-blue col max-w-[1px] border-[1.5px] my-2 rounded-full border-blue"></div>
                                                                <div className="col flex justify-between gap-3">
                                                                    <div className="flex flex-col col h-fit">
                                                                        <div className="col text-start">
                                                                            <p className='fs-5 font-bold mt-2'>{RoomData?.display_name}</p>
                                                                        </div>
                                                                        <div className="col text-start">
                                                                            <p className="fs-6 font-semibold">Description :-</p>
                                                                            <p className='w-100 fs-7 rounded px-2 opacity-80 font-semibold'>{RoomData?.description}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-col w-fit items-end px-1">
                                                                        <div className="flex flex-col w-fit items-end px-1">
                                                                            <div className='tx-black flex items-center gap-2 pointer w-100 h-fit tx-blue px-3 py-1 rounded-md fs-6 mt-' onClick={() => {
                                                                                handlEditRoomData(RoomData?._id);
                                                                                setActiveTabState("rooms")
                                                                            }}>
                                                                                <i className="fa-regular fa-pencil"></i>

                                                                                <p className="font-semibold fs-6" >EDIT</p>
                                                                            </div>
                                                                            <div className='tx-black flex items-center gap-2 pointer w-100 h-fit tx-red px-3 py-1 rounded-md fs-6 mt-'
                                                                                onClick={() => handleDeleteRoom({
                                                                                    id: RoomData?._id,
                                                                                    name: RoomData?.display_name,
                                                                                })}>
                                                                                <i className="fa-solid fa-trash"></i>
                                                                                <p className="font-semibold fs-6" >DELETE</p>
                                                                            </div>
                                                                            <div className='tx-black flex items-center gap-2 pointer w-100 h-fit tx-blue px-3 py-1 rounded-md fs-6 mt-' onClick={() => handleRoomDetails(RoomData?._id)}>
                                                                                <i className="fa-solid fa-circle-info"></i>
                                                                                <p className="font-semibold fs-6" >SHOW DETAILS</p>
                                                                            </div>
                                                                            <div className='tx-black flex items-center gap-2 pointer w-100 h-fit tx-blue px-3 py-1 rounded-md fs-6 mt-' onClick={() => handleRateplanTypeModal(RoomData?._id)}>
                                                                                <i className="fa-sharp fa-solid fa-plus"></i>
                                                                                <p className="font-semibold fs-6">ADD RATE PLAN</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div >

                                                            <div className="w-100 p-3">
                                                                <div className="rounded border-2 border-[#4f4f4f5b] overflow-hidden">
                                                                    <div className="bg-[#bdbdbd] col flex border-b gap-3 ">
                                                                        <div className="col py-2 fs-6 font-bold tracking-wider border-r-2 border-[#4f4f4f5b]">Rateplan Name</div>
                                                                        <div className="col py-2 fs-6 font-bold tracking-wider ">Meal Plan</div>
                                                                        <div className="col py-2 fs-6 font-bold tracking-wider border-l-2 border-[#4f4f4f5b]">Actions</div>
                                                                    </div>
                                                                    {RoomData?.plans?.map((PlanData) => {
                                                                        return (
                                                                            <div className="col flex border-b-2 gap-3">
                                                                                <div className="col py-1 fs-6 font-semibold border-r-2 border-[#4f4f4f5b]">{PlanData?.plan_name}</div>
                                                                                <div className="col py-1 fs-6 font-semibold ">{PlanData?.meal_plan}</div>
                                                                                <div className="col py-1 flex flex-col gap-1 fs-6 font-semibold border-l-2 border-[#4f4f4f5b]">
                                                                                    <div className="flex text-left px-3 gap-2 items-center">

                                                                                        <label htmlFor="checkbox">
                                                                                            <input className='accent-[#2c74b3] pointer'
                                                                                                type="checkbox"
                                                                                                checked={JSON.parse(PlanData.active_status)}
                                                                                                onChange={(e) => {
                                                                                                    handleActiveDeactivePlan(
                                                                                                        RoomData?._id,
                                                                                                        PlanData?.plan_id,
                                                                                                        e.target.checked,
                                                                                                    );
                                                                                                }} />
                                                                                            Active
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="flex text-left px-3 gap-2 items-center" onClick={() => {
                                                                                        handlEditRatePlan(RoomData?._id, PlanData?.plan_id,);
                                                                                        setAddRateplanTypeModal(true)
                                                                                    }}>
                                                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                                                        <p>Edit Rateplan </p>
                                                                                    </div>
                                                                                    <div className='tx-black flex items-center gap-2 pointer w-100 h-fit tx-red px-3 py-1 rounded-md fs-6 mt-'
                                                                                        onClick={() => handleDeleteRatePlanData(RoomData?._id, PlanData?.plan_id)}
                                                                                    >
                                                                                        <i class="fa-solid fa-trash"></i>
                                                                                        <p className="font-semibold fs-6" >DELETE</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div >
                                    </div>


                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal isOpen={addrateplanTypeModal} backdrop={"blur"} placement={"center"} onOpenChange={closeRateplanTypeModal}>
                <ModalContent>
                    {(closeRateplanTypeModal) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Rate Plan</ModalHeader>
                            <ModalBody>

                                <div className="h-fit text-start m-0">
                                    <p className='fs-7 font-semibold my-1 opacity-90 manded'>Rateplan Name</p>
                                    <input className='w-100 drop-shadow- border-[1px] border-grey rounded-md px-2 py-1 focus:!outline-none' placeholder='Rateplan Name' type='text' name='plan_name' value={PlanDatas?.plan_name} onChange={handleChangePlanData} />
                                </div>
                                <div className="relative z-30 text-start drop-shadow-">
                                    <p className='fs-7 font-semibold my-1 opacity-90 manded'>Room Type</p>
                                    <Select
                                        placeholder="Select Rooms Type"
                                        options={ratePlansTypes}
                                        onChange={selectedPlanData}
                                        value={PlanDatas?.isUpdate ? { label: PlanDatas.meal_plan, value: PlanDatas.meal_plan } : ratePlansTypes.find(option => option.value === PlanDatas?.meal_plan)}
                                        className="w-100 border-[1px] border-grey rounded-md bs-transparent focus:outline-0"
                                    />
                                </div>
                                <div onPress={closeRateplanTypeModal}>
                                    Close
                                </div>
                                <button onPress={closeRateplanTypeModal} onClick={(e) => handleSubmitPlanData(e)} type="submit" >
                                    Action
                                </button>

                            </ModalBody>
                            <ModalFooter>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={roomInfoModal} backdrop={"blur"} placement={"center"} onOpenChange={closeInformationOfRoomTypeModal}>
                <ModalContent>
                    {(closeInformationOfRoomTypeModal) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Rate Plan</ModalHeader>
                            <ModalBody>
                                <div>Display Name:- {roomData?.display_name}</div>
                                <div>Image:-
                                    <img className='min-h-[150px] max-h-[150px] w-100 object-cover rounded-md shadow-md' src={roomData.images[0]} alt="" />
                                </div>
                                <div className="">amenities :- {roomData?.amenities?.map((aminitesData) => {
                                    return (
                                        <div>
                                            <p>{aminitesData?.amenetName}</p>
                                            <p>{aminitesData?.options?.map((aminitesOption) => {
                                                return (
                                                    <>
                                                        <p>{aminitesOption?.optionName}
                                                            {/* - {aminitesOption?.drp2?.value} */}
                                                        </p>
                                                    </>
                                                )
                                            })}</p>
                                        </div>
                                    )
                                })}</div>
                                <div className="">
                                    <p>MaxChildren :- {roomData?.max_children} </p>
                                    <p>MaxOccupancy :- {roomData?.max_occupancy} </p>
                                    <p>MaximumAdults :- {roomData?.maximum_adults} </p>
                                    <p>MaxChildren :- {roomData?.max_children} </p>
                                </div>
                                <div className="">{roomData?.plans?.map((planName) => {
                                    return (
                                        <>
                                            <div>
                                                <span>Plan Name :- {planName?.plan_name} </span>
                                                <p>Meal Plan :- {planName?.meal_plan}</p>
                                            </div>
                                        </>
                                    )
                                })}
                                </div>
                                <div className="">Room Type :- {roomData?.room_type}</div>
                                <div className="">Room View :- {roomData?.room_view}</div>
                                <div className="">Size :- {roomData?.size_type}</div>
                                <div className="">Total Rooms :- {roomData?.total_rooms}</div>
                                <div className="">Area Type :- {roomData?.dimensions}</div>
                                <div className="">Base Adult :- {roomData?.base_adult}</div>
                                <div className="">Bed Type :- {roomData?.bed_type}</div>
                                <div className="">Breadth :- {roomData?.breadth}</div>
                                <div className="">CoverImage :-
                                    <img className='min-h-[150px] max-h-[150px] w-100 object-cover rounded-md shadow-md' src={roomData.cover_image} alt="" />
                                </div>
                                <div className="">Date :- {moment(roomData?.created_at).format('YYYY-MM-DD')}</div>
                                <div className="">Description :- {roomData?.description}</div>

                            </ModalBody>
                            <ModalFooter>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
