import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllBookingAction } from '../../../redux/actions/booking';
import moment from 'moment';
import { Modal, ModalContent, ModalHeader, backdrop, ModalBody, ModalFooter, Button, useDisclosure, CircularProgress, Tooltip } from "@nextui-org/react";
import { DatePicker } from 'antd';
import Pagination from "react-bootstrap/Pagination";

const Booking = () => {

    const dispatch = useDispatch()
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [paymentStatusModal, setPaymentStatusModal] = useState(false);
    const { getAllBookingData } = useSelector((state) => state.bookingState);
    const [filterData, setFilterData] = useState({
        booking_name: "",
        selectedDate: null
    });
    const [filteredData, setFilteredData] = useState([]);

//pagination
const [currentPage, setCurrentPage] = useState(1);
const [PerPage, setPerPage] = useState(10);
const pageCount = Math.ceil(filteredData.length / PerPage);
const startIndex = (currentPage - 1) * PerPage;
const endIndex = startIndex + PerPage;
const displayedDataPaginated = Array.isArray(filteredData)
    ? filteredData.slice(startIndex, endIndex)
    : "";
const numbers =
    Array.isArray(filteredData) && filteredData?.length !== undefined
        ? [...Array(pageCount).keys()]?.map((num) => num + 1)
        : "";
const startSerial = (currentPage - 1) * PerPage + 1;


const prePage = () => {
    if (currentPage !== startIndex) {
        setCurrentPage(currentPage - 1);
    }
};

const handleChangePage = (id) => {
    setCurrentPage(id);
};

const handleItemsPerPageChange = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
};
const nextPage = () => {
    if (currentPage !== endIndex) {
        setCurrentPage(currentPage + 1);
    }
};

const paginationItems = [];
for (let i = 1; i <= pageCount; i++) {
    paginationItems.push(
        <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={handleChangePage}
        >
            {i}
        </Pagination.Item>
    );
}


    useEffect(() => {
        dispatch(getAllBookingAction())
    }, [])

    useEffect(() => {
        console.log("filterData.selectedDate",filterData.selectedDate);
        if (filterData.booking_name === ""
        //  || !filterData.selectedDate
        ) {
            setFilteredData(getAllBookingData);
        } else {
            const TempFilter = getAllBookingData.filter((item) => {
                const lowerCaseCodeOrName = filterData.booking_name.toLowerCase();
                console.log("lowerCaseCodeOrName", lowerCaseCodeOrName);
                const matchesCodeOrName =
                    item?.contact_number?.toLowerCase().includes(lowerCaseCodeOrName) ||
                    item?._id?.toLowerCase().includes(lowerCaseCodeOrName) ||
                    item?.name?.toLowerCase().includes(lowerCaseCodeOrName)

//                 const selectedDate = filterData.selectedDate;
//                 const itemDate = moment(item.created_at);
// console.log("itemDate",moment(itemDate).format('MM-DD-YYYY'));
                // const matchesDate = selectedDate ? itemDate.isSame(moment(selectedDate), 'day'): true;

                return (
                    (matchesCodeOrName) 
                    // || (matchesDate)
                );
            });
            setFilteredData(TempFilter);
        }
    }, [filterData, getAllBookingData]);

    const handlePaymentStatusModal = () => {
        setPaymentStatusModal(true)
    }

    const closePaymentStatusModal = () => {
        setPaymentStatusModal(false)
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            [name]: value,
        }));
    };

    const handleDateChange = (date) => {
        setFilterData((prevFilterData) => ({ ...prevFilterData, selectedDate: date }));
    };

    return (
        <>

            <div className="flex flex-col gap-3 mx-auto h-100 w-[90%] mt-4">
                <div className="w-100 flex justify-between mx-auto">
                    <div className="w-fit flex h-fit min-w-[400px] max-w-[400px] rounded-md relative">
                        <i className="absolute top-50 start-0 translate-middle-y px-3 fa-sharp fa-solid fa-magnifying-glass"></i>
                        <input className='w-100 border-2 ps-5 border-blue rounded-md px-3 py-1' type="text" name="booking_name" placeholder='Search by boking ID or guest name...'
                            onChange={handleInputChange}
                            value={filterData.booking_name} />
                    </div>
                    <div className="w-fit">
                        <div className="border-2 flex items-center gap-1 py-0.5 ps-3 border-blue rounded-md">
                            <i className="fa-regular fa-calendar tx-blue"></i>
                            <DatePicker selected={filterData.selectedDate} onChange={handleDateChange}/>
                        </div>
                    </div>
                </div>
                <div className="h-fit w-[100%] border-[1px] border-[#e5e7eb] mx-auto ">
                    <div className="flex bg-gray-200 gap-5 py-1.5">
                        <div className="col flex items-center px-3">
                            <div className="col px-2">
                                <p className='fs-7 text-start font-bold'>Guest Name</p>
                            </div>
                            <div className="col px-2">
                                <p className='fs-7 text-start font-bold'>Stay Duration</p>
                            </div>
                            <div className="col px-2">
                                <p className='fs-7 text-start font-bold'>Room & Meal Plan</p>
                            </div>
                            <div className="col px-2">
                                <p className='fs-7 text-start font-bold'>Booking ID</p>
                            </div>
                            <div className="col px-2">
                                <p className='fs-7 text-start font-bold'>Guest Contact</p>
                            </div>
                            <div className="col px-2">
                                <p className='fs-7 text-start font-bold'>Net Amountnet</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 py-2 px-[4px]">
                        {displayedDataPaginated?.length > 0 && displayedDataPaginated?.map((bookData) => {
                            return (
                                <>
                                    <div className="">
                                        <div className="flex items-center py-2 px-2">
                                            <p className='fs-7 font-bold tx-blue px-2'>TODAY, 7 FEBRUARY </p>
                                            <p className='fs-8 border-l-[1px] border-blue px-2'>2 Check-ins</p>
                                        </div>
                                        <div className="col flex flex-col gap-3 border-2 px-2 py-2.5 rounded-md overflow-hidden">
                                            {/* <div className="w-[16%] p-1 flex flex-col">
                                                    <div className="col">
                                                        <img className='rounded-md shadow-md' src="https://img.freepik.com/free-photo/luxury-classic-modern-bedroom-suite-hotel_105762-1787.jpg?t=st=1703074448~exp=1703075048~hmac=fcfca67c8e56c2b361863f7b6aaa8cd9fdcc3e59178ff9d93702416d9620d05c" alt="" />
                                                    </div>
                                                </div>
                                                <div className="bs-blue col max-w-[2px]  rounded-full border-blue"></div> */}
                                            <div className="col flex flex-col gap-2 justify-between">
                                                <div className="w-100 h-fit flex gap-3 items-center justify-between">
                                                    <div className="w-100 h-fit flex items-center justify-between ">
                                                        <div className="col px-2 text-start">
                                                            <p className='fs-6 font-bold '>{bookData?.name || "YOGESH UPADHYAY"}</p>
                                                        </div>
                                                        <div className="col px-2 text-start">
                                                            <p className='fs-7 font-semibold '>{moment(bookData?.checkin_date).format("MMM DD")} - {moment(bookData?.checkout_date).format("MMM DD")}</p>
                                                        </div>
                                                        {/* </div>
                                                    <div className="col h-fit flex gap-3 justify-around items-center"> */}
                                                        <div className="col whitespace-nowrap overflow-hidden text-ellipsis px-2 text-start">
                                                            <Tooltip className='fs-6' content="1 x Premium Twin Bed Room">
                                                                <p className='fs-7 font-semibold '>{bookData?.room_detail?.display_name}</p>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="col px-2 text-start">
                                                            <p className='fs-7 font-semibold '>{bookData?._id || 'GH76045210788890'}</p>
                                                        </div>
                                                        <div className="col px-2 text-start">
                                                            <p className='fs-7 font-semibold '>{bookData?.contact_number}</p>
                                                        </div>
                                                        <div className="col px-2 text-start">
                                                            <p className='fs-7 font-semibold '> Net Amount ₹ {bookData?.total_amount || 0}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bs-grey w-[98%] mx-auto rounded-full opacity-70 h-[1px]"></div>
                                                <div className="flex col justify-between items-end">
                                                    <div className="flex gap-2">
                                                        <div className="flex items-center px-2">
                                                            <p className='font-semibold fs-8 bg-[#e8fff0] text-[#37bc8e] px-2 py-1 rounded-md'>CHECKED-IN</p>
                                                        </div>
                                                        <div className="fs-6 tx-blue font-semibold" onClick={handlePaymentStatusModal}>
                                                            <p>Payment Details</p>
                                                        </div>
                                                    </div>
                                                    <div className="text-end px-1">
                                                        <Link to="/admin/voucher" >
                                                            <p className='tx-blue w-fit ms-auto border-2 border-blue px-4 py-1 rounded-md fs-6 font-bold'>Voucher</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                        {/* <div className="col flex gap-3 shadow-1 border-2 p-2 rounded-md overflow-hidden">
                                <div className="w-[16%] p-1 flex flex-col">
                                    <div className="col">
                                        <img className='rounded-md shadow-md' src="https://img.freepik.com/free-photo/luxury-classic-modern-bedroom-suite-hotel_105762-1787.jpg?t=st=1703074448~exp=1703075048~hmac=fcfca67c8e56c2b361863f7b6aaa8cd9fdcc3e59178ff9d93702416d9620d05c" alt="" />
                                    </div>
                                </div>
                                <div className="bs-blue col max-w-[2px]  rounded-full border-blue"></div>
                                <div className="col flex flex-col justify-between">
                                    <div className="w-100 flex col justify-between">
                                        <div className="w-100 flex gap-3">
                                            <div className="col text-start">
                                                <p className='text-[1rem] font-bold '>YOGESH UPADHYAY</p>
                                            </div>
                                            <div className="col text-start">
                                                <p className='text-[0.9rem] font-semibold '>1 x Premium Twin Bed Room</p>
                                            </div>
                                            <div className="w-fit text-start">
                                                <p className='text-[0.9rem] font-semibold '>Feb 05 - Feb 06</p>
                                            </div>
                                            <div className="w-fit text-start">
                                                <p className='fs-6 font-semibold '>GH76045210788890</p>
                                            </div>
                                            <div className="w-fit text-start">
                                                <p className='fs-6 font-semibold '>919825585607</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex col justify-between items-end">
                                        <div className="flex fs-7 items-center gap-2">
                                            <i className="fa-sharp fa-solid tx-green fa-badge-check"></i>
                                            <p className='font-semibold'>Check-In</p>
                                        </div>
                                        <div className="text-end px-1">
                                            <div className="col text-end">
                                                <p className='text-[1rem] font-bold '> Net Amount ₹ 2,886.84</p>
                                            </div>
                                            <Link to="/Room-Details" >
                                                <p className='tx-blue w-fit ms-auto border-2 border-blue px-4 py-1 rounded-md fs-6 mt-3 font-bold'>Voucher</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {displayedDataPaginated.length > 0 ? (
                    <div className="justify-content-start gap-2 d-flex align-items-center mt-3 pagination">
                        <div className="page-item">
                            <button
                                className="py-2 px-4 border tx-black rounded-2 bg-white"
                                onClick={prePage}
                                disabled={currentPage === 1}
                            >
                                <i className="fa-solid fa-angle-left pe-2"></i>
                                Previous
                            </button>
                        </div>
                        {numbers.map((no, i) => {
                            return (
                                <div className={`h-100 bs-blue rounded-md pointer px-2 py-1 ${currentPage === no ? "active" : ""}`} key={i}>
                                    <p
                                        className="border-0 tx-white h-100"
                                        onClick={() => handleChangePage(no)}>
                                        {no}
                                    </p>
                                </div>
                            );
                        })}
                        <div className="">
                            <li>
                                <button
                                    className="py-2 px-4 border tx-black rounded-2 bg-white"
                                    onClick={nextPage}
                                    disabled={currentPage === pageCount}
                                >
                                    Next<i className="fa-solid fa-angle-right ps-2"></i>
                                </button>
                            </li>
                        </div>
                        <div className="border-1 border-blue rounded-2 py-1 px-2">
                            <select
                                value={PerPage}
                                onChange={handleItemsPerPageChange}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20 </option>
                            </select>
                        </div>
                    </div>
                ) : null}

                    </div>
                </div>
            </div>


            <Modal className='close-btn-d-none !p-0 !m-0' isOpen={paymentStatusModal} backdrop={"blur"} placement={"start"} onOpenChange={closePaymentStatusModal}>
                <ModalContent className='!justify-end !ml-auto h-[100vh] !rounded-none'>
                    {(closePaymentStatusModal) => (
                        <>
                            <ModalBody className='h-[100vh] flex flex-col justify-between !p-0'>
                                <div className="w-100 h-fit">
                                    <div className="w-100 flex flex-col items-start gap-2 py-2 bs-white col relative mx-auto overflow-hidden rounded-lg">
                                        <div className="flex w-100 items-start border-b-[1px] pb-3 border-grey justify-between">
                                            <div className="col px-4">
                                                <p className='fs-5 font-bold font-Montserrat'>Payment Details</p>
                                                <p className='fs-6 font-semibold '>Booking Id : NH74046304527352</p>
                                            </div>
                                            <div className="w-fit px-4">
                                                <i className="fa-solid fa-xmark"></i>
                                            </div>
                                        </div>
                                        <div className="px-3 flex flex-col gap-3 mt-2">
                                            <div className="flex items-center gap-3 w-100 px-3 py-1 border-2 border-[#c2c7ec] bg-[#eff1ff]">
                                                <div className="">
                                                    {/* <CircularProgress size="sm" aria-label="Loading..." /> */}
                                                    <i className="fa-regular fa-circle-notch tx-blue"></i>
                                                </div>
                                                <div className="w-fit">
                                                    <p className='fs-6 font-bold font-Montserrat'> Payment Under Process</p>
                                                </div>
                                            </div>
                                            <div className="border-2 border-grey  rounded-md overflow-hidden">
                                                <p className='bg-[#f8f8f8] fs-6 px-3 py-1 font-semibold'>DETAILS</p>
                                                <div className=" px-3 py-1">
                                                    <p className='opacity-70 fs-7 font-semibold'>Payment has not been processed for this booking. Please check later.</p>
                                                    <p className='opacity-70 fs-7 font-semibold mt-2'>BOOKING AMOUNT</p>
                                                    <p className='fs-6 font-bold mt-3'>₹ 12254.33</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="bs-blue rounded-md py-1 ">
                                        <p className='tx-white text-center fs-5 font-semibold'>Close</p>
                                    </div>
                                </div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal >
        </>
    )
}

export default Booking