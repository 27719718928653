import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DatePicker } from 'antd';
import { Modal, ModalContent, ModalHeader, backdrop, ModalBody, ModalFooter, Button, useDisclosure, CircularProgress, Tooltip } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { getAllBookingAction } from '../../../redux/actions/booking';
import moment from 'moment';
import Pagination from "react-bootstrap/Pagination";


export default function PaymentList() {
    const { RangePicker } = DatePicker;
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [paymentStatusTypeModal, setPaymentStatusTypeModal] = useState(false);
    const dispatch = useDispatch();
    const { getAllBookingData } = useSelector((state) => state?.bookingState);
    const [bookData, setBookData] = useState();
    const [filterData, setFilterData] = useState({
        booking_name: "",
        selectedDate: null
    });
    const [filteredData, setFilteredData] = useState([]);

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(10);
    const pageCount = Math.ceil(filteredData.length / PerPage);
    const startIndex = (currentPage - 1) * PerPage;
    const endIndex = startIndex + PerPage;
    const displayedDataPaginated = Array.isArray(filteredData)
        ? filteredData.slice(startIndex, endIndex)
        : "";
    const numbers =
        Array.isArray(filteredData) && filteredData?.length !== undefined
            ? [...Array(pageCount).keys()]?.map((num) => num + 1)
            : "";
    const startSerial = (currentPage - 1) * PerPage + 1;


    const prePage = () => {
        if (currentPage !== startIndex) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleChangePage = (id) => {
        setCurrentPage(id);
    };

    const handleItemsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };
    const nextPage = () => {
        if (currentPage !== endIndex) {
            setCurrentPage(currentPage + 1);
        }
    };

    const paginationItems = [];
    for (let i = 1; i <= pageCount; i++) {
        paginationItems.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={handleChangePage}
            >
                {i}
            </Pagination.Item>
        );
    }

    const closePaymentStatusModal = () => {
        setPaymentStatusTypeModal(false)
    }

    const handleBookDetails = (id) => {
        const bookDetail = getAllBookingData?.find((item) => {
            return item?._id === id;
        });
        setBookData(bookDetail);
        setPaymentStatusTypeModal(true)
    }

    const handleSearchInputChange = (event) => {
        const { name, value } = event.target;
        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            [name]: value,
        }));
    };

    useEffect(() => {
        dispatch(getAllBookingAction())
    }, [dispatch]);

    useEffect(() => {
        console.log("filterData.selectedDate", filterData.selectedDate);
        if (filterData.booking_name === ""
            //  || !filterData.selectedDate
        ) {
            setFilteredData(getAllBookingData);
        } else {
            const TempFilter = getAllBookingData.filter((item) => {
                const lowerCaseCodeOrName = filterData.booking_name.toLowerCase();
                const matchesCodeOrName =
                    item?.contact_number?.toLowerCase().includes(lowerCaseCodeOrName) ||
                    item?._id?.toLowerCase().includes(lowerCaseCodeOrName) ||
                    item?.name?.toLowerCase().includes(lowerCaseCodeOrName)

                //                 const selectedDate = filterData.selectedDate;
                //                 const itemDate = moment(item.created_at);
                // console.log("itemDate",moment(itemDate).format('MM-DD-YYYY'));
                // const matchesDate = selectedDate ? itemDate.isSame(moment(selectedDate), 'day'): true;

                return (
                    (matchesCodeOrName)
                    // || (matchesDate)
                );
            });
            setFilteredData(TempFilter);
        }
    }, [filterData, getAllBookingData]);

    console.log("getAllBookingData", bookData);


    return (
        <>
            <div className="flex flex-col bs-white p-3 rounded-md gap-3 mx-auto h-100 w-[90%] my-4">
                <div className="w-100 flex justify-between mx-auto">
                    <div className="w-fit flex h-fit min-w-[400px] max-w-[400px] rounded-md relative">
                        <i className="absolute top-50 start-0 translate-middle-y tx-blue px-3 fa-sharp fa-solid fa-magnifying-glass"></i>
                        <input className='w-100 border-1 ps-5 border-blue rounded-md px-3 py-1' type="text"
                            name='booking_name'
                            placeholder='Booking ID/Bank Ref number'
                            onChange={handleSearchInputChange}
                            value={filterData.booking_name}
                        />
                    </div>
                    <div className="w-fit">
                        <div className="border-1 bs-white payment-date flex items-center gap-1 py-0.5 ps-3 border-blue rounded-md">
                            <RangePicker
                                format="MMM DD, YYYY"
                                label='Tarix'
                                placeholder={["14-02-2024", "15-08-2021"]}
                                className='w-100 border-x-0 border-t-0 !border-b-[1px] border-gray-200 !rounded-none py-2'
                            />
                            <i className="fa-regular fa-calendar tx-blue px-2"></i>
                        </div>
                    </div>
                </div>

                <div className="flex gap-3 w-100">
                    <div className="flex w-[25%] gap-2 bs-white rounded-md border-1 p-3 items-center">
                        <div className="">
                            <div className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] rounded-md bg-[#e9ebf6] flex items-center justify-center">
                                <i className="fa-solid fa-circle-euro fs-1 text-[#3543bf]"></i>
                            </div>
                        </div>
                        <div className="px-3 text-start">
                            <p className='fs-7 leading-[5px] font-semibold'>Amount Payable</p>
                            <p className='text-[1.8rem] leading-[40px] font-bold'>₹ 3,56,042</p>
                        </div>
                    </div>
                    <div className="flex w-[25%] gap-2 bs-white rounded-md border-1 p-3 items-center">
                        <div className="">
                            <div className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] rounded-md bg-[#e6f5ec] flex items-center justify-center">
                                <i className="fa-solid fa-circle-euro fs-1 text-[#3bad69]"></i>
                            </div>
                        </div>
                        <div className="px-3 text-start">
                            <p className='fs-7 leading-[5px] font-semibold'>Total Payout Done</p>
                            <p className='text-[1.8rem] leading-[40px] font-bold'>₹ 2,94,743</p>
                        </div>
                    </div>
                    <div className="flex w-[25%] gap-2 bs-white rounded-md border-1 p-3 items-center">
                        <div className="">
                            <div className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] rounded-md bg-[#ffece0] flex items-center justify-center">
                                <i className="fa-solid fa-circle-euro fs-1 text-[#ef864c]"></i>
                            </div>
                        </div>
                        <div className="px-3 text-start">
                            <p className='fs-7 leading-[5px] font-semibold'>Payment Pending</p>
                            <p className='text-[1.8rem] leading-[40px] font-bold'>₹ 12,099</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="drop-shadow-md">
                        <p className='fs-3 px-1 font-bold w-fit'>Booking Details</p>
                    </div>
                    <div className="flex gap-3 w-fit">
                        <div className="w-fit flex items-center pointer gap-2 tx-red">
                            <i className="fa-solid fa-file-pdf"></i>
                            <p className='fs-6 font-semibold drop-shadow-md'>DOWNLOAD PDF</p>
                        </div>
                        <div className="w-fit flex items-center pointer gap-2 tx-blue">
                            <i className="fa-solid fa-file-xls"></i>
                            <p className='fs-6 font-semibold drop-shadow-md'>EXCEL PDF</p>
                        </div>
                    </div>
                </div>
                <table className="payment-table w-100 mx-auto border-[2px] border-[#d1d3d5] rounded-md overflow-hidden">
                    <thead className="bg-[#bdbdbd]">
                        <tr className="">
                            <td className="col px-3">
                                <p className='fs-7 text-start font-bold'>Sr No.</p>
                            </td>
                            <td className="col px-3">
                                <p className='fs-7 text-start font-bold'>Check-In Date</p>
                            </td>
                            <td className="col py-2 px-3">
                                <p className='fs-7 text-start font-bold'>Guest Name</p>
                            </td>
                            <td className="col py-2 px-3">
                                <p className='fs-7 text-start font-bold'>Booking ID</p>
                            </td>
                            <td className="col py-2 px-3">
                                <p className='fs-7 text-start font-bold'>Booking Type</p>
                            </td>
                            <td className="col py-2 px-3">
                                <p className='fs-7 text-start font-bold'>Booking Status</p>
                            </td>
                            <td className="col py-2 px-3">
                                <p className='fs-7 text-start font-bold'>Booking Amount</p>
                            </td>
                            <td className="col py-2 px-3">
                                <p className='fs-7 text-start font-bold'>Payment Status</p>
                            </td>
                            <td className="col py-2 px-3">
                                <p className='fs-7 text-start font-bold'>Details</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody className="">
                        {displayedDataPaginated?.length > 0 ? displayedDataPaginated?.map((bookDetails, index) => {
                            return (
                                <tr className="border-t-1 border-[#d1d3d5]" key={index}>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-center font-semibold'>{startSerial + index}</p>
                                    </td>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-center font-semibold'>{moment(bookDetails?.checkin_date).format('DD-MM-YYYY')}</p>
                                    </td>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-start font-semibold'>{bookDetails?.name || "Guest Name"}</p>
                                    </td>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-start font-semibold tx-blue'>{bookDetails?._id}</p>
                                        <p className='fs-7 text-start font-semibold'>(0111219885)</p>
                                    </td>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-center font-semibold'>Paid Online</p>
                                    </td>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-center font-semibold tx-org'>Confirmed</p>
                                    </td>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-center font-semibold'>₹ 1,951.49</p>
                                    </td>
                                    <td className="col py-2 px-3">
                                        <p className='fs-7 text-end font-semibold'>{bookDetails?.payment_status || "₹ 1,505"}</p>
                                        <p className='fs-8 text-end font-semibold tx-green'>Settled on 12th Nov 2023</p>
                                    </td>
                                    <td className="col py-2 px-3" onClick={() => handleBookDetails(bookDetails?._id)}>
                                        <p className='fs-7 text-center font-semibold'>view</p>
                                    </td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td colSpan={8} className='txt-center'>Data is not found</td>
                            </tr>}

                    </tbody>
                </table>


                {displayedDataPaginated.length > 0 ? (
                    <div className="justify-content-start gap-2 d-flex align-items-center mt-3 pagination">
                        <div className="page-item">
                            <button
                                className="py-2 px-4 border tx-black rounded-2 bg-white"
                                onClick={prePage}
                                disabled={currentPage === 1}
                            >
                                <i className="fa-solid fa-angle-left pe-2"></i>
                                Previous
                            </button>
                        </div>
                        {numbers.map((no, i) => {
                            return (
                                <div className={`h-100 bs-blue rounded-md pointer px-2 py-1 ${currentPage === no ? "active" : ""}`} key={i}>
                                    <p
                                        className="border-0 tx-white h-100"
                                        onClick={() => handleChangePage(no)}>
                                        {no}
                                    </p>
                                </div>
                            );
                        })}
                        <div className="">
                            <li>
                                <button
                                    className="py-2 px-4 border tx-black rounded-2 bg-white"
                                    onClick={nextPage}
                                    disabled={currentPage === pageCount}
                                >
                                    Next<i className="fa-solid fa-angle-right ps-2"></i>
                                </button>
                            </li>
                        </div>
                        <div className="border-1 border-blue rounded-2 py-1 px-2">
                            <select
                                value={PerPage}
                                onChange={handleItemsPerPageChange}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20 </option>
                            </select>
                        </div>
                    </div>
                ) : null}

            </div>

            <Modal className='close-btn-d-none !p-0 !m-0' isOpen={paymentStatusTypeModal} backdrop={"blur"} placement={"start"} onOpenChange={closePaymentStatusModal}>
                <ModalContent className='!justify-end !ml-auto h-[100vh] min-w-[600px] !rounded-none'>
                    {(closePaymentStatusModal) => (
                        <>
                            <ModalBody className='h-[100vh] min-w-[600px] overflow-y-scroll flex flex-col justify-between !p-0'>
                                <div className="w-100 h-fit pb-5">
                                    <div className="w-100 flex flex-col items-start gap-2 py-2 px-1 bs-white col relative mx-auto overflow-hidden rounded-lg">
                                        <div className="flex w-100 items-start pt-2 justify-between">
                                            <div className="col px-3 py-2">
                                                <p className='fs-1 font-bold font-Montserrat'>Booking Details</p>
                                                {/* <p className='fs-6 font-semibold '>Booking Id : NH74046304527352</p> */}
                                            </div>
                                        </div>
                                        <div className="w-100 px-3 flex flex-col gap-2 mt-">
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Booked By </p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">{bookData?.name} </p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Booking ID</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">{bookData?._id || "NH71186289603478"}</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">PNR Details</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">0111219885</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Checkin Date</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">{moment(bookData?.checkin_date).format('Do MMM YYYY') || "26th Jan 2024"}</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Checkout Date</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">{moment(bookData?.checkout_date).format('Do MMM YYYY') || "27th Jan 2024"}</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Number of Guest(s)</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">{bookData?.number_of_adults || 0}</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Booking Type</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Paid Online</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Booking Status</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 text-[#fe7d32] font-bold">Confirmed</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100 px-3 py-2 mt-4">
                                            <p className='fs-1 font-bold font-Montserrat'>Payment Breakup</p>
                                        </div>
                                        <div className="w-100 px-3 w-100 flex flex-col gap-2 mt-2">
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Payable to Hotel</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">₹ 1,505</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Commission (Including GST)</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">₹ 411.21</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Commission</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">₹ 348.48</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">GST @ 18%</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">₹ 62.73</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Coupon Discount</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">₹ 1,425.6</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">Tax</p>
                                                </div>
                                                <div className="">
                                                    <p className="opacity-70 fs-7 font-semibold">₹ 34.84</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between text-black items-center">
                                                <div className="">
                                                    <p className="opacity-90 fs-7 font-bold">Booking Amount</p>
                                                </div>
                                                <div className="border-t-[1.5px] ps-3 border-black">
                                                    <p className="opacity-90 fs-7 font-bold">₹ 1,951.49</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex w-100 items-center pt-2 px-3 mt-4 justify-between">
                                            <div className="w-100 py-2">
                                                <p className='fs-1 font-bold font-Montserrat'>Payment Details</p>
                                            </div>
                                            <div className="bg-[#e6f5ec] rounded-md px-2 py-0.5">
                                                <p className='fs-7 text-[#3aad67] font-semibold whitespace-nowrap'>₹ 1,505 Settled</p>
                                            </div>
                                        </div>
                                        <div className="px-3">
                                            <table className="payment-table w-100 mx-auto border-[2px] border-[#d1d3d5] rounded-md overflow-hidden">
                                                <thead className="bg-[#bdbdbd]">
                                                    <tr className="">
                                                        <td className="col px-3">
                                                            <p className='fs-7 text-start font-bold'>Date</p>
                                                        </td>
                                                        <td className="col py-2 px-3">
                                                            <p className='fs-7 text-start font-bold'>Payment Type</p>
                                                        </td>
                                                        <td className="col py-2 px-3">
                                                            <p className='fs-7 text-start font-bold'>Amount</p>
                                                        </td>
                                                        <td className="col py-2 px-3">
                                                            <p className='fs-7 text-start font-bold'>Bank Reference No / Adjustment ID</p>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    <tr className="border-t-1 border-[#d1d3d5]">
                                                        <td className="col py-2 px-3">
                                                            <p className='fs-7 text-center font-semibold'>12th Nov 2023</p>
                                                        </td>
                                                        <td className="col py-2 px-3">
                                                            <p className='fs-7 text-center font-semibold'>CreditMemo</p>
                                                            <p className='fs-7 text-center font-semibold'>(CreditMemo 0111560530)</p>
                                                        </td>
                                                        <td className="col py-2 px-3">
                                                            <p className='fs-7 text-center font-semibold'>₹ 1,505</p>
                                                        </td>
                                                        <td className="col py-2 px-3">
                                                            <p className='fs-7 text-center font-semibold'>GH23212205601338</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div className="p-2 sticky bottom-0 bs-white">
                                    <div className="bs-blue rounded-md py-1 ">
                                        <p className='tx-white text-center fs-5 font-semibold'>Close</p>
                                    </div>
                                </div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>



        </>
    )
}
