import React from 'react'
import { Link } from 'react-router-dom'

export default function Header({ toggleSideBar }) {
    return (
        <>
            <div className="w-100">
                <div className="flex px-3 justify-between">
                    <div className="" onClick={toggleSideBar}>
                        <i className="fa-solid fa-bars-sort px-1"></i>
                    </div>
                    <div className="flex gap-3 items-center">
                        <div className=" ease-soft-spring duration-1000">
                            <p className='fs-8 font-bold bg-slate-950 rounded tx-white px-3 py-[2px] text-center'>3⭐Hotel</p>
                        </div>
                        <div className="flex items-center justify-center rounded-full bg-[#c2c7ec] min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] ease-soft-spring duration-1000">
                            <i className="fa-solid fa-user tx-blue"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
