const STORAGEKEY = {
    token: 'token',
    authData: 'authData',
    userData: 'userData',
    userId: 'userId',
    layoutData: 'layoutData',
    roles: 'roles',
    signupData: 'signupData',
    year: 'year'
};

export default STORAGEKEY
