import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover, PopoverTrigger, PopoverContent, Button } from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import ScrollSpy from "react-ui-scrollspy";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addUserProfileAction } from '../../../redux/actions/userProfile';



export default function SiteHeader({ setUserLoginModal }) {

    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [propertyRules, setPropertyRules] = useState(false);
    const [profileGender, setProfileGender] = useState('');
    const { mobile, _id, is_verified } = JSON.parse(localStorage.getItem('LogInData') || '{}') || {};
    const [profileData, setProfileData] = useState({
        image: "",
        user_id: _id,
        mobile: mobile,
        email: "",
        first_name: "",
        last_name: "",
        dob: new Date(),
        gender: ""
    });
    const dispatch = useDispatch();
    console.log(profileGender, "profileGender");
    const closePropertyRulesTypeModal = () => {
        setPropertyRules(false);
    }

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setProfileData((prevProfileData) => ({
            ...prevProfileData,
            [name]: value
        }));
    };

    const handleSetProfileGender = (gender) => {
        setProfileGender(gender);
    };

    const handleChangeDate = (date) => {
        setProfileData((prevFilterData) => ({ ...prevFilterData, bod: date }));
    };

    // const handleChangeDate = (date, dateType) => {
    //     const formattedDate = date ? moment(date).format('DD-MM-YYYY') : null;
    //     setProfileData((prevCouponCode) => ({
    //         ...prevCouponCode,
    //         [dateType]: formattedDate,
    //     }));
    // };


    const handleSubmitProfiledata = () => {
        let body = {
            image: "",
            user_id: _id,
            mobile: mobile,
            email: profileData?.email,
            first_name: profileData?.first_name,
            last_name: profileData?.last_name,
            dob: profileData?.dob,
            gender: profileGender
        }
        console.log("body", body);
        dispatch(addUserProfileAction(body))

    }

    const handleLogOut = () => {
        localStorage.removeItem('LogInData');
    }


    return (
        <>
            <div className="flex items-center w-[90%] lg:!w-[85%] duration-800 ease-in-out bs-white fixed z-50 top-2 left-0 right-0 rounded-lg my-3 mx-auto shadow-md">
                <div className="col flex items-center justify-between duration-500 ease-in-out">
                    <div className="block relative w-fit py-2 px-3 lg:!px-5 duration-500 ease-in-out">
                        <div className="h-fit absolute top-0 start-50 mt-[-7px] border-[1.5px] border-dpink translate-middle bs-white rounded-md overflow-hidden">
                            <img className='w-[50px]' src="../../../SIte_logos/Hotel-logo-Lable-rm-borderd.png" alt="" />
                        </div>
                        <Link to="/" className="mb-[-0px]">
                            <img className='w-[115px]' src="../../../SIte_logos/Everland logo-content.png" alt="" />
                        </Link>
                    </div>
                    <div className="hidden lg:!flex gap-5 items-center duration-500 ease-in-out">
                        <Link to="" className="">
                            <p className='fs-6 pointer text-gray-500 font-bold'>Reception</p>
                        </Link>
                        <div className="">
                            <p className='fs-6 pointer text-gray-500 font-bold'>Rajkot Attractions</p>
                        </div>
                        <div className="" onClick={() => setPropertyRules(true)}>
                            <p className='fs-6 pointer text-gray-500 font-bold'>Property Rules</p>
                        </div>
                        <Link to="/Rooms" className="">
                            <p className='fs-6 pointer text-gray-500 font-bold'>Rooms</p>
                        </Link>
                        <Link to="" className="">
                            <p className='fs-6 pointer text-gray-500 font-bold'>FAQ's</p>
                        </Link>
                    </div>
                    <div className="hidden lg:!flex gap-4 pl-2 mr-[-92px] xl:!mr-[-92px] px-3 xl:!px-5 items-center relative z-[1] duration-500 ease-in-out">
                        <div className="flex gap-2 items-center">
                            <div className="h-fit w-[30px]">
                                <img className='w-100 h-100 object-cover' src="../../../header/Manage Booking Icon.png" alt="" />
                            </div>
                            <div className="">
                                <p className='text-gray-500 leading-[15px] fs-7 font-semibold m-0 '>My Trips</p>
                                <p className='fs-6 leading-[15px] font-semibold '>Manage Booking</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* aa login modal open karva mate  */}
                {is_verified === true ? "" :
                    <div onClick={() => setUserLoginModal(true)} className="w-fit flex items-center md:gap-2 border-mix-pink before:p-0 md:before:p-[2px] !border-l-0 pointer rounded-r-lg py-2.5 md:pl-[5rem] md:pr-[2rem] duration-500 ease-in-out">
                        <div className=" w-[2px] h-[60%] py-3 md:mx-3 rounded-full bs-pink"></div>
                        <div className=" flex rounded-full bs-pink mx-2.5 items-center justify-center min-w-[25px] max-w-[25px] min-h-[25px] max-h-[25px] w-fit">
                            <i className="fa-solid fa-user tx-white fs-8 mx-2 lg:!mx-0"></i>
                        </div>
                        <div className="">
                            <p className='fs-6 tracking-wider tx-pink font-bold'>LOGIN / SIGNUP</p>
                        </div>
                    </div>
                }


                {is_verified === true &&
                    <Popover placement={"bottom-end"} backdrop={"blur"} color="primary">
                        <PopoverTrigger className='!scale-100'>
                            <div className="w-fit flex items-center md:gap-2 border-mix-pink before:p-0 md:before:p-[2px] !border-l-0 pointer rounded-r-lg py-2.5 md:pl-[5rem] md:pr-[2rem] duration-500 ease-in-out">
                                <div className="w-[2px] h-[60%] py-3 md:mx-3 rounded-full bs-pink"></div>
                                <div className="rounded-full bs-pink flex mx-2.5 items-center justify-center min-w-[25px] max-w-[25px] min-h-[25px] max-h-[25px] w-fit">
                                    <i className="fa-solid fa-user tx-white fs-8 mx-2 lg:!mx-0 "></i>
                                </div>
                                <div className="hidden lg:!block">
                                    <p className='fs-6 tracking-wider tx-pink font-bold'>MR . JIMMY GAJJAR</p>
                                </div>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent className='bs-white !min-w-[300px] !items-start profile-mix-pink'>
                            <div className="p-3 flex flex-col gap-3 relative z-10">
                                <div className="flex items-center gap-3">
                                    {/* <div className="relative">
                                 <div className="mx-auto rounded-full overflow-hidden bs-pink flex items-center justify-center min-w-[110px] max-w-[110px] min-h-[110px] max-h-[110px] w-fit">
                                     <img className="w-100 h-100 scale-150" src='https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg' />
                                 </div>
                                 <div className="absolute top-[5px] end-[5px] z-10">
                                     <label htmlhtmlFor="coverphoto" className="pointer drop-shadow-md rounded-lg w-fit ">
                                         <input className='hidden'
                                             type="file" id="coverphoto" name="coverphoto" accept=".jpg,.jpeg,.png" />
                                     </label>
                                 </div>
                             </div> */}
                                    <div className="relative rounded-full overflow-hidden bs-mix-pink flex items-center justify-center min-w-[110px] max-w-[110px] min-h-[110px] max-h-[110px] ">
                                        {/* <i className="fa-solid fa-user tx-white text-[2rem] mx-2 lg:!mx-0"></i>*/}
                                        <p className='text-[50px] font-bold !text-[#fff] font-Montserrat'>J</p>
                                    </div>
                                    <div>
                                        <p className='!text-[#000] font-bold fs-6 drop-shadow'>{profileData?.first_name} - {profileData?.last_name}</p>
                                        <p className='!text-[#878787] font-semibold fs-7 drop-shadow  '>{profileData?.mobile}</p>
                                        <p className='!text-[#878787] font-semibold fs-7 drop-shadow  '>{profileData?.email}</p>
                                    </div>
                                </div>

                                <div className="bg-gradient-to-r from-[#ffffff] from-1% via-[#d11242] via-50% to-[#ffffff] to-99% min-h-[2px] max-h-[2px] col "></div>

                                <div className="flex md:!flex-row flex-col gap-3">
                                    <div className="w-100 ">
                                        <div className="coolinput">
                                            <label className="text tx-8 manded-not tx-blue">First Name</label>
                                            <input type="text" name="first_name" className="input border-blue" value={profileData?.first_name} onChange={handleChangeData} />
                                            {/* <span style={{ color: 'red' }}>{_error}</span> */}
                                        </div>
                                    </div>
                                    <div className="w-100 ">
                                        <div className="coolinput">
                                            <label className="text tx-8 manded-not tx-blue">Last Name</label>
                                            <input type="text" name="last_name" className="input border-blue" value={profileData?.last_name} onChange={handleChangeData} />
                                            {/* <span style={{ color: 'red' }}>{_error}</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex md:!flex-row flex-col gap-3">
                                    <div className="w-100 ">
                                        <div className="coolinput">
                                            <label className="text tx-8 manded-not tx-blue">Mo.number</label>
                                            <input type="text" name="mobile" className="input border-blue" value={profileData?.mobile} disabled />
                                            {/* <span style={{ color: 'red' }}>{_error}</span> */}
                                        </div>
                                    </div>
                                    <div className="w-100 ">
                                        <div className="coolinput">
                                            <label className="text tx-8 manded-not tx-blue">E-mail</label>
                                            <input type="text" name="email" className="input border-blue" value={profileData?.email} onChange={handleChangeData} />
                                            {/* <span style={{ color: 'red' }}>{_error}</span> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-100 ">
                             <label className="font-bold fs-8 manded-not tx-blue px-2">DOB</label>
                             <div className="w-100 profile date-picker border-1 rounded-md border-blue py-1.5" >
                             <input type="date" value={profileData.dob} onChange={(e) => handleChangeDate(e.target.value)} />
                              
                              
                                 {/* <DatePicker 
                                 //  className="absolute top-50 start-50 translate-middle z-10 caret-transparent"
                                 //  selected={profileData.dob} 
                                  onChange={handleChangeDate}/>
                                    <i className="fa-light fa-calendar px-3"></i> 
                                 {/* <span style={{ color: 'red' }}>{_error}</span> 
                             </div>
                         </div> */}

                                <div className="flex gap-3 w-100">
                                    <div
                                        onClick={() => handleSetProfileGender('male')}
                                        className={`${profileGender === "male" ? "bs-mix-pink !text-[#fff]" : "border-1 border-blue !text-[#3f4fdf]"}  col pointer rounded px-3 py-1`}>
                                        <p className=' text-center fs-6 font-bold mx-auto'>Male</p>
                                    </div>
                                    <div
                                        onClick={() => handleSetProfileGender('female')}
                                        className={`${profileGender === "female" ? "bs-mix-pink !text-[#fff]" : "border-1 border-blue !text-[#3f4fdf]"}  col pointer rounded px-3 py-1`}>
                                        <p className='text-center fs-6 font-bold mx-auto'>Female</p>
                                    </div>
                                </div>
                                <div className='flex gap-3 w-100'>
                                    <div className="py-1.5 rounded text-center border-1 border-pink w-50">
                                        <p className='!text-[#be0e3a] font-bold' onClick={() => handleSubmitProfiledata()}>UPDATE</p>
                                    </div>
                                    <div className="py-1.5 rounded text-center border-1 border-pink w-50">
                                        <p className='!text-[#be0e3a] font-bold' onClick={() => handleLogOut()}>LogOut</p>
                                    </div>
                                </div>


                            </div>
                        </PopoverContent>
                    </Popover>

                }
                {/* aa profile pen karva mate jyare login hoy tyare batavvu */}
            </div>

            <Modal isOpen={propertyRules} backdrop={"blur"} onOpenChange={closePropertyRulesTypeModal}>
                <ModalContent className='min-w-[700px]'>
                    {(closePropertyRulesTypeModal) => (
                        <>
                            <ModalBody>
                                <nav id="navbar-example2" className="navbar mt-3 navbar-light bg-light px-1 !py-1 rounded-lg">
                                    <ul className="nav nav-pills flex !flex-row !flex-nowrap overflow-x-scroll scroll-d-none">
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading1">Restrictions</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading2">Guest Profile</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading3">ID Proof Related</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading4">Food Arrangement</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading5">Smoking</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading6">Pet(s)</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading7">Accessibility</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading8">Other Rules</a>
                                        </li>
                                        <li className="nav-item w-fit hover:bg-pink-700 py-1 rounded-md ">
                                            <a className="hover:!text-white px-3 py-3 whitespace-nowrap" href="#scrollspyHeading9">Policy</a>
                                        </li>
                                    </ul>
                                </nav>
                                <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" className="scrollspy-example  pb-3 mb-3 max-h-[400px] overflow-y-scroll" tabindex="0">
                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading1">Restrictions</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Passport, Aadhar and Driving License are accepted as ID proof(s)</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Pets are not allowed.</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Outside food is not allowed</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Smoking within the premises is not allowed</p>



                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading2">Guest Profile</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Unmarried couples allowed</p>



                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading3">ID Proof Related</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]"> Passport, Aadhar and Driving License are accepted as ID proof(s)</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Local ids are allowed</p>


                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading4">Food Arrangement</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Non veg food is allowed</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Outside food is not allowed</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Food delivery service is not available at the property</p>



                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading5">Smoking/Alcohol consumption Rules</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Smoking within the premises is not allowed</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">There are no restrictions on alcohol consumption.</p>



                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading6">Pet(s) Related</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Pets are not allowed.</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">There are no pets living on the property</p>



                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading7">Property Accessibility</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">This property is accessible to guests who use a wheelchair. Guests are requested to carry their own wheelchair.</p>


                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading8">Other Rules</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Allows private parties or events</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">Registration Number: HOTN004557</p>



                                    <h4 className='my-2 mt-3 font-bold tx-black' id="scrollspyHeading9">Child & Extra Bed Policy</h4>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">An extra bed will be provided to accommodate any child included in the booking for a charge mentioned below. (Subject to availability)</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">INR 5000 will be charged for an extra cot per child. (To be paid at the property)</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">INR 5000 will be charged for an extra crib per child. (To be paid at the property)</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">An extra bed will be provided to accommodate any additional guest included in the booking for a charge mentioned below. (Subject to availability)</p>
                                    <p clasName="fs-8 font-semibold tx-black opacity-[0.3]">INR 5000 will be charged for an extra cot per guest. (To be paid at the property)</p>

                                </div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>

        </>
    )
}