import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CheckCouponCodeAction, getAllCouponCodeAction } from '../../../redux/actions/couponCode';
import { addBookingAction, getAllBookingAction } from '../../../redux/actions/booking';
import { getAllRoomsAction } from '../../../redux/actions/room';

export default function BookingReview() {
    const [codeName, setCodeName] = useState('');
    const dispatch = useDispatch();
    const { getAllCouponCode } = useSelector((state) => state?.couponCodeState)
    const roomsData = JSON.parse(localStorage.getItem('roomsData') || '{}') || {};
    const SearchData = JSON.parse(localStorage.getItem('searchData') || '{}') || {};
    const { mobile } = JSON.parse(localStorage.getItem('LogInData') || '{}') || {};
    const singleRoomData =JSON.parse(localStorage.getItem('bookRoomDetails') || '{}') || {};
    const [guestInfo, setGuestInfo] = useState({
        name: "",
        email: "",
        contact_number: mobile,
        any_rquest: "",
    })
    console.log("mobilemobilemobile", singleRoomData);

    const handleChangeCouponCode = (e) => {
        setCodeName(e.target.value);
    };

    const handleSubmitCode = () => {
        let body = {
            code_name: codeName,
        }
        dispatch(CheckCouponCodeAction(body))
    }

    const handleChangeGuestInfo = (e) => {
        const {name,value} = e.target;
        setGuestInfo({
            ...guestInfo,
            [name]:value
        })
    };

    const handleSubmitBookIngData = (e) => {
        e.preventDefault();
        let body = {
            number_of_rooms:SearchData?.number_of_rooms,
            number_of_adults:SearchData?.number_of_adults,
            number_of_childrens:SearchData?.number_of_childrens,
            number_of_infants:SearchData?.number_of_infants,
            number_of_night:SearchData?.number_of_night,
            checkin_date:SearchData?.date[0],
            checkout_date:SearchData?.date[1],
            total_amount:"",
            name: guestInfo?.name,
            email: guestInfo?.email,
            contact_number: guestInfo?.contact_number,
            any_rquest: guestInfo?.any_rquest,
        }
        console.log("BodyBody",body);
        dispatch(addBookingAction(body))
    }

    useEffect(() => {
        dispatch(getAllCouponCodeAction());
        // dispatch(getAllRoomsAction());
    }, [])

    return (
        <div>
            <div className="w-100 container mt-[90px]">
                <div className="w-100">
                    <p className='fs-5 font-bold '>Review your Booking</p>
                    <div className="flex flex-col md:!flex-row gap-4 my-2">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col md:!flex-row h-fit items-center gap-3 shadow-1 py-3 px-3">
                                <div className="min-w-[100%] max-w-[100%] md:!min-w-[15%] md:!max-w-[15%]">
                                    <img className='w-100 h-100 rounded shadow-md object-cover pointer ease-in-out transition-transform hover:scale-[1.050]' src={singleRoomData?.cover_image} alt="" />
                                </div>
                                <div className="block md:!hidden w-fit rounded  bs-mix-pink px-3">
                                    <p className='fs-8 tx-white font-bold'>{SearchData?.number_of_night} NIGHTS | {SearchData?.number_of_adults} Adults | {roomsData?.total_rooms} Room</p>
                                </div>
                                <div className="col w-100 relative flex flex-col md:!flex-row items-center">
                                    <div className="col w-100 flex items-center justify-between">
                                        <div className="w-fit">
                                            <p className='text-gray-400 fs-7 font-semibold'>CHECK IN</p>
                                            <p className='fs-7 text-gray-900'><span className='fs-5 tx-black font-bold font-Montserrat'>{SearchData?.date?.[0]}</span></p>
                                            <p className='text-gray-400 fs-7 font-semibold'>12 PM</p>
                                        </div>
                                        <div className="md:!block hidden w-fit">
                                            <p className='fs-8 font-semibold border-1 bs-mix-pink tx-white px-2 rounded-full '>{SearchData?.number_of_night} NIGHTS</p>
                                        </div>
                                        <div className="w-fit">
                                            <p className='text-gray-400 fs-7 font-semibold'>CHECK OUT</p>
                                            <p className='fs-7 text-gray-900'> <span className='fs-5 tx-black font-bold font-Montserrat'>{SearchData.date[SearchData.date.length - 1]}</span></p>
                                            <p className='text-gray-400 fs-7 font-semibold'>12 PM</p>
                                        </div>
                                    </div>
                                    <div className="md:!block hidden w-fit absolute bottom-0 start-50 rounded translate-middle-x bs-mix-pink px-3">
                                        <p className='fs-8 tx-white font-bold'>{singleRoomData?.maximum_adults} Adults | {singleRoomData?.total_rooms} Room</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-white">
                                <p className='fs-5 font-bold'>{singleRoomData?.display_name}</p>
                                <p className='fs-7 opacity-70 font-bold'>{singleRoomData?.maximum_adults} Adults</p>

                                <div className="flex gap-1 items-center px-2 mt-1">
                                    <i className={`fa-sharp fa-solid fa-pot-food  fs-6 min-w-[30px] max-w-[30px] text-center`}></i>
                                    <p className={`fs-7 font-semibold `}> Free Breakfast </p>
                                </div>
                                {/* <div className="flex gap-1 items-center px-2 mt-1 tx-red">
                                    <i className={`fa-sharp fa-solid fa-circle-xmark  fs-6 min-w-[30px] max-w-[30px] text-center`}></i>
                                    <p className={`fs-7 font-semibold `}>No meals included </p>
                                </div> */}
                                <div className="flex gap-1 items-center px-2 mt-1 tx-green">
                                    <i className={`fa-sharp fa-solid fa-badge-check  fs-6 min-w-[30px] max-w-[30px] text-center`}></i>
                                    <p className={`fs-7 font-semibold `}> Free Cancellation till check-in </p>
                                </div>
                                <div className="w-100 bg-pink-200 border-1 px-3 py-2 my-2 md:!my-3 rounded">
                                    <p className='font-bold'>Important information</p>
                                    <ul className="p-2 list-disc">
                                        <li className='fs-7 mx-3 opacity-80'>Unmarried couples are not allowed</li>
                                        <li className='fs-7 mx-3 opacity-80'>Guests below 18 years of age are not allowed at the property.</li>
                                        <li className='fs-7 mx-3 opacity-80'>Passport, Aadhar and Govt. ID are accepted as ID proof(s)</li>
                                        <li className='fs-7 mx-3 opacity-80'>Pets are not allowed.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="text-center">
                                    <p className='fs-5 font-bold tx-pink'>Guest Information</p>
                                </div>
                                <form>
                                    <div className="col mt-3">
                                        <input
                                            className={`form-control`}
                                            placeholder="Enter Your Full Name"
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={guestInfo?.name}
                                            onChange={handleChangeGuestInfo}
                                        />
                                    </div>
                                    <div className="col flex gap-3 mt-3">
                                        <div className="col">
                                            <input
                                                className={`form-control`}
                                                placeholder="Enter Your E-mail"
                                                type="text"
                                                name="email"
                                                id="email"
                                                value={guestInfo?.email}
                                                onChange={handleChangeGuestInfo}
                                            />
                                        </div>
                                        <div className="col">
                                            <input
                                                className={`form-control`}
                                                placeholder="Enter Your Number"
                                                type="text"
                                                name="contact_number"
                                                id="contact_number"
                                                value={guestInfo?.contact_number}
                                                onChange={handleChangeGuestInfo}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3 col">
                                        <textarea
                                            type="text"
                                            name="any_rquest"
                                            id="any_rquest"
                                            className={`form-control  ? "border-red" : "" }`}
                                            placeholder="Any Requests"
                                            value={guestInfo?.any_rquest}
                                            onChange={handleChangeGuestInfo}
                                        />
                                    </div>
                                    <div className="text-center my-4" onClick={handleSubmitBookIngData}>
                                        <button type="submit" className="bs-mix-pink rounded-md tx-white font-bold px-4 py-1 fs-6">
                                            Book Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="min-w-[100%] max-w-[100%] md:!min-w-[30%] md:!max-w-[30%] flex flex-col gap-3 font-Montserrat">
                            <div className="">
                                <p className='py-1 px-3 fs-5 font-bold shadow-1 bs-mix-pink tx-white'>PRICE BREAKUP</p>
                                <div className="bs-white mx-3 shadow-1 px-3">

                                    <div className="flex items-start justify-between px-2 py-2 border-b-[2px] border-pink ">
                                        <div className="">
                                            <p className='fs-6 font-bold'> 1 Room x 1 Nights</p>
                                            <p className='fs-7 font-medium tx-grey'>Base Price</p>
                                        </div>

                                        <p className='fs-6 font-bold'>₹ 3,798</p>
                                    </div>

                                    <div className="flex items-center justify-between px-2 py-2 border-b-[2px] border-pink opacity-80">
                                        <p className='tx-dpink font-medium fs-6'>Total Discount</p>
                                        <p className='tx-dpink font-medium fs-6'>₹ 1,299</p>
                                    </div>

                                    <div className="flex items-center justify-between px-2 py-2 border-b-[2px] border-pink ">
                                        <p className='fs-6 font-thin '>Price after Discount</p>
                                        <p className='fs-6 font-thin '>₹ 6,297</p>
                                    </div>

                                    <div className="flex items-center justify-between px-2 py-2 border-b-[2px] border-pink ">
                                        <p className='fs-6 font-thin '> Taxes & Service Fees</p>
                                        <p className='fs-6 font-thin '>₹ 798</p>
                                    </div>

                                    <div className="flex items-center justify-between px-2 py-2 border-pink ">
                                        <div className="flex w-[80%] items-start">
                                            <input className='mt-0.5' type="checkbox" />
                                            <p className='fs-8 font-medium ps-2'>Donate ₹5 to build resilient travel destinations. T&Cs</p>
                                        </div>
                                        <p className=''>₹ 0</p>
                                    </div>

                                </div>
                                <div className="flex items-center tx-white px-3 justify-between p-2 bs-mix-pink">
                                    <p className='fs-6 font-bold'>Total Amount to be paid</p>
                                    <p className='font-bold'>₹ 7,095</p>
                                </div>
                            </div>
                            <div className="">

                                <p className='py-1 px-3 fs-5 font-bold shadow-1 bs-mix-pink tx-white'>COUPON CODES</p>

                                <div className="flex flex-col gap-2 p-3 bs-white mx-3 shadow-1">
                                    {getAllCouponCode?.length > 0 && getAllCouponCode?.map((Data) => {
                                        return (
                                            <div className="flex gap-2 rounded border-2 border-dashed border-pink p-2">
                                                <div className="">
                                                    <input className='accent-rose-600' type="checkbox" />
                                                </div>
                                                <div className="col">
                                                    <p className='fs-6 font-bold'>{Data?.code_name}</p>
                                                    <p className='fs-7 font-semibold text-gray-400'>Discount of INR 6418 Applied</p>
                                                </div>
                                                <div className="">
                                                    <p className='whitespace-nowrap'>₹ 6,418</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="flex w-100 py-2 bs-white">
                                    <div className="col border-2 shadow-1 border-pink">
                                        <input className='w-100' type="string" value={codeName} onChange={handleChangeCouponCode} />
                                    </div>
                                    <div className="w-fit py-2 bs-mix-pink" onClick={handleSubmitCode}>
                                        <p className='px-3 fs-8 font-bold shadow-1 whitespace-nowrap tx-white' >COUPON CODE</p>
                                        {/* <p className='px-3 fs-8 font-bold shadow-1  whitespace-nowrap tx-white'> </p> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
