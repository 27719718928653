import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
// import { RangePicker } from 'your-range-picker-library';
import { Modal, ModalBody, ModalContent, useDisclosure } from "@nextui-org/react";
import dayjs from 'dayjs';
import moment from 'moment';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { ApiPost } from '../../../helper/axios';
import { siteLoginAction } from '../../../redux/actions/auth';
import { getAllPriceDateAction } from '../../../redux/actions/inventory';
import { getAllTestimonialsAction } from '../../../redux/actions/testimonials';

const { RangePicker } = DatePicker;


export default function SiteHero({ userLoginModal, setUserLoginModal }) {
    // const [enddate, setEndDate] = useState(new Date());
    // const [startdate, setStartDate] = useState(new Date());
    const [roomselector, setRoomselector] = useState(new Date());
    const [testimonialDetailsTypeModal, setTestimonialDetailsTypeModal] = useState(false);
    const [guestDetails, setGuestDetails] = useState(false);

    const [otpBoxDisplayHandle, setotpBoxDisplayHandle] = useState(false);
    const [otp, setOtp] = useState('')
    const [seconds, setSeconds] = useState(60);
    const [otpReSend, setOtpReSend] = useState(false);
    const [countDownStart, setCountDownStart] = useState(false);

    const handleOtpBoxChange = (newValue) => {
        setOtp(newValue)
    }

    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const handleGuestDetails = () => {
        setGuestDetails(prevCheck => !prevCheck);
    }

    const [bookingDates, setBookingDates] = useState();

    const [roomnum, setRoomNum] = useState(0);
    const [adultnum, setAdultNum] = useState(0);
    const [childrennum, setChildrenNum] = useState(0);
    const dispatch = useDispatch();
    const [loginData, setLoginData] = useState({
        mobile: "",
        mobile_error: "",
    })
    const dateFormat = 'YYYY/MM/DD';
    const defaultStartDate = moment().startOf('day');
    const defaultEndDate = moment().startOf('day').add(1, 'days');
    const [selectedDates, setSelectedDates] = useState([dayjs(), dayjs()]);
    const [startDate, setStartDate] = useState(dayjs());
    const [numOfDays, setNumOfDays] = useState(null);
    const [endDate, setEndDate] = useState(dayjs());
    const { getAllTestimonials } = useSelector((state) => state?.testimonialsState)
    const [residenceType, setResidenceType] = useState('indian');
    const [preferenceType, setPreferenceType] = useState('civils');
    const naviget = useNavigate();
    const { is_verified } = JSON.parse(localStorage.getItem('LogInData') || '{}') || {};
    console.log("is_verified", is_verified);
    const roomincNum = () => {
        if (roomnum < 8) {
            setRoomNum(Number(roomnum) + 1);
        }
    };

    const roomdecNum = () => {
        if (roomnum > 0) {
            setRoomNum(roomnum - 1);
        }
    }

    const roomhandleChange = (e) => {
        setRoomNum(e.target.value);
    }

    const adultincNum = () => {
        if (adultnum < 10) {
            setAdultNum(Number(adultnum) + 1);
        }
    };

    const adultdecNum = () => {
        if (adultnum > 0) {
            setAdultNum(adultnum - 1);
        }
    }

    const adulthandleChange = (e) => {
        setAdultNum(e.target.value);
    }

    const childrenincNum = () => {
        if (childrennum < 10) {
            setChildrenNum(Number(childrennum) + 1);
        }
    };

    const childrendecNum = () => {
        if (childrennum > 0) {
            setChildrenNum(childrennum - 1);
        }
    }

    const childrenhandleChange = (e) => {
        setChildrenNum(e.target.value);
    }

    const bookingDateHandler = (values) => {
        setBookingDates(values?.map(item => {
            return moment(item).format('DD-MM-YYYY')
        }))
    }

    const closeRateplanTypeModal = () => {
        setUserLoginModal(false)
    }

    const handleTestimonialDetailsTypeModal = () => {
        setTestimonialDetailsTypeModal(true)
    }

    const closeTestimonialDetailsTypeModal = () => {
        setTestimonialDetailsTypeModal(false)
    }

    const heroBannerSlider = {
        arrows: false,
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
        speed: 1000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const testimoniyalslider = {
        arrows: true,
        dots: false,
        autoplay: true,
        infinite: true,
        rtl: true,
        slidesToShow: 2,
        centerMode: false,
        slidesToScroll: 1,
        speed: 2000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const adsSlider = {
        arrows: true,
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 2,
        centerMode: false,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const validateInput = (name, value) => {
        if (name === 'mobile') {
            const phoneNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            if (!phoneNoRegex.test(value)) {
                return 'Please enter a valid phone number';
            }
        }
        return '';
    };

    const handleChangeLoginData = (e) => {
        const { name, value } = e.target;
        setLoginData((prevData) => ({
            ...prevData,
            [name]: value,
            [`${name}Error`]: validateInput(name, value),
        }));

        const inputValue = e.target.value;

        // Check if the input contains exactly 10 digits
        if (inputValue.length === 10) {
            setotpBoxDisplayHandle(true)
            setTimeout(() => {
                const input = document.querySelector('.MuiInputBase-input');
                input.focus();
            }, 1000);
            setSeconds(5);
            setCountDownStart(true);
            if (seconds === 0) {
                setOtpReSend(true)
            }
        } else {
            setotpBoxDisplayHandle(false);
        }
    };

    const handleNationalityChange = (e) => {
        setResidenceType(e.target.value);
    };
    const handlePreferencesChange = (e) => {
        setPreferenceType(e.target.value);
    };

    const handleSearch = () => {
        const selectedDatesArray = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
            selectedDatesArray.push(currentDate.format('DD-MM-YYYY'));
            currentDate = currentDate.add(1, 'day');
        }

        const dateString = selectedDatesArray.map(date => date);
        let body = {
            date: dateString,
            number_of_adults: adultnum,
            number_of_childrens: childrennum,
            // number_of_infants:,
            number_of_night:numOfDays,
            number_of_rooms: roomnum,
            preferences: preferenceType,
            nationality: residenceType,
        }
        const dataToStore = JSON.stringify(body);
        dispatch(getAllPriceDateAction(body));
        localStorage.setItem('searchData', dataToStore);
        naviget('/Rooms')

    }

    useEffect(() => {
        let interval;
        if (countDownStart && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            clearInterval(interval);
            setCountDownStart(false);
        }
        return () => clearInterval(interval);
    }, [countDownStart, seconds]);

    useEffect(() => {
        dispatch(getAllTestimonialsAction())
    }, []);

    const handleSubmitLogin = async () => {
        if (loginData?.mobile?.length >= 10) {
            const mobile_error = validateInput('mobile', loginData.mobile);

            setLoginData((prevData) => ({
                ...prevData,
                mobile_error,
            }));

            if (!mobile_error) {
                let body = {
                    mobile: loginData?.mobile
                }
                dispatch(siteLoginAction(body));
            }
        }
    };

    useEffect(() => {
        handleSubmitLogin();
    }, [loginData?.mobile]);

    const handleVerifyOtp = async () => {
        if (otp.length >= 6) {
            try {
                let userVerifyData = {
                    mobile: loginData?.mobile,
                    otp: otp
                };

                const res = await ApiPost(`/api/user/verify/otp`, userVerifyData);
                console.log("resresres", res?.data?.data);
                localStorage.setItem('LogInData', JSON.stringify(res?.data?.data));
                toast.success(res?.data?.message, { autoClose: 1000 });
            } catch (error) {
                console.log("error", error);
                toast.error(error?.response?.data?.message, { autoClose: 1000 });
            }
        }
    };

    useEffect(() => {
        handleVerifyOtp();
    }, [otp]);


    // const handleDateChange = (dates) => {
    //     if (dates && dates.length === 2) {
    //         const [start, end] = dates;
    //         setStartDate(start);
    //         setEndDate(end);
    //         const diffTime = Math.abs(end - start);
    //         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //         setNumOfDays(diffDays);
    //     }
    // };
    // const formatPlaceholder = (dates) => {
    // Format placeholder text based on selected dates
    // if (dates && dates.length === 2) {
    //     return [dates[0].format('MMM DD, YYYY'), dates[1].format('MMM DD, YYYY')];
    // }
    // else {
    //   return ['Check-', 'Check-'];
    // }
    // };
    // useEffect(() => {
    //     // Set default values for today and tomorrow
    //     const defaultStartDate = moment().startOf('day');
    //     const defaultEndDate = moment().startOf('day').add(1, 'days');

    //     // Update state with default values
    //     setStartDate(defaultStartDate);
    //     setEndDate(defaultEndDate);

    //     // Calculate and set the default number of nights
    //     const diffTime = Math.abs(defaultEndDate - defaultStartDate);
    //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //     setNumOfDays(diffDays);
    // }, []);

    // const disabledDate = (d) => {
    //     return d && d.isBefore(moment());
    // };


    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setNumOfDays(diffDays);
        }
    };
    const formatPlaceholder = (dates) => {
    };

    useEffect(() => {
        // Set default values for today and tomorrow
        const defaultStartDate = moment().startOf('day');
        const defaultEndDate = moment().startOf('day').add(1, 'days');

        // Update state with default values
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);

        // Calculate and set the default number of nights
        const diffTime = Math.abs(defaultEndDate - defaultStartDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setNumOfDays(diffDays);
    }, []);
    const disabledDate = (current) => {
        return current && current.isBefore(dayjs(), 'day');
    };

    const handleContinueClick = () => {
        const mobile_error = validateInput('mobile', loginData.mobile);

        setLoginData((prevData) => ({
            ...prevData,
            mobile_error,
        }));
        if (!mobile_error && otp !== "") {
            window.location.pathname = "/"
        }
    };

    console.log("roomnum", roomnum);

    return (
        <>
            <div className="w-100 relative">
                <div className="w-100  bg-[#eff3f8]">
                    <div className="hero-background"></div>
                    <div className="hero-background-v2"></div>
                    <div className="w-100 container mx-auto pt-[90px] lg:!pt-[100px] relative z-[5]">
                        <div className="flex flex-col  lg:!flex-row gap-4">
                            <div className="w-100 py-5 lg:!w-[50%] lg:!order-1 order-2">
                                <div className="adslider flex mt-1">
                                    {/* <Slider {...adsSlider}> */}
                                    <div className="col px-3 mx-auto">
                                        <div className="bs-white col relative mx-auto overflow-hidden rounded-lg">
                                            <div className="">
                                                <img src="https://gos3.ibcdn.com/img-1667580000.jpg" alt="" />
                                            </div>
                                            <div className="px-3 py-2 ">
                                                <p className='fs-7 font-semibold'>Tap to get up to 16% instant discount now.</p>
                                                <p className='fs-8 mt-2 opacity-50 font-semibold'>Valid till: 31st Jan'23</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col px-3 mx-auto">
                                        <div className="bs-white col relative mx-auto overflow-hidden rounded-lg">
                                            <div className="">
                                                <img src="https://gos3.ibcdn.com/hotel-1705862692.jpg" alt="" />
                                            </div>
                                            <div className="px-3 py-2 ">
                                                <p className='fs-7 font-semibold'>Tap to get up to 16% instant discount now.</p>
                                                <p className='fs-8 mt-2 opacity-50 font-semibold'>Valid till: 31st Jan'23</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </Slider> */}
                                </div>
                                <div className="testimonialsSlider">
                                    <div className="">
                                        <p className='w-fit fs-5 my-2 text-black lg:!text-white font-bold px-3'>Testimonials</p>
                                    </div>
                                    <Slider className='pt-2' {...testimoniyalslider}>
                                        <div className="col px-3 mx-auto">
                                            <div className="flex items-center gap-2 px-2 py-2 bs-white col relative mx-auto rounded-lg">
                                                {/* <div className="rounded-full bs-sky-blue flex items-center justify-center min-w-[50px] max-w-[50px]  min-h-[50px] max-h-[50px] w-fit">
                                                    <i className="fa-solid fa-user tx-blue fs-5"></i>
                                                </div> */}
                                                <div className="px-1 col">
                                                    <p className='fs-6 font-bold font-Montserrat'>Kalpesh Vaghasiya</p>
                                                    <p className='w-[170px] fs-7 font-semibold whitespace-nowrap overflow-hidden text-ellipsis pe-2'>Room is good, all service good, breakfast is good, security is good</p>
                                                </div>
                                                <div className="absolute top-0 end-0 px-2" onClick={handleTestimonialDetailsTypeModal}>
                                                    <i className="fa-duotone fa-circle-info pointer tx-pink"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-3 mx-auto">
                                            <div className="flex items-center gap-2 px-2 py-2 bs-white col relative mx-auto overflow-hidden rounded-lg">
                                                {/* <div className="rounded-full bs-sky-blue flex items-center justify-center min-w-[50px] max-w-[50px]  min-h-[50px] max-h-[50px] w-fit">
                                                    <i className="fa-solid fa-user tx-blue fs-5"></i>
                                                </div> */}
                                                <div className="px-1">
                                                    <p className='fs-6 font-bold font-Montserrat'>amit anand</p>
                                                    <p className='w-[170px] fs-7 font-semibold whitespace-nowrap overflow-hidden text-ellipsis pe-2'>Property is located in market area on road rooms are clean</p>
                                                </div>
                                                <div className="absolute top-0 end-0 px-2" onClick={handleTestimonialDetailsTypeModal}>
                                                    <i className="fa-duotone fa-circle-info pointer tx-pink"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-3 mx-auto">
                                            <div className="flex items-center gap-2 px-2 py-2 bs-white col relative mx-auto overflow-hidden rounded-lg">
                                                {/* <div className="rounded-full bs-sky-blue flex items-center justify-center min-w-[50px] max-w-[50px]  min-h-[50px] max-h-[50px] w-fit">
                                                    <i className="fa-solid fa-user tx-blue fs-5"></i>
                                                </div> */}
                                                <div className="px-1">
                                                    <p className='fs-6 font-bold font-Montserrat'>Ullas Retrekar</p>
                                                    <p className='w-[170px] fs-7 font-semibold whitespace-nowrap overflow-hidden text-ellipsis pe-2'>The experience was amazing actually. The room was spacious as atleast for 3 people</p>
                                                </div>
                                                <div className="absolute top-0 end-0 px-2" onClick={handleTestimonialDetailsTypeModal}>
                                                    <i className="fa-duotone fa-circle-info pointer tx-pink"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-3 mx-auto">
                                            <div className="flex items-center gap-2 px-2 py-2 bs-white col relative mx-auto overflow-hidden rounded-lg">
                                                {/* <div className="rounded-full bs-sky-blue flex items-center justify-center min-w-[50px] max-w-[50px]  min-h-[50px] max-h-[50px] w-fit">
                                                    <i className="fa-solid fa-user tx-blue fs-5"></i>
                                                </div> */}
                                                <div className="px-1">
                                                    <p className='fs-6 font-bold font-Montserrat'>fatehbir singh</p>
                                                    <p className='w-[170px] fs-7 font-semibold whitespace-nowrap overflow-hidden text-ellipsis pe-2'>location is awesome nd alot of peace, all staff very polite</p>
                                                </div>
                                                <div className="absolute top-0 end-0 px-2" onClick={handleTestimonialDetailsTypeModal}>
                                                    <i className="fa-duotone fa-circle-info pointer tx-pink"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-3 mx-auto">
                                            <div className="flex items-center gap-2 px-2 py-2 bs-white col relative mx-auto overflow-hidden rounded-lg">
                                                {/* <div className="rounded-full bs-sky-blue flex items-center justify-center min-w-[50px] max-w-[50px]  min-h-[50px] max-h-[50px] w-fit">
                                                    <i className="fa-solid fa-user tx-blue fs-5"></i>
                                                </div> */}
                                                <div className="px-1">
                                                    <p className='fs-6 font-bold font-Montserrat'>Amitesh Pareek</p>
                                                    <p className='w-[170px] fs-7 font-semibold whitespace-nowrap overflow-hidden text-ellipsis pe-2'>Hotel was very good for a stay. Best choice o mmt. Breakfast was upto mark</p>
                                                </div>
                                                <div className="absolute top-0 end-0 px-2" onClick={handleTestimonialDetailsTypeModal}>
                                                    <i className="fa-duotone fa-circle-info pointer tx-pink"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className="w-100 lg:!w-[50%] order-1 lg:!order-2">
                                <p className='text-right hidden md:!block tx-pink fs-5 font-p mb-2 drop-shadow-lg font-bold px-2'>Book your stay</p>
                                <p className='text-center  block md:!hidden tx-white fs-5 font-Poppins mb-2 drop-shadow-lg font-bold px-2'>Book your stay</p>
                                <div className="col w-100 form-shadow-1 col bs-white relative p-2 lg:!p-4 rounded-xl">
                                    <div className="flex justify-around md:!justify-start gap-3 px-3 mt-2 lg:!mt-0 lg:!px-2.5">
                                        <div className="flex gap-2 items-center lg:!items-baseline">
                                            <input type="radio" id="indian" name="residence" value="indian" className='accent-[#d11242] md:!mt-2 scale-[1.1] lg:!scale-[1.1]' checked={residenceType === 'indian'}
                                                onChange={handleNationalityChange} />
                                            <label htmlFor="indian" className='text-[12px] lg:!text-[18px] font-bold'>Indian</label>
                                        </div>
                                        <div className="flex gap-2 items-center lg:!items-baseline">
                                            <input type="radio" id="nri" name="residence" value="nri" className='accent-[#d11242] md:!mt-2 scale-[1.1] lg:!scale-[1.1]'
                                                checked={residenceType === 'nri'}
                                                onChange={handleNationalityChange} />
                                            <label htmlFor="nri" className='text-[12px] lg:!text-[18px] font-bold'>Non-Resident Indian(NRI)</label>
                                        </div>
                                    </div>
                                    <div className="my-3 flex gap-2 items-center py-3 px-2 border-b-[1px] border-gray-200">
                                        <i className="fa-regular fa-location-dot tx-pink"></i>
                                        <p className="text-[12px] lg:!text-[16px] tracking-wide font-semibold font-Montserrat">Welcome To Everland Hotel Rajkot, Gujrat, India</p>
                                    </div>
                                    <div className="w-100 mt-3 relative">
                                        <div className="col flex">
                                            <p className="w-50 text-[12px] lg:!text-[16px] text-gray-500 font-Poppins font-medium px-2">Check In</p>
                                            <p className="w-50 text-[12px] lg:!text-[16px] text-gray-500 font-Poppins font-medium px-2">Check Out</p>
                                        </div>
                                        <div className="mt-2">
                                            <div className="w-fit absolute top-50 start-[42%] translate-middle z-10 px-2  rounded-full text-[11px] lg:!text-[12px] font-semibold bs-mix-pink tx-white tx-7">
                                                {startDate && endDate ? (
                                                    <p>{numOfDays} Nights</p>
                                                ) : (
                                                    <p>0 Nights</p>
                                                )} </div>
                                            <RangePicker
                                                format="MMM DD, YYYY"
                                                onChange={(dates) => { handleDateChange(dates) }}
                                                label='Tarix'
                                                name={['date']}
                                                showTime={false}
                                                separator={null}
                                                inputReadOnly
                                                defaultValue={[dayjs(), dayjs().add(1, 'day'
                                                
                                                
                                                )]}
                                                // defaultValue={[dayjs('2024/02/11', dateFormat), dayjs('2024/02/12', dateFormat)]}
                                                placeholder={formatPlaceholder(selectedDates)}
                                                suffixIcon={null}
                                                disabledDate={disabledDate}
                                                className='w-100 border-x-0 border-t-0 !border-b-[1px] border-gray-200 !rounded-none py-2'
                                            />
                                            {/* 
                                            <RangePicker
                                                // onChange={bookingDateHandler}
                                                disabledDate={(d) =>
                                                    !d ||
                                                    d.isBefore(moment()) || 
                                                    moment('').isAfter(d) || 
                                                    moment().endOf('day').isBefore(d)
                                                }
                                                onChange={handleDateChange}
                                                placeholder={["From", "To"]}
                                                className='w-100 border-x-0 border-t-0 !border-b-[1px] border-gray-200 !rounded-none py-2'
                                            /> */}


                                        </div>
                                    </div>
                                    <div className="my-3 mb-4 flex flex-row items-start lg:!items-center py-2 px-2 border-b-[1px] border-gray-200 relative">
                                        <div className="w-100 lg:!w-[50%] pointer" onClick={handleGuestDetails}>
                                            <p className='text-[12px] lg:!text-[16px] opacity-50 font-semibold px-1'>Guests & Rooms</p>
                                            <p className='mt-3 font-Montserrat font-bold'>{childrennum > 0 ? `${childrennum} Childre | ${adultnum} Adults | ${roomnum} Room` : `${adultnum} Adults | ${roomnum} Room`} </p>
                                        </div>
                                        <div className="w-100 lg:!w-[50%]" >
                                            <p className='text-[12px] lg:!text-[16px] opacity-50 font-semibold px-1'>Traveller Preference</p>
                                            <div className="mt-3">
                                                <div className="flex flex-wrap gap-2 lg:!gap-3 px-2">
                                                    <div className="flex gap-2 items-center">
                                                        <input id="civils" name="preference" value="civils" className='accent-[#d11242] scale-[1] lg:!scale-[1.1]' type="radio"
                                                            checked={preferenceType === 'civils'}
                                                            onChange={handlePreferencesChange} />
                                                        <label htmlFor="civils" className='fs-7 px- lg:!py-1 font-semibold'>Civils</label>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <input id="corporates" name="preference" value="corporates" className='accent-[#d11242] scale-[1] lg:!scale-[1.1]' type="radio"
                                                            checked={preferenceType === 'corporates'}
                                                            onChange={handlePreferencesChange} />
                                                        <label htmlFor="corporates" className='fs-7 px- lg:!py-1 font-semibold'>Corporates</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {guestDetails &&
                                            <div className="w-100 absolute top-[70px] start-0 z-10 bs-white p-3 rounded-xl shadow-3">
                                                <div className="w-[100vw] h-[100vh] fixed top-0 start-0 -z-10 "
                                                    onClick={() => setGuestDetails(false)}></div>
                                                <div className="flex flex-wrap w-100 gap-2 justify-around md:!justify-between  items-end">
                                                    <div className="w-fit">
                                                        <p className="text-[10px] lg:!text-[13px] py-1 text-center font-semibold">Rooms(Max 8)</p>
                                                        <div className="flex items-center rounded-2xl shadow-1 w-fit mx-auto">
                                                            <div
                                                                className="px-3 py-1.5 rounded-l-2xl pointer"
                                                                onClick={roomdecNum}
                                                            >
                                                                <i className="fa-solid fa-minus tx-blue fs-6"></i>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="font-semibold py-1 tx-blue text-center min-w-[25px] !max-w-[25px]"
                                                                value={roomnum}
                                                                onChange={roomhandleChange}
                                                            />
                                                            <div
                                                                className="px-3 py-1.5 rounded-r-2xl pointer"
                                                                onClick={roomincNum}
                                                            >
                                                                <i className="fa-solid fa-plus tx-blue fs-6"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-fit">
                                                        <p className="text-[10px] lg:!text-[13px] py-1 text-center font-semibold">Adults(12+ yr)</p>
                                                        <div className="flex items-center rounded-2xl shadow-1 w-fit mx-auto">
                                                            <div
                                                                className="px-3 py-1.5 rounded-l-2xl pointer"
                                                                onClick={adultdecNum}
                                                            >
                                                                <i className="fa-solid fa-minus tx-blue fs-6"></i>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="font-semibold py-1 tx-blue text-center min-w-[25px] !max-w-[25px]"
                                                                value={adultnum}
                                                                onChange={adulthandleChange}
                                                            />
                                                            <div
                                                                className="px-3 py-1.5 rounded-r-2xl pointer"
                                                                onClick={adultincNum}
                                                            >
                                                                <i className="fa-solid fa-plus tx-blue fs-6"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-fit">
                                                        <p className="text-[10px] lg:!text-[13px] py-1 text-center font-semibold">Children(0-12 yr)</p>
                                                        <div className="flex items-center rounded-2xl shadow-1 w-fit mx-auto">
                                                            <div
                                                                className="px-3 py-1.5 rounded-l-2xl pointer"
                                                                onClick={childrendecNum}
                                                            >
                                                                <i className="fa-solid fa-minus tx-blue fs-6"></i>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="font-semibold py-1 tx-blue text-center min-w-[25px] !max-w-[25px]"
                                                                value={childrennum}
                                                                onChange={childrenhandleChange}
                                                            />
                                                            <div
                                                                className="px-3 py-1.5 rounded-r-2xl pointer"
                                                                onClick={childrenincNum}
                                                            >
                                                                <i className="fa-solid fa-plus tx-blue fs-6"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divide-x-1"></div>
                                                <div className="col bs-mix-pink px-4 py-1 rounded-md mt-4" onClick={() => setGuestDetails(false)}>
                                                    <p className='tx-white text-center fs-6 font-semibold'>Done</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div onClick={handleSearch} className="absolute top-[100%] start-[50%] translate-middle text-select-none anime-pink-btn mx-auto w-fit lg:!w-[40%] text-center duration-400 rounded-full px-5 active:!px-5 py-2.5">
                                        <button className='tx-white text-[22px] font-bold' type='submit'>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal isOpen={userLoginModal} backdrop={"blur"} placement={"center"} onOpenChange={closeRateplanTypeModal}>
                <ModalContent className='flex-col md:!flex-row bs-transparent !m-0 !border-0 !shadow-none rounded-b-none md:!rounded-b-xl md:!max-w-[650px] min-h-[390px]'>
                    {(closeRateplanTypeModal) => (
                        <>
                            <div className="my-3 p-3 mx-auto min-w-[95%] max-w-[95%] md:!min-w-[280px] md:!max-w-[280px] form-shadow-1 opacity-[0.85] ml-0 md:!ml-2 bs-mix-pink rounded-lg md:!rounded-r-none md:!rounded-l-lg ">
                                <div className="w-100 h-100 flex flex-col justify-between">
                                    <div className="w-100">
                                        <p className='tx-white fs-6 font-medium'>UNLOCK THESE BENEFITS</p>
                                        <div className="col flex items-center gap-2 mt-3">
                                            <div className="flex items-center justify-center min-w-[35px]">
                                                <i className="fa-light fa-money-bill tx-white fs-4"></i>
                                            </div>
                                            <div className="col ">
                                                <p className='text-[13px] font-bold tx-white'>Earn goCash</p>
                                                <p className='text-[11px] text-gray-300 font-semibold'>₹100 on Sign Up & Cashback on Bookings</p>
                                            </div>
                                        </div>
                                        <div className="col flex items-center gap-2 mt-2">
                                            <div className="flex items-center justify-center min-w-[35px]">
                                                <i className="fa-sharp fa-light fa-percent tx-white fs-4"></i>
                                            </div>
                                            <div className="col ">
                                                <p className='text-[13px] font-bold tx-white'>Loyalty Benefits</p>
                                                <p className='text-[11px] text-gray-300 font-semibold'>Extra Discounts, Free Seat Selection etc.</p>
                                            </div>
                                        </div>
                                        <div className="col flex items-center gap-2 mt-2">
                                            <div className="flex items-center justify-center min-w-[35px]">
                                                <i className="fa-light fa-ticket-perforated tx-white fs-4"></i>
                                            </div>
                                            <div className="col ">
                                                <p className='text-[13px] font-bold tx-white'>Exclusive Offers</p>
                                                <p className='text-[11px] text-gray-300 font-semibold'> Daily Steal Deals & Special Discounts</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden mt-3 md:!mt-0">
                                        <div className="flex items-center opacity-100 relative border-l-[6px] border-y-[2px] border-r-[2px] py-1 px-2 border-white">
                                            <div className="col">
                                                <p className='leading-[16px] tx-white font-bold text-[15px]'>FLAT 25% OFF*</p>
                                                <p className='leading-[16px] tx-white font-semibold text-[13px]'> on your first Hotel booking</p>
                                                <p className='leading-[16px] tx-white text-[11px]'>Use Coupon : GOIBEBO</p>
                                            </div>
                                            <div className="px-3 py-4 border-l-[2px] border-dashed"></div>
                                            <div className="w-[22px] h-[22px] bs-pink absolute top-50 start-100 translate-middle border-2  origin-center rotate-45 border-white rounded-full"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ModalBody className='flex bs-white form-shadow-1 rounded-l-lg min-w-[350px]'>
                                <div className="flex flex-col h-100 !m-0 justify-between">
                                    <div className="flex flex-col gap-3 mb-5">
                                        <h3 className="fs-5 font-bold my-2">Login / Signup</h3>
                                        <div className="coolinput mt-">
                                            <label className="text manded tx-blue">Enter your Mobile Number</label>
                                            <input type="tel" name="mobile" className="input border-blue" value={loginData?.mobile}
                                                onChange={handleChangeLoginData} />
                                            <span style={{ color: 'red' }}>{loginData.mobile_error}</span>
                                        </div>
                                        {otpBoxDisplayHandle &&
                                            <div className="">
                                                <MuiOtpInput value={otp} onChange={handleOtpBoxChange}
                                                    className="!p-0 custom-otp-input tx-marun"
                                                    type="tel"
                                                    length={6}
                                                    placeholder="X"
                                                />
                                            </div>
                                        }

                                        <div className="col flex justify-between">
                                            {setOtpReSend && seconds === 0 && <p className='w-fit fs-7 font-semibold tx-blue pointer' onClick={handleSubmitLogin}>Re-Send Otp </p>}
                                            {countDownStart && <p className='w-fit fs-7 font-semibold'>Valid For: {seconds}</p>}
                                        </div>

                                        <div className="border-2 border-pink hov-bs-mix-pink tx-pink hover:!text-white py-1 rounded-lg text-center pointer"
                                            onClick={handleContinueClick}
                                        >
                                            <p className='font-bold'>Continue</p>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <p className='fs-8 opacity-80 font-semibold'>By proceeding, you agree to Golbiboes
                                            <span className='tx-blue'> Privacy Policy, User Agreement </span>
                                            and
                                            <span className='tx-blue'> Terms of Service </span>
                                        </p>
                                    </div>
                                </div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
            {is_verified === true &&
                <Modal isOpen={testimonialDetailsTypeModal} backdrop={"blur"} placement={"center"} onOpenChange={closeTestimonialDetailsTypeModal}>
                    <ModalContent>
                        {(closeTestimonialDetailsTypeModal) => (
                            <>
                                <ModalBody>
                                    <div className="col">
                                        <div className="w-100 flex items-start gap-2 py-2 bs-white col relative mx-auto overflow-hidden rounded-lg">
                                            <div className="rounded-full bs-sky-blue flex items-center justify-center min-w-[60px] max-w-[60px]  min-h-[60px] max-h-[60px] w-fit">
                                                <i className="fa-solid fa-user tx-blue fs-5"></i>
                                            </div>
                                            <div className="col px-1">
                                                <p className='fs-6 font-bold font-Montserrat'>Ullas Retrekar</p>
                                                <p className='fs-7 font-semibold '>The experience was amazing actually. The room was spacious as atleast for 3 people</p>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            }
        </>
    )
}























































{/* <div className="container bg-[#eff3f8] py-5">
                <div className="w-100 max-h-[500px] bg-[#eff3f8] rounded-lg mx-auto overflow-hidden">
                    <Slider {...heroBannerSlider}>
                        <div className="col px-2 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571024099.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-2 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571026325.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-2 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571026703.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-2 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571028146.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-2 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571026790.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div> */}
