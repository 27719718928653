import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { addCouponCodeAction, deleteCouponCodeAction, editCouponCodeAction, getAllCouponCodeAction } from "../../../redux/actions/couponCode";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { GET_ALL_ROOMS } from "../../../redux/type";
import { getAllRoomsAction } from "../../../redux/actions/room";
import Pagination from "react-bootstrap/Pagination";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
export default function Promotion() {
    const [selectedDates, setSelectedDates] = useState([dayjs(), dayjs()]);

    const [promotionActiveTabState, setPromotionActiveTabState] = useState("promo-add");
    const dispatch = useDispatch();
    const { getAllCouponCode } = useSelector((state) => state?.couponCodeState);
    const [addCouponCode, setAddCouponCode] = useState({
        offer_type: "",
        amount_all_user: "",
        amount_login_user: "",
        start_date: "",
        end_date: "",
        specific_date: "",
        refundable: "",
        pay_at_hotel: "",
        room_rateplans: "",
        promotion: "",
        code_name: "",
        isUpdate: false,
        _id: "",
    });
    const { getAllRooms } = useSelector((state) => state?.roomsState);


    const handleOfferTypeChange = (value) => {
        setAddCouponCode({ ...addCouponCode, offer_type: value });
    };

    // const handleDateChange = (dates) => {
    //     if (dates && dates.length === 2) {
    //         const [start, end] = dates;
    //         setStartDate(start);
    //         setEndDate(end);
    //         const diffTime = Math.abs(end - start);
    //         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //         setNumOfDays(diffDays);
    //     }
    // };
    const formatPlaceholder = (dates) => {
    };
    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        if (addCouponCode?.offer_type === "percentage" && name === "amount_all_user") {
            const parsedValue = parseFloat(value);
            if (parsedValue > 100) {
                return;
            }
        }

        setAddCouponCode(prevState => ({ ...prevState, [name]: value }));
        // setAddCouponCode({ ...addCouponCode, [name]: value });
    };

    const handleDataChange = (property, value) => {
        setAddCouponCode({ ...addCouponCode, [property]: value });
    };

    const handleChangeDate = (date, dateType) => {
        // const formattedDate = date ? date.format('YYYY-MM-DD') : null;
        // setAddCouponCode((prevCouponCode) => ({
        //     ...prevCouponCode,
        //     [dateType]: formattedDate ,
        // }));
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;

        const isValidDate = moment(formattedDate, 'YYYY-MM-DD', true).isValid();

        if (isValidDate) {
            setAddCouponCode((prevCouponCode) => ({
                ...prevCouponCode,
                [dateType]: formattedDate,
            }));
        } else {
            console.log('Invalid date');
        }
    };

    const handleSubmitCouponCode = () => {

        if (addCouponCode?.isUpdate) {
            let body = {
                _id: addCouponCode?._id,
                isUpdate: true,
                offer_type: addCouponCode?.offer_type,
                amount_all_user: addCouponCode?.amount_all_user,
                amount_login_user: addCouponCode?.amount_login_user,
                start_date: addCouponCode?.start_date,
                end_date: addCouponCode?.end_date,
                specific_date: addCouponCode?.specific_date,
                refundable: addCouponCode?.refundable,
                pay_at_hotel: addCouponCode?.pay_at_hotel,
                room_rateplans: addCouponCode?.room_rateplans,
                promotion: addCouponCode?.promotion,
                code_name: addCouponCode?.code_name,
            }
            // console.log("body", body);
            dispatch(editCouponCodeAction(body))
        }
        let body = {
            _id: "",
            isUpdate: false,
            offer_type: addCouponCode?.offer_type,
            amount_all_user: addCouponCode?.amount_all_user,
            amount_login_user: addCouponCode?.amount_login_user,
            start_date: addCouponCode?.start_date,
            end_date: addCouponCode?.end_date,
            specific_date: addCouponCode?.specific_date,
            refundable: addCouponCode?.refundable,
            pay_at_hotel: addCouponCode?.pay_at_hotel,
            room_rateplans: addCouponCode?.room_rateplans,
            promotion: addCouponCode?.promotion,
            code_name: addCouponCode?.code_name,
        }
        console.log("bodybodybody", body);
        dispatch(addCouponCodeAction(body))
    }

    const handlEditCouponCodeData = (id) => {
        const FilterRoomData = getAllCouponCode?.find((item) => {
            return item?._id === id;
        });
        console.log("FilterRoomData", FilterRoomData);
        let body = {
            _id: FilterRoomData?._id,
            isUpdate: true,
            offer_type: FilterRoomData?.offer_type,
            amount_all_user: FilterRoomData?.amount_all_user,
            amount_login_user: FilterRoomData?.amount_login_user,
            start_date: FilterRoomData?.start_date,
            end_date: FilterRoomData?.end_date,
            specific_date: FilterRoomData?.specific_date,
            refundable: FilterRoomData?.refundable,
            pay_at_hotel: FilterRoomData?.pay_at_hotel,
            room_rateplans: FilterRoomData?.room_rateplans,
            promotion: FilterRoomData?.promotion,
            code_name: FilterRoomData?.code_name,
        };
        setAddCouponCode(body)
    };

    const handleDeleteCouponCode = async (Data) => {
        console.log("Data", Data);

        if (!Data?.id) {
            console.error("Please Enter Id for Delete the Room value");
            return;
        } else {
            confirmAlert({
                title: "Warning",
                message: "Are you sure want to delete : " + Data?.name,
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            dispatch(deleteCouponCodeAction(Data?.id));
                        }

                    },
                    {
                        label: "Cancel",
                        onClick: () => "",
                    },
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,

            });
        }
    };

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(10);
    const pageCount = Math.ceil(getAllCouponCode.length / PerPage);
    const startIndex = (currentPage - 1) * PerPage;
    const endIndex = startIndex + PerPage;
    const displayedDataPaginated = Array.isArray(getAllCouponCode)
        ? getAllCouponCode.slice(startIndex, endIndex)
        : "";
    const numbers =
        Array.isArray(getAllCouponCode) && getAllCouponCode?.length !== undefined
            ? [...Array(pageCount).keys()]?.map((num) => num + 1)
            : "";
    const startSerial = (currentPage - 1) * PerPage + 1;


    const prePage = () => {
        if (currentPage !== startIndex) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleChangePage = (id) => {
        setCurrentPage(id);
    };

    const handleItemsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };
    const nextPage = () => {
        if (currentPage !== endIndex) {
            setCurrentPage(currentPage + 1);
        }
    };

    const paginationItems = [];
    for (let i = 1; i <= pageCount; i++) {
        paginationItems.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={handleChangePage}
            >
                {i}
            </Pagination.Item>
        );
    }


    useEffect(() => {
        dispatch(getAllRoomsAction());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllCouponCodeAction())
    }, [dispatch])
    const disabledDate = (current) => {
        return current && current.isBefore(dayjs(), 'day');
    };
    return (
        <>
            <div className="w-[90%] h-100 mx-auto flex flex-col items-start gap-3 rounded-lg">
                {promotionActiveTabState === "promo-add" && (
                    <div className="w-100 mx-auto text-center relative py-4 scroll-d-none overflow-y-scroll">
                        <div
                            onClick={() => setPromotionActiveTabState("promo-list")}
                            className="w-fit flex gap-1 hover:bg-[#f9e6da] text-[#fe7d32] pointer rounded items-center my-2 px-3"
                        >
                            <i className="fa-solid fs-7 fa-angle-left"></i>
                            <p className="fs-7 font-semibold">BACK </p>
                        </div>

                        <form onSubmit={handleSubmitCouponCode}>
                            <div className="flex flex-col w-100 mx-auto bs-white w-100 rounded border-2 h-fit">
                                <div className="w-100">
                                    <div className="w-100 shadow-md">
                                        <p className="fs-2 px-3 py-2 font-bold w-fit">
                                            Basic Promotion
                                        </p>

                                    </div>
                                    <div className="flex col">
                                        <div className="min-w-[20%] max-w-[20%] border-r-1 border-y-1 text-start">
                                            <p className="py-2 px-3 fs-6 font-bold opacity-80">
                                                CONFIGURE OFFER
                                            </p>
                                        </div>
                                        <div className="col border-y-1 flex flex-col gap-3 p-3 text-start">
                                            <div className="">
                                                <p className="fs-7 p-1 font-semibold manded">
                                                    Select offer type
                                                </p>
                                                <div className="flex gap-3">
                                                    <div className="">
                                                        <label for="percentage" className={`${addCouponCode.offer_type === "percentage" ? "text-[#fe7d32] border-[#fe7d32]" : ""}   fs-7  font-semibold border-1  pointer px-3.5 py-2 rounded-md`}>
                                                            Percentage
                                                        </label>
                                                        <input
                                                            type="radio"
                                                            id="percentage" name="promotiontype"
                                                            className="hidden"
                                                            value="percentage"
                                                            // checked={addCouponCode.offer_type === "percentage"}
                                                            onChange={() => handleOfferTypeChange("percentage")}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <label for="fixed" className={`${addCouponCode.offer_type === "fixed" ? "text-[#fe7d32] border-[#fe7d32]" : ""}   fs-7  font-semibold border-1  pointer px-3.5 py-2 rounded-md`}>
                                                            Fixed
                                                        </label>
                                                        <input
                                                            type="radio"
                                                            id="fixed" name="promotiontype"
                                                            className="hidden"
                                                            value="fixed"
                                                            // checked={addCouponCode.offer_type === "fixed"}
                                                            onChange={() => handleOfferTypeChange("fixed")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="flex gap-4 items-center">
                                                    <div className="">
                                                        <p className="fs-7 p-1 font-semibold manded">
                                                            Discount for all users
                                                        </p>
                                                        <div className="relative w-fit h-fit">
                                                            <input
                                                                className="border px-3 py-1.5 rounded focus:outline-[0px]"
                                                                type="number"
                                                                min='0'
                                                                name="amount_all_user"
                                                                value={addCouponCode?.amount_all_user}
                                                                onKeyDown={event => { if (event.key === "-" || event.key === "e" || event.key === "+") { event.preventDefault(); } }}
                                                                onChange={handleChangeInput}
                                                            />
                                                            <p className="fs-6 font-semibold absolute top-50 end-[10px] translate-middle">{addCouponCode.offer_type === "fixed" ? "₹" : "%"}</p>
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <p className="fs-7 p-1 font-semibold manded">
                                                            Discount for Logged-in users
                                                        </p>
                                                        <div className="flex gap-2 items-center">
                                                            <div className="relative w-fit h-fit">
                                                                <input
                                                                    className="border px-3 py-1.5 rounded focus:outline-[0px] max-w-[100px]"
                                                                    min='0'
                                                                    type="tel"
                                                                    value={addCouponCode?.amount_all_user}
                                                                    onKeyDown={event => { if (event.key === "-" || event.key === "e" || event.key === "+") { event.preventDefault(); } }}
                                                                    disabled
                                                                // onChange={handleChangeInput}
                                                                />
                                                                <p className="fs-6 font-semibold absolute top-50 end-[10px] translate-middle">{addCouponCode.offer_type === "fixed" ? "₹" : "%"}</p>
                                                            </div>
                                                            <p className="fs-6 font-bold">+</p>
                                                            <div className="flex border rounded">
                                                                <div className="relative w-fit h-fit">
                                                                    <input
                                                                        className=" px-3 py-1.5 focus:outline-[0px] max-w-[100px]"
                                                                        min='0'
                                                                        type="number"
                                                                        name="amount_login_user"
                                                                        value={addCouponCode?.amount_login_user}
                                                                        onKeyDown={event => { if (event.key === "-" || event.key === "e" || event.key === "+") { event.preventDefault(); } }}
                                                                        onChange={handleChangeInput}
                                                                    />
                                                                    <p className="fs-6 font-semibold absolute top-50 end-[10px] translate-middle">{addCouponCode.offer_type === "fixed" ? "₹" : "%"}</p>
                                                                </div>
                                                                <div className="fs-4 font-bold px-2.5 flex items-center pointer justify-center bg-[#f4f5ff]">
                                                                    <p className="text-[#a9afe4] ">-</p>
                                                                </div>
                                                                <div className="fs-4 font-bold px-2.5 flex items-center pointer justify-center bg-[#e9ebff]">
                                                                    <p className="text-[#3c49c2] ">+</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex col">
                                        <div className="min-w-[20%] max-w-[20%] border-r-1 border-y-1 text-start">
                                            <p className="py-2 px-3 fs-6 font-bold opacity-80">
                                                PROMOTION DATES
                                            </p>
                                        </div>
                                        <div className="col border-y-1 flex flex-col gap-2 px-3 py-2 pb-3 text-start">
                                            <p className="fs-7 p-1 font-semibold manded">
                                                This promotion will be applicable for?
                                            </p>
                                            <div className="flex gap-4 items-center">
                                                <div className="">
                                                    <p className="fs-7 p-1 font-semibold manded">
                                                        <span>Stay Date</span>
                                                        <span>To</span>
                                                        <span>End Date</span>
                                                        
                                                    </p>
                                                    <div className="relative border-2 rounded bs-white py-1 w-fit h-fit">
                                                        {/* <DatePicker /> */}
                                                        {/* <DatePicker  
                                                    onChange={(date) => handleChangeDate(date, 'start_date')} /> */}
                                                        {/* <DatePicker onChange={(date) => handleChangeDate(date, 'start_date')}
                                                            value={addCouponCode.start_date ? moment(addCouponCode.start_date) : null}
                                                            format="YYYY-MM-DD"
                                                        /> */}
                                                        <RangePicker
                                                            format="MMM DD, YYYY"
                                                            // onChange={(dates) => { handleDateChange(dates) }}
                                                            label='Tarix'
                                                            name={['date']}
                                                            showTime={false}
                                                            separator={null}
                                                            inputReadOnly
                                                            defaultValue={[dayjs(), dayjs().add(1, 'day')]}
                                                            // defaultValue={[dayjs('2024/02/11', dateFormat), dayjs('2024/02/12', dateFormat)]}
                                                            placeholder={formatPlaceholder(selectedDates)}
                                                            suffixIcon={null}
                                                            disabledDate={disabledDate}
                                                            className='w-100 border-x-0 border-t-0 !border-b-[1px] border-gray-200 !rounded-none py-2'
                                                        />
                                                    </div>
                                                </div>

                                                <div className="">
                                                    {/* <p className="fs-7 p-1 font-semibold manded">
                                                        End Date
                                                    </p> */}
                                                    {/* <div className="relative border-2 rounded bs-white py-1 w-fit h-fit"> */}
                                                        {/* <DatePicker /> */}
                                                        {/* <DatePicker
                                                            onChange={(date) => handleChangeDate(date, 'end_date')}

                                                        /> */}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex col">
                                        <div className="min-w-[20%] max-w-[20%] border-r-1 border-y-1 text-start">
                                            <p className="py-2 px-3 fs-6 font-bold opacity-80">
                                                PROMOTION DATES
                                            </p>
                                        </div>
                                        <div className="col border-y-1 flex flex-col gap-2 px-3 py-2 text-start">
                                            <div className="flex flex-col gap-2 items-start">
                                                <div className="">
                                                    <p className="fs-7 p-1 font-semibold">
                                                        Do you want to make this offer non refundable?
                                                    </p>
                                                    <div className="flex gap-5 px-3">
                                                        <div className="relative flex gap-2 rounded bs-white py-1 w-fit h-fit">
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                checked={addCouponCode?.refundable === "Yes"}
                                                                onChange={() => handleDataChange('refundable', "Yes")}
                                                            />
                                                            <label>Yes</label>
                                                        </div>
                                                        <div className="relative flex gap-2 rounded bs-white py-1 w-fit h-fit">
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                checked={addCouponCode?.refundable === "No"}
                                                                onChange={() => handleDataChange("refundable", "No")}
                                                            />
                                                            <label>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <p className="fs-7 p-1 font-semibold">
                                                        Will the promotion be applicable for all rooms and rateplans?
                                                    </p>
                                                    <div className="flex gap-5 px-3">
                                                        <div className="relative flex gap-2 rounded bs-white py-1 w-fit h-fit">
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                checked={addCouponCode?.room_rateplans === "Yes"}
                                                                onChange={() => handleDataChange('room_rateplans', "Yes")}
                                                            />
                                                            <label>Yes</label>
                                                        </div>
                                                        <div className="relative flex gap-2 rounded bs-white py-1 w-fit h-fit">
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                checked={addCouponCode?.room_rateplans === "No"}
                                                                onChange={() => handleDataChange("room_rateplans", "No")}
                                                            />
                                                            <label>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {addCouponCode?.room_rateplans === "No" &&
                                                    <div className="flex gap-3 bg-[#fff]">
                                                        <div>
                                                            {getAllRooms?.length > 0 && getAllRooms?.map((roomData) => {
                                                                return (
                                                                    <>
                                                                        <div className="flex gap-3">
                                                                            <input type="checkbox"></input>
                                                                            <p>
                                                                                {roomData?.display_name}
                                                                            </p>
                                                                        </div>

                                                                        {roomData?.plans?.map((planData) => {
                                                                            return (
                                                                                <div className="flex px-3 gap-3">
                                                                                    <input type="checkbox"></input>
                                                                                    <p>
                                                                                        {planData?.plan_name}
                                                                                    </p>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex col">
                                        <div className="min-w-[20%] max-w-[20%] border-r-1 border-y-1 text-start">
                                            <p className="py-2 px-3 fs-6 font-bold opacity-80">
                                                PROMOTION NAME
                                            </p>
                                        </div>
                                        <div className="col border-y-1 flex flex-col gap-2 px-3 py-2 pb-5 text-start">
                                            <p className="fs-7 p-1 font-semibold manded">
                                                Promotion Name
                                            </p>
                                            <div className="">
                                                <div className="">
                                                    <input
                                                        className="border px-3 py-1.5 rounded focus:outline-[0px]"
                                                        type="text"
                                                        name="code_name"
                                                        value={addCouponCode?.code_name}
                                                        onChange={handleChangeInput}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bs-white p-3">
                                        <div className="bs-blue rounded ms-auto w-fit px-5 py-1">
                                            <p className="tx-white font-semibold" onClick={() => handleSubmitCouponCode()}>Submit</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
                {promotionActiveTabState === "promo-list" && (
                    <div className="w-100 mx-auto text-center relative py-4 scroll-d-none overflow-y-scroll">
                        <div
                            onClick={() => setPromotionActiveTabState("promo-add")}
                            className="w-fit flex gap-1 hover:bg-[#f9e6da] text-[#fe7d32] pointer rounded items-center my-2 px-3"
                        >
                            <i className="fa-solid fs-7 fa-angle-left"></i>
                            <p className="fs-7 font-semibold">BACK </p>
                        </div>
                        <div className="flex flex-col w-100 mx-auto bs-white w-100 rounded border-2 h-fit">
                            <div className="w-100">
                                <div className="w-100 shadow-md">
                                    <p className="fs-2 px-3 py-2 font-bold w-fit">
                                        Promotion List
                                    </p>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Code Name</th>
                                            <th>Date</th>
                                            <th>Amount All User</th>
                                            <th>Offer Type</th>
                                            <th>Amount Login User</th>
                                            <th>Action</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {getAllCouponCode?.length && getAllCouponCode?.map((couponData) => {
                                            return (console.log("couponData", couponData),
                                                <tr>
                                                    <td>{couponData?.code_name}</td>
                                                    <td>{moment(couponData?.created_at).format('YYYY-MM-DD')}</td>
                                                    <td>{couponData?.amount_all_user}</td>
                                                    <td>{couponData?.offer_type}</td>
                                                    <td>{couponData?.amount_login_user}</td>
                                                    <td>
                                                        <div className='tx-black flex items-center gap-2 pointer w-100 h-fit tx-blue px-3 py-1 rounded-md fs-6 mt-' onClick={() => {
                                                            handlEditCouponCodeData(couponData?._id);
                                                            setPromotionActiveTabState("promo-add")
                                                        }}>
                                                            <i className="fa-regular fa-pencil"></i>

                                                            <p className="font-semibold fs-6" >EDIT</p>
                                                        </div>
                                                        <div className='tx-black flex items-center gap-2 pointer w-100 h-fit tx-red px-3 py-1 rounded-md fs-6 mt-'
                                                            onClick={() => handleDeleteCouponCode({
                                                                id: couponData?._id,
                                                                name: couponData?.code_name,
                                                            })}>
                                                            <i className="fa-solid fa-trash"></i>
                                                            <p className="font-semibold fs-6" >DELETE</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                {/* <div className="flex col">
                                    <div className="col border-y-1 flex flex-col gap-3 p-3 text-start">
                                        <div className="">
                                            <p className="fs-7 p-1 font-semibold manded">
                                                Select offer type
                                            </p>
                                            <div className="flex gap-3">
                                                <div className="border-1 border-[#fe7d32] pointer px-3.5 py-2 rounded-md">
                                                    <p className="fs-7 text-[#fe7d32] font-semibold">
                                                        Percentage
                                                    </p>
                                                </div>
                                                <div className="border-1 border-[#] pointer px-3.5 py-2 rounded-md">
                                                    <p className="fs-7 text-[#] font-semibold">Fixed</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex gap-4 items-center">
                                            <div className="">
                                                <p className="fs-7 p-1 font-semibold manded">
                                                    Discount for Logged-in users
                                                </p>
                                                <div className="relative w-fit h-fit">
                                                    <input
                                                        className="border px-3 py-1.5 rounded focus:outline-[0px]"
                                                        type="tel"
                                                    />
                                                    <p className="fs-6 font-semibold absolute top-50 end-0 translate-middle">
                                                        %
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="">
                                                <p className="fs-7 p-1 font-semibold manded">
                                                    Discount for Logged-in users
                                                </p>
                                                <div className="flex gap-2 items-center">
                                                    <div className="relative w-fit h-fit">
                                                        <input
                                                            className="border px-3 py-1.5 rounded focus:outline-[0px] max-w-[100px]"
                                                            type="tel"
                                                        />
                                                        <p className="fs-6 font-semibold absolute top-50 end-0 translate-middle">
                                                            %
                                                        </p>
                                                    </div>
                                                    <p className="fs-6 font-bold">+</p>
                                                    <div className="flex border rounded">
                                                        <div className="relative w-fit h-fit">
                                                            <input
                                                                className=" px-3 py-1.5 focus:outline-[0px] max-w-[100px]"
                                                                type="tel"
                                                            />
                                                            <p className="fs-6 font-semibold absolute top-50 end-0 translate-middle">
                                                                %
                                                            </p>
                                                        </div>
                                                        <div className="fs-4 font-bold px-2.5 flex items-center pointer justify-center bg-[#f4f5ff]">
                                                            <p className="text-[#a9afe4] ">-</p>
                                                        </div>
                                                        <div className="fs-4 font-bold px-2.5 flex items-center pointer justify-center bg-[#e9ebff]">
                                                            <p className="text-[#3c49c2] ">+</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        {displayedDataPaginated.length > 0 ? (
                            <div className="justify-content-start gap-2 d-flex align-items-center mt-3 pagination">
                                <div className="page-item">
                                    <button
                                        className="py-2 px-4 border tx-black rounded-2 bg-white"
                                        onClick={prePage}
                                        disabled={currentPage === 1}
                                    >
                                        <i className="fa-solid fa-angle-left pe-2"></i>
                                        Previous
                                    </button>
                                </div>
                                {numbers.map((no, i) => {
                                    return (
                                        <div className={`h-100 bs-blue rounded-md pointer px-2 py-1 ${currentPage === no ? "active" : ""}`} key={i}>
                                            <p
                                                className="border-0 tx-white h-100"
                                                onClick={() => handleChangePage(no)}>
                                                {no}
                                            </p>
                                        </div>
                                    );
                                })}
                                <div className="">
                                    <li>
                                        <button
                                            className="py-2 px-4 border tx-black rounded-2 bg-white"
                                            onClick={nextPage}
                                            disabled={currentPage === pageCount}
                                        >
                                            Next<i className="fa-solid fa-angle-right ps-2"></i>
                                        </button>
                                    </li>
                                </div>
                                <div className="border-1 border-blue rounded-2 py-1 px-2">
                                    <select
                                        value={PerPage}
                                        onChange={handleItemsPerPageChange}
                                    >
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20 </option>
                                    </select>
                                </div>
                            </div>
                        ) : null}

                    </div>



                )}
            </div >
        </>
    );
}
