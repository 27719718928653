import { Button, Tooltip } from "@nextui-org/react";
import moment, { max } from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllPriceDateAction } from "../../../redux/actions/inventory";
import { DateTime } from "luxon";
import { getAllRoomsAction } from "../../../redux/actions/room";

export default function Inventory() {
    const dispatch = useDispatch();
    const [dates, setDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);

    const { getAllRooms } = useSelector((state) => state?.roomsState);
    const [filtredAmenitiesType, setFiltredAmenitiesType] = useState("Bathroom");
    const [roomPriceDetailBox, setRoomPriceDetailBox] = useState(
        Array(getAllRooms.length).fill(false)
    );
    const [priceDetailShowMore, setPriceDetailShowMore] = useState(
        Array(getAllRooms.length).fill(false)
    );
    const [ActiveTabState, setActiveTabState] = useState(
        localStorage.getItem("Inventory page")
    );
    const { getAllWeekData, getAllpriceDate } = useSelector(
        (state) => state?.inventoryState
    );
    const { addPriceData, setAddPriceData } = useState({
        date: "",
        price_with_room_type: [],
    });
    const [selectedDate, setSelectedDate] = useState(null);

    const handleRoomPriceDetailBox = (roomIndex) => {
        setRoomPriceDetailBox((prevStates) => {
            const newState = [...prevStates];
            newState[roomIndex] = !newState[roomIndex];
            return newState;
        });
    };

    const handlePriceDetailShowMore = (planIndex) => {
        setPriceDetailShowMore((prevShowMore) => {
            const newPlanState = [...prevShowMore];
            newPlanState[planIndex] = !newPlanState[planIndex];
            return newPlanState;
        });
    };

    const generateDateSeries = (startDate, numDates, interval) => {
        let dateSeries = [];
        let currentDate = startDate;
        for (let i = 0; i < numDates; i++) {
            moment(dateSeries).format('DD-MM-YYYY');
            dateSeries.push(currentDate.toISODate());
            currentDate = currentDate.plus(interval);
        }

        return dateSeries;
    };

    const currentWeek = [
        { id: 1, dates: "30", day: "SAT", month: "Dec" },
        { id: 2, dates: "31", day: "SUN", month: "Dec" },
        { id: 3, dates: "01", day: "MON", month: "Jan" },
        { id: 4, dates: "02", day: "TUE", month: "Jan" },
        { id: 5, dates: "03", day: "WED", month: "Jan" },
        { id: 6, dates: "04", day: "THU", month: "Jan" },
        { id: 7, dates: "05", day: "FRI", month: "Jan" },
    ];

    const handleCurrentWeek = () => {
        const formattedDates = selectedDates.map((date) =>
            moment(date, "DD-MM-YYYY").format("MM-DD-YYYY")
        );

        let body = {
            date: formattedDates,
        };
        dispatch(getAllPriceDateAction(body));
    };

    useEffect(() => {
        const today = moment().format("DD-MM-YYYY");
        const selected = moment(today, 'DD-MM-YYYY');
        const nextSevenDays = [];
        for (let i = 0; i < 7; i++) {
            const nextDate = moment(selected).add(i, 'days');
            nextSevenDays.push(nextDate.format('DD-MM-YYYY'));
        }
        setSelectedDates(nextSevenDays);
    }, []);

    const handleDateChange = (date, dateString) => {
        if (dateString) {
            const selected = moment(dateString, 'DD-MM-YYYY');
            const nextSevenDays = [];

            for (let i = 0; i < 7; i++) {
                const nextDate = moment(selected).add(i, 'days');
                nextSevenDays.push(nextDate.format('DD-MM-YYYY'));
            }

            setSelectedDates(nextSevenDays);
        } else {
            setSelectedDates([]);
        }
    };

    const handleChangeRateprice = (e, item) => {
        console.log("?????????", item, getAllpriceDate);
        // const roomData = getAllRooms?.map((item)=>{
        //     return item;
        // })
        // console.log("ItemDatatatasfa",roomData)
        // setAddPriceData({
        //     date:"26-02-2024",
        //     price_with_room_type:[{
        //         room_type_id: "65983873a53690fa080f07c2",
        //         room_name:"",
        //         // ep_rate : [
        //         //     {"base_rate":1800},
        //         //     {"single_person_rate":2200},
        //         //     {"tax":"included"},
        //         //     {"tax_rate":100},
        //         //     {"tax_type":"rup"}
        //         // ],
        //     }]
        // })
        let body = {
            date: addPriceData?.date,
            price_with_room_type: addPriceData?.price_with_room_type
        }
        console.log("BodyBodyBody::::::", body);
    }

    useEffect(() => {
        localStorage.setItem("Inventory page", ActiveTabState);
    }, [ActiveTabState]);

    useEffect(() => {
        const startDate = DateTime.now();
        const interval = { days: 1 };
        const numDates = 7;
        const generatedDates = generateDateSeries(startDate, numDates, interval);
        setDates(generatedDates);
    }, []);

    useEffect(() => {
        // dispatch(getAllPriceDateAction());
        dispatch(getAllRoomsAction());
    }, [dispatch]);

    useEffect(() => {
        handleCurrentWeek()
    }, [selectedDates])

    console.log("getAllpriceDate", getAllRooms, getAllpriceDate);

    return (
        <>
            <form className="flex flex-col w-100 my-5 px- gap-4">
                <div className="w-100 flex flex-col gap-3 px-3">
                    <div className="w-100 flex justify-between bg-[#f4f4f4] box-shadow-1 p-1 rounded-md">
                        <div className="">
                            <div className=""></div>
                        </div>
                        <div className="flex rounded-md overflow-hidden bs-white">
                            <div className="flex items-center border-[1.5px] !border-r-0 border-blue rounded-l-md px-3">
                                <i className="fa-solid fa-angle-left fs-5 tx-blue"></i>
                            </div>
                            <div className="border-[1.5px] border-blue py-[6px] hover:bg-slate-100 relative">
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    className="absolute top-50 start-50 translate-middle z-10 caret-transparent"
                                    style={{ width: "0px", background: "transparent" }}
                                    onChange={handleDateChange}
                                />

                                {/* <i className="fa-solid fa-calendar-days"></i> */}
                                <i className="fa-light fa-calendar px-3"></i>
                            </div>
                            <div className="flex items-center border-[1.5px] !border-l-0 border-blue rounded-r-md px-3">
                                <i className="fa-solid fa-angle-right fs-5 tx-blue"></i>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 flex bg-[#f4f4f4] box-shadow-1  rounded-md">
                        <div className="min-w-[20%] max-w-[20%] flex items-center gap-2 px-4">
                            <div className="flex w-fit gap-3 items-center h-fit ">
                                <i className="fa-sharp fa-regular fa-bed-front"></i>
                                <p className="opacity-80 w-fit text-start font-semibold fs-6">
                                    Rooms & Rates
                                </p>
                            </div>
                        </div>

                        {/* <div className="bs-blue min-w-[2px] max-w-[2px] rounded-full relative" /> */}
                        <div className="w-[80%] flex flex-col">
                            <div className="">
                                <div className="w-100 flex items-center">
                                    {selectedDates?.map((item, index) => (
                                        console.log("item::::::", item),
                                        <>
                                            <div
                                                key={item}
                                                className={`${item === moment(DateTime.now()).format("DD-MM-YYYY")
                                                    ? "bs-blue box-shadow-1"
                                                    : " "
                                                    }  border-l-[1.5px] border-[#cfcece] col pointer py-2 px-5`}
                                            >
                                                <p
                                                    className={`${item === moment(DateTime.now()).format("DD-MM-YYYY")
                                                        ? "tx-white"
                                                        : "tx-blue"
                                                        }  fs-7 font-Roboto font-bold opacity-80`}
                                                >
                                                    {moment(item, "DD-MM-YYYY").format("ddd")}
                                                    {/* {item */}
                                                </p>
                                                <p
                                                    className={`${item === moment(DateTime.now()).format("DD-MM-YYYY")
                                                        ? "tx-white"
                                                        : ""
                                                        } leading-5 fs-2 font-semibold`}
                                                >
                                                    {moment(item, "DD-MM-YYYY").format("DD")}
                                                </p>
                                                <p
                                                    className={`${item === moment(DateTime.now()).format("DD-MM-YYYY")
                                                        ? "tx-white"
                                                        : ""
                                                        }  fs-7 font-semibold font-Roboto opacity-70`}
                                                >
                                                    {moment(item, "DD-MM-YYYY").format("MM")}
                                                </p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* {availableroomlist.map((item, index) => ( */}
                    {getAllRooms?.length > 0 && getAllRooms?.map((roomName, roomIndex) => {
                        // {getAllpriceDate?.length > 0 &&
                        //     getAllpriceDate?.map((roomName, roomIndex) => {
                        return (
                            <div
                                className="w-100 border-[1px] box-shadow-1 flex flex-col rounded-md overflow-hidden"
                                key={roomIndex}
                            >
                                <div className="w-100 flex">
                                    <div className="min-w-[20%] w-[20%] max-w-[200px] flex items-center justify-center gap-2 ">
                                        <div className="flex w-100 gap-2 px-3 items-center h-fit w-fit overflow-hidden">
                                            {!roomPriceDetailBox[roomIndex] ? (
                                                <i
                                                    className="fa-solid fa-plus tx-blue pointer rounded-[4px] bg-[#f4f4f4] fs-7 p-1.5"
                                                    onClick={() => handleRoomPriceDetailBox(roomIndex)}
                                                ></i>
                                            ) : (
                                                <i
                                                    className="fa-solid fa-minus tx-blue pointer rounded-[4px] bg-[#f4f4f4] fs-7 p-1.5"
                                                    onClick={() => handleRoomPriceDetailBox(roomIndex)}
                                                ></i>
                                            )}
                                            <Tooltip
                                                className="fs-6"
                                                content="CORPORATE SPECIAL ROOM - KING SIZE BED"
                                            >
                                                <p className="font-semibold fs-7 text-ellipsis overflow-hidden whitespace-nowrap">
                                                    {roomName?.display_name}
                                                </p>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="w-[80%]  flex flex-col gap-3">
                                        <div className="w-100 flex items-center ">
                                            {selectedDates?.map((item, index) => (
                                                console.log("itemitemitemitem", item),
                                                <>
                                                    {getAllpriceDate?.map((getItem, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="col border-l-[1.5px] border-[#cfcece] px-3 py-2"
                                                            >
                                                                <div
                                                                    className={`${item === getItem?.date
                                                                        } col bs-white tx-green border-[1.5px] border-[#cfcece] hover:border-[#000000] focus-within:border-[#000000] pointer  rounded`}
                                                                >{getItem?.price_with_room_type?.map((roomdata, index) => {
                                                                    return (

                                                                        <input
                                                                            value={item === getItem?.date ? roomdata?.available_room_for_day : ""}
                                                                            className={` max-w-[100px] text-center fs-4 font-bold focus-visible:outline-0 focus:border-0`}
                                                                            type="number"
                                                                            onChange={(e) => handleChangeRateprice(e, item)}
                                                                        />
                                                                    )
                                                                })}
                                                                </div>
                                                                <p className="fs-7 font-semibold opacity-90">
                                                                    Sold Out
                                                                </p>
                                                            </div>

                                                        )
                                                    })}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {console.log("roomName.plan", roomName.plans)}
                                {roomName?.plans?.map((data, planIndex) => {

                                    {/* {roomName?.price_with_room_type?.map((data, planIndex) => { */ }
                                    console.log("Price Item:", data);
                                    console.log("EP Rate:", data.ep_rate);
                                    console.log("CP Rate:", data.cp_rate);
                                    console.log(
                                        "Available Rooms for the Day:",
                                        data.available_room_for_day
                                    );
                                    return (
                                        <>
                                            {roomPriceDetailBox[roomIndex] && (
                                                <div
                                                    key={planIndex}
                                                    className={`flex flex-col  ${priceDetailShowMore ? "" : "bg-[#f4f4f4] py-1"
                                                        } border-t-[1.5px] border-[#cfcece]  `}
                                                >
                                                    <div className="w-100 flex">
                                                        <div className="min-w-[20%] max-w-[20%] flex col items-center justify-end gap-2 ">
                                                            <div className="col flex items-center gap-2 text-start px-3">
                                                                {roomPriceDetailBox[roomIndex] === true ? (
                                                                    <i
                                                                        className="fa-solid fa-plus tx-blue pointer px-1"
                                                                        onClick={() =>
                                                                            handlePriceDetailShowMore(planIndex)
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <i
                                                                        className="fa-solid fa-minus tx-blue pointer px-1"
                                                                        onClick={() =>
                                                                            handlePriceDetailShowMore(planIndex)
                                                                        }
                                                                    />
                                                                )}

                                                                <p className="fs-6 font-semibold">
                                                                    {data?.plan_name}
                                                                </p>
                                                            </div>
                                                            {priceDetailShowMore && (
                                                                <div className="flex flex-col h-100 min-w-[60px] max-w-[60px] justify-center border-b-[1px] border-l-[1px] border-[#cfcece] px-1 items-center w-fit overflow-hidden">
                                                                    <div className="w-fit h-fit flex items-center gap-1">
                                                                        <p className="fs-7 text-[15px] font-bold ">
                                                                            2
                                                                        </p>
                                                                        <i className="fa-solid fa-user fs-8 opacity-70"></i>
                                                                    </div>
                                                                    <div className="w-fit h-fit">
                                                                        <p className="text-[10px] font-semibold opacity-80">
                                                                            Base Rate
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="w-[80%]  flex flex-col ">
                                                            {priceDetailShowMore && (
                                                                <div className="w-100 flex items-center ">

                                                                    {selectedDates?.map((item, index) => (
                                                                        <>
                                                                            {getAllpriceDate?.length > 0 && getAllpriceDate?.map((getItem, index) => {
                                                                                console.log("getItemgetItem",getItem?.price_with_room_type);
                                                                                return (
                                                                                    <div key={index} className="col border-l-[1.5px] border-b-[1.5px]  border-[#cfcece] px-3 py-2">
                                                                                        <div
                                                                                            key={item}
                                                                                            className={`${item === getItem?.date
                                                                                                }  col border-[1.5px] border-[#cfcece] hover:border-[#000000] focus-within:border-[#000000] pointer  rounded`}
                                                                                        >
                                                                                            <input
                                                                                                max={100}
                                                                                                className={` max-w-[100px] opacity-70 text-center fs-5 font-semibold focus-visible:outline-0 focus:border-0`}
                                                                                                type="number"
                                                                                                value={item === getItem?.date ? getItem?.cp_rate:""}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}


                                                                        </>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {priceDetailShowMore && (
                                                        <div className="w-100 flex ">
                                                            <div className="min-w-[20%] max-w-[20%] flex col items-center justify-end gap-2 ">
                                                                <div className="flex flex-col h-100 min-w-[60px] max-w-[60px] justify-center border-l-[1px] border-[#cfcece] px-1 items-center w-fit overflow-hidden">
                                                                    <div className="w-fit h-fit flex items-center gap-1">
                                                                        <p className="fs-7 text-[15px] font-bold ">
                                                                            1
                                                                        </p>
                                                                        <i className="fa-solid fa-user fs-8 opacity-70" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="w-[80%] flex flex-col ">
                                                                <div className="w-100 flex items-center ">
                                                                    {selectedDates.map((item, index) => (
                                                                        <>
                                                                            {getAllpriceDate?.length > 0 && getAllpriceDate?.map((getItem, index) => {
                                                                                return (
                                                                                    <div key={index} className="col border-l-[1.5px] border-[#cfcece] px-3 py-2">
                                                                                        <div
                                                                                            key={item}
                                                                                            className={`${item === getItem?.date
                                                                                                }  col border-[1.5px] border-[#cfcece] hover:border-[#000000] focus-within:border-[#000000] pointer  rounded`}
                                                                                        >
                                                                                            <input
                                                                                                max={100}
                                                                                                value={item}
                                                                                                className={` max-w-[100px] opacity-70 text-center fs-5 font-semibold focus-visible:outline-0 focus:border-0`}
                                                                                                type="number"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        );
                    })}
                    {/* ))} */}
                </div>
            </form>
            {/* </div>} */}
        </>
    );
}
