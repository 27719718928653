import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Modal, ModalContent, ModalHeader, backdrop, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import Select from 'react-select';
import { BedType, RoomTypes, RoomView, amenitiesNames, amenitiesTypes, dimentionsoption, ratePlansTypes } from '../../../RoomDetails';
import { airConditionoptions, amenitiesTypeOptions, bathroomoptions, heateroptions, houseKeepingoptions, mineralWateroptions, roomDiningoptions, roomserviceoptions, wifioptions } from '../../../Amenities';



export default function Settings() {
    const [filtredAmenitiesType, setFiltredAmenitiesType] = useState("Bathroom");
    const [ActiveTabState, setActiveTabState] = useState(
        localStorage.getItem('Setting page')
    );
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [addAmenitiesTypeModal, setAddAmenitiesTypeModal] = useState(false);
    const [addAmenitieModal, setAddAmenitieModal] = useState(false);
    const [amenitiestypeselector, setamenitiestypeselector] = useState("");
    const [selectAminites, setSelectAminites] = useState([]);

    const [airConditionchecked, setAirConditionChecked] = useState(false);
    const [heaterchecked, setHeaterChecked] = useState(false);
    const [houseKeepingchecked, setHouseKeepingChecked] = useState(false);
    const [roomDiningchecked, setRoomDiningChecked] = useState(false);
    const [mineralWaterchecked, setMineralWaterChecked] = useState(false);
    const [roomservicechecked, setRoomServiceChecked] = useState(false);
    const [kettlechecked, setKettleChecked] = useState(false);
    const [wifichecked, setWifiChecked] = useState(false);
    const [bathroomchecked, setBathroomChecked] = useState(false);
    const [baseAdultnum, setBaseAdultNum] = useState(1);
    const [maxAdultnum, setMaxAdultNum] = useState(1);
    const [maxChildrentnum, setMaxChildrentNum] = useState(1);
    const [maxOccupancy, setMaxOccupancy] = useState();

    const [dimensions, setDimensions] = useState("size");
    const [selectedBedType, setSelectedBedType] = useState();
    const [selectedRoomView, setSelectedRoomView] = useState();
    const [selectedRatePlanTypes, setSelectedRatePlanTypes] = useState();
    const [selectedRoomTypes, setSelectedRoomTypes] = useState();
    const [selectedDimensionsize, setSelectedDimensionsize] = useState();

    const handleAmenitiesTypeModal = () => {
        setAddAmenitiesTypeModal(true)
    }
    const closeAmenitiesTypeModal = () => {
        setAddAmenitiesTypeModal(false)
    }
    const handleAmenitieModal = () => {
        setAddAmenitieModal(true)
    }
    const closeAmenitieModal = () => {
        setAddAmenitieModal(false)
    }



    const selectedRoomViewHandler = (selectedOption) => {
        setSelectedRoomView(selectedOption.value);
    };

    const selectedRoomTypesHandler = (selectedOption) => {
        setSelectedRoomTypes(selectedOption.value);
    };

    const selectedRatePlanTypesHandler = (selectedOption) => {
        setSelectedRatePlanTypes(selectedOption.value);
    };

    const selectedBedTypeHandler = (selectedOption) => {
        setSelectedBedType(selectedOption.value)
    }

    const selectedDimensionSizeType = (selectedOption) => {
        setSelectedDimensionsize(selectedOption.value);
    };

    // const handleAirConditionCheck = (e) => {
    //     setAirConditionChecked(e.target.checked);
    //     // setAirConditionChecked(!airConditionchecked);
    // };

    const handleHeaterCheck = (e) => {
        setHeaterChecked(e.target.checked);
        // setHeaterChecked(!heaterchecked);
    };

    const handleHouseKeepingCheck = () => {
        setHouseKeepingChecked(!houseKeepingchecked);
    };

    const handleMineralWaterCheck = () => {
        setMineralWaterChecked(!mineralWaterchecked);
    };

    const handleRoomDiningCheck = () => {
        setRoomDiningChecked(!roomDiningchecked);
    };

    const handleRoomserviceCheck = () => {
        setRoomServiceChecked(!roomservicechecked);
    };

    const handleWifiCheck = () => {
        setWifiChecked(!wifichecked);
    };

    const handleBathroomCheck = () => {
        setBathroomChecked(!bathroomchecked);
    };
    // const amenities = [
    //     { id: 1, amenitiesname: 'Bathroom', amenitiesType: "Bathroom" },
    //     { id: 2, amenitiesname: "Features", amenitiesType: 'Room Features' },
    //     { id: 3, amenitiesname: "Entertainment", amenitiesType: 'Media and Entertainment' },
    //     { id: 4, amenitiesname: "hh", amenitiesType: 'Food and Drinks' },
    //     { id: 5, amenitiesname: "hh", amenitiesType: 'Kitchen and Appliances' },
    //     { id: 6, amenitiesname: "hh", amenitiesType: 'Beds and Blanket' },
    //     { id: 7, amenitiesname: "hh", amenitiesType: 'Safety and Security' },
    //     { id: 8, amenitiesname: "hh", amenitiesType: 'Childcare' },
    //     { id: 9, amenitiesname: "hh", amenitiesType: 'Other Facilities' },
    // ];


    const filteredAmenitiesData = amenitiesNames.filter(type => type.amenitiesType === filtredAmenitiesType)


    // useEffect to update the localStorage whenever the state changes
    useEffect(() => {
        localStorage.setItem('Setting page', ActiveTabState);
    }, [ActiveTabState]);
    const handleAminites = (e) => {
        const categoryNam = filtredAmenitiesType;
        const option = e.target.value;

        const categoryIndex = selectAminites.findIndex((item) => item.amenetName === categoryNam);

        if (categoryIndex === -1) {
            const newObj = {
                amenetName: categoryNam,
                options: [option],
            };

            setSelectAminites((prevSelectAminites) => [...prevSelectAminites, newObj]);
        } else {
            setSelectAminites((prevSelectAminites) => {
                const updatedAminites = [...prevSelectAminites];
                updatedAminites[categoryIndex].options.push(option);
                return updatedAminites;
            });
        }
        // const catoeryNam= filtredAmenitiesType
        // const option=e.target.value

        // const newObj={
        //     aminetName:categoryNam,
        //     option:[option]
        // }
        // const newArr=selectAminites.push(newObj)
        // setSelectAminites()

    }

    return (
        <>
            {/* <div className=" h-[100vh] w-[calc(100vw-0px)] flex flex-col items-start gap-3 rounded-lg"> */}
            {/* <div className="w-100 h-[50px] flex text-center shadow-1 rounded-bl-lg">
                    <div className="flex gap-3 px-3">
                        <div onClick={() => setActiveTabState("Amenities")} className={`${ActiveTabState === "Amenities" ? "bs-blue tx-white shadow-1" : ""}  pointer border-x-2 border-b-2 border-blue py-1 px-5 h-fit rounded-b-md`}>
                            <p className='fs-6 font-bold'>Amenities</p>
                        </div>
                    </div>
                </div> */}

            <div className="w-[90%] h-100 flex mx-auto text-center relative py-4 border-blue scroll-d-none overflow-y-scroll">
                {/* {ActiveTabState === "Amenities" && */}
                <div className="flex flex-col gap-3 h-fit w-100">
                    {/* <div className="w-fit px-3 drop-shadow-md">
                                <p className='tx-blue font-bold fs-3'>Manage Amenities</p>
                            </div> */}
                    <div className="w-100 flex border- gap-3 border-blue rounded-md">
                        <div className="w-[18%] flex flex-col gap-2 px-2">
                            <div className="bs-white" onClick={handleAmenitiesTypeModal}>
                                <p className="tx-blue border-3 border-dashed border-blue shadow-1 bs-white pointer fs-6 font-bold py-1 rounded">Add Amenities Type</p>
                            </div>
                        </div>
                        <div className="bs-blue w-[2px] rounded-full relative" />
                        <div className="col flex flex-col gap-3 px-2">
                            <div className=" bs-white" onClick={handleAmenitieModal}>
                                <p className="tx-blue border-3 border-dashed border-blue shadow-1 bs-white pointer fs-6 font-bold py-1 rounded">Add Amenitie Content</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 flex rounded-md bs-white p-1 overflow-hidden">
                        <div className="w-[20%] flex flex-col overflow-y-scroll pe-2 scroll-d-none max-h-[500px] gap- ">
                            {amenitiesTypes.map((item, index) => (
                                <div key={index} className={`${item?.amenitiesType === filtredAmenitiesType ? "tx-white bs-blue drop-shadow-md  indicate-active " : "border-b-2"}  rounded-sm pointer h-fit relative `}>
                                    <p value={item?.amenitiesType} onClick={() => setFiltredAmenitiesType(item?.amenitiesType)}
                                        className={`${item?.amenitiesType === filtredAmenitiesType ? " " : " "} 
                                                            fs-6 text-start font-semibold px-3 py-2`}>{item?.amenitiesType}</p>
                                </div>
                            ))}
                        </div>
                        <div className="col flex flex-col ms-[-8px] border-l-2 overflow-y-scroll pe-2 scroll-d-non max-h-[500px] gap-1">
                            {filteredAmenitiesData.map((item, index) => (
                                <div key={index} className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center border-b-2 border- gap-3">
                                    <input className='accent-[#2c74b3] pointer' type="checkbox" />
                                    <div className=" h-fit">
                                        <p className='fs-6 font-bold'>{item?.amenitiesname}</p>
                                    </div>
                                </div>
                            ))}
                            {filtredAmenitiesType === "Popular with Guests" &&
                                <>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleAminites} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>Air Conditioning</p>
                                            </div>
                                        </div>
                                        <div className={`${airConditionchecked === true ? "" : "hidden"}  relative z-[9] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={airConditionoptions[0]}
                                                options={airConditionoptions} onChange={selectedRoomViewHandler} value={airConditionoptions.find(option => option.value === airConditionoptions) || airConditionoptions.find(option => option === airConditionoptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleHeaterCheck} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>Heater</p>
                                            </div>
                                        </div>
                                        <div className={`${heaterchecked === true ? "" : "hidden"}  relative z-[8] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={heateroptions[0]}
                                                options={heateroptions} onChange={selectedRoomViewHandler} value={heateroptions.find(option => option.value === heateroptions) || heateroptions.find(option => option === heateroptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleHouseKeepingCheck} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>Housekeeping</p>
                                            </div>
                                        </div>
                                        <div className={`${houseKeepingchecked === true ? "" : "hidden"}  relative z-[7] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={houseKeepingoptions[0]}
                                                options={houseKeepingoptions} onChange={selectedRoomViewHandler} value={houseKeepingoptions.find(option => option.value === houseKeepingoptions) || houseKeepingoptions.find(option => option === houseKeepingoptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleRoomDiningCheck} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>In Room dining</p>
                                            </div>
                                        </div>
                                        <div className={`${roomDiningchecked === true ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={roomDiningoptions[0]}
                                                options={roomDiningoptions} onChange={selectedRoomViewHandler} value={roomDiningoptions.find(option => option.value === roomDiningoptions) || roomDiningoptions.find(option => option === roomDiningoptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'> Iron/Ironing Board </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleMineralWaterCheck} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'> Mineral Water</p>
                                            </div>
                                        </div>
                                        <div className={`${mineralWaterchecked === true ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={mineralWateroptions[0]}
                                                options={mineralWateroptions} onChange={selectedRoomViewHandler} value={mineralWateroptions.find(option => option.value === mineralWateroptions) || mineralWateroptions.find(option => option === mineralWateroptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleRoomserviceCheck} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>Room service</p>
                                            </div>
                                        </div>
                                        <div className={`${roomservicechecked === true ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={roomserviceoptions[0]}
                                                options={roomserviceoptions} onChange={selectedRoomViewHandler} value={roomserviceoptions.find(option => option.value === roomserviceoptions) || roomserviceoptions.find(option => option === roomserviceoptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'> Study Room </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>Kettle</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleWifiCheck} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>Wifi</p>
                                            </div>
                                        </div>
                                        <div className={`${wifichecked === true ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={wifioptions[0]}
                                                options={wifioptions} onChange={selectedRoomViewHandler} value={wifioptions.find(option => option.value === wifioptions) || wifioptions.find(option => option === wifioptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" onChange={handleBathroomCheck} />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'>Bathroom</p>
                                            </div>
                                        </div>
                                        <div className={`${bathroomchecked === true ? "" : "hidden"} relative z-[6] text-start drop-shadow-`}>
                                            <Select
                                                defaultValue={bathroomoptions[0]}
                                                options={bathroomoptions} onChange={selectedRoomViewHandler} value={bathroomoptions.find(option => option.value === bathroomoptions) || bathroomoptions.find(option => option === bathroomoptions)}
                                                className="min-w-[200px] w-100 border-[1px] border-grey rounded bs-transparent focus:outline-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-100 min-h-[45px] h-fit rounde px-3 py-1 items-center justify-between border-b-2 border- gap-3">
                                        <div className="flex items-center gap-2">
                                            <input className='accent-[#2c74b3] pointer' type="checkbox" />
                                            <div className="h-fit">
                                                <p className='fs-6 font-bold'> Air Purifier </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                {/* } */}
            </div>
            {/* </div> */}

            <Modal isOpen={addAmenitiesTypeModal} backdrop={"blur"} placement={"center"} onOpenChange={closeAmenitiesTypeModal}>
                <ModalContent className="close-btn-d-none border-2 border-blue shadow-">
                    {(closeAmenitiesTypeModal) => (
                        <>
                            <ModalHeader className="flex flex-col tx-blue mx-auto font-bold fs-5 gap-1">Add Amenities Type</ModalHeader>
                            <ModalBody>
                                <div className="flex items-center shadow-1 border-2 my-3 border-blue rounded overflow-hidden">
                                    <div className="col">
                                        <input className='w-100 py-1 px-2' placeholder='add amenities Type Name' type="text" />
                                    </div>
                                    <div className="w-fit bs-blue px-4">
                                        <p className='tx-white py-1'>Add</p>
                                    </div>
                                </div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={addAmenitieModal} backdrop={"blur"} placement={"center"} onOpenChange={closeAmenitieModal}>
                <ModalContent className="close-btn-d-none border-2 border-blue shadow-">
                    {(closeAmenitieModal) => (
                        <>
                            <ModalHeader className="flex flex-col tx-blue font-bold fs-5 gap-1">Add Amenitie Content</ModalHeader>
                            <ModalBody>
                                <div className="col">
                                    <div className="static  h-[40px]">
                                        <div className="w-[396px] fixed">
                                            <Select placeholder="Select Amenitie Type"
                                                options={amenitiesTypeOptions} value={amenitiesTypeOptions.find(option => option.value === amenitiesTypeOptions) || amenitiesTypeOptions.find(option => option === amenitiesTypeOptions)}
                                                className="w-100 border-blue border-2 rounded bs-transparent focus:outline-0" onChange={(roomnumber) => { setamenitiestypeselector("roomnumber", roomnumber); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center shadow-1 border-2 my-3 border-blue rounded overflow-hidden">
                                        <div className="col">
                                            <input className='w-100 py-1 px-2' placeholder='add amenitie content here' type="text" />
                                        </div>
                                        <div className="w-fit bs-blue px-4">
                                            <p className='tx-white py-1'>Add</p>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>




        </>
    )
}
