import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserAction } from '../../../redux/actions/auth';
import moment from 'moment';
import Pagination from "react-bootstrap/Pagination";

const User = () => {

    const { getAllUser } = useSelector((state) => state?.authState);
    const dispatch = useDispatch();
    const [filterData, setFilterData] = useState({
        user_name: "",
        selectedDate: null
    });
    const [filteredData, setFilteredData] = useState([]);

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(10);
    const pageCount = Math.ceil(filteredData.length / PerPage);
    const startIndex = (currentPage - 1) * PerPage;
    const endIndex = startIndex + PerPage;
    const displayedDataPaginated = Array.isArray(filteredData)
        ? filteredData.slice(startIndex, endIndex)
        : "";
    const numbers =
        Array.isArray(filteredData) && filteredData?.length !== undefined
            ? [...Array(pageCount).keys()]?.map((num) => num + 1)
            : "";
    const startSerial = (currentPage - 1) * PerPage + 1;


    const prePage = () => {
        if (currentPage !== startIndex) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleChangePage = (id) => {
        setCurrentPage(id);
    };

    const handleItemsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };
    const nextPage = () => {
        if (currentPage !== endIndex) {
            setCurrentPage(currentPage + 1);
        }
    };

    const paginationItems = [];
    for (let i = 1; i <= pageCount; i++) {
        paginationItems.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={handleChangePage}
            >
                {i}
            </Pagination.Item>
        );
    }

    const handleSearchInputChange = (event) => {
        const { name, value } = event.target;
        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            [name]: value,
        }));
    };

    useEffect(() => {
        dispatch(getAllUserAction())
    }, [dispatch]);


    useEffect(() => {
        if (filterData.user_name === ""
            //  || !filterData.selectedDate
        ) {
            setFilteredData(getAllUser);
        } else {
            const TempFilter = getAllUser.filter((item) => {
                const lowerCaseCodeOrName = filterData?.user_name.toLowerCase();
                const matchesCodeOrName =
                    item?.mobile?.toLowerCase().includes(lowerCaseCodeOrName) ||
                    item?.email?.toLowerCase().includes(lowerCaseCodeOrName) ||
                    item?.first_name?.toLowerCase().includes(lowerCaseCodeOrName) ||
                    item?.last_name?.toLowerCase().includes(lowerCaseCodeOrName) 
                //                 const selectedDate = filterData.selectedDate;
                //                 const itemDate = moment(item.created_at);
                // console.log("itemDate",moment(itemDate).format('MM-DD-YYYY'));
                // const matchesDate = selectedDate ? itemDate.isSame(moment(selectedDate), 'day'): true;

                return (
                    (matchesCodeOrName)
                    // || (matchesDate)
                );
            });
            setFilteredData(TempFilter);
        }
    }, [filterData, getAllUser]);

    return (
        <div className='flex flex-col'>

            <div className="w-100 flex justify-between mx-auto m-3">
                <div className="w-fit flex h-fit min-w-[400px] max-w-[400px] rounded-md relative">
                    <i className="absolute top-50 start-0 translate-middle-y tx-blue px-3 fa-sharp fa-solid fa-magnifying-glass"></i>
                    <input className='w-100 border-1 ps-5 border-blue rounded-md px-3 py-1' type="text"
                        name='user_name'
                        placeholder='User Search ...'
                        onChange={handleSearchInputChange}
                        value={filterData.user_name}
                    />
                </div>
            </div>

            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>FirstName</th>
                        <th>LastName</th>
                        <th>email</th>
                        <th>mobile</th>
                        <th>Verified User</th>
                        <th>Date Of Birth</th>
                        <th>Date</th>
                    </tr>

                </thead>
                {displayedDataPaginated?.length > 0 ? displayedDataPaginated?.map((item,index) => {
                    console.log("itemitem", item?.is_verified);
                    return (
                        <tbody>
                            <tr>
                            <td>{startSerial + index}</td>
                                <td>{item?.first_name || "-"}</td>
                                <td>{item?.last_name || "-"}</td>
                                <td>{item?.email || "-"}</td>
                                <td>{item?.mobile || "-"}</td>
                                {/* <td>{item?.is_verified || "-"}</td> */}
                                <td dangerouslySetInnerHTML={{ __html: item?.is_verified || "-" }}></td>
                                <td>{moment(item?.dob).format('DD-MM-YYYY') || "-"}</td>
                                <td>{moment(item?.created_at).format('DD-MM-YYYY') || "-"}</td>
                            </tr>
                        </tbody>
                    )
                }) :
                    <tr>
                        <td colSpan={8} className='txt-center'>Data is not found</td>
                    </tr>}
            </table>
            {displayedDataPaginated.length > 0 ? (
                    <div className="justify-content-start gap-2 d-flex align-items-center mt-3 pagination">
                        <div className="page-item">
                            <button
                                className="py-2 px-4 border tx-black rounded-2 bg-white"
                                onClick={prePage}
                                disabled={currentPage === 1}
                            >
                                <i className="fa-solid fa-angle-left pe-2"></i>
                                Previous
                            </button>
                        </div>
                        {numbers.map((no, i) => {
                            return (
                                <div className={`h-100 bs-blue rounded-md pointer px-2 py-1 ${currentPage === no ? "active" : ""}`} key={i}>
                                    <p
                                        className="border-0 tx-white h-100"
                                        onClick={() => handleChangePage(no)}>
                                        {no}
                                    </p>
                                </div>
                            );
                        })}
                        <div className="">
                            <li>
                                <button
                                    className="py-2 px-4 border tx-black rounded-2 bg-white"
                                    onClick={nextPage}
                                    disabled={currentPage === pageCount}
                                >
                                    Next<i className="fa-solid fa-angle-right ps-2"></i>
                                </button>
                            </li>
                        </div>
                        <div className="border-1 border-blue rounded-2 py-1 px-2">
                            <select
                                value={PerPage}
                                onChange={handleItemsPerPageChange}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20 </option>
                            </select>
                        </div>
                    </div>
                ) : null}
        </div>

    )
}

export default User
