import React from 'react'
import Slider from 'react-slick'

export default function Gallery() {

    const bannerSlider = {
        arrows: false,
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
        speed: 1000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (

        <div className="col container py-[100px] rounded-md overflow-hidden">
            <div className="flex flex-col md:!flex-row w-100 gap-2 items-center justify-center mx-auto overflow-hidden">
                <div className="w-100 md:!w-[70%] max-h-[420px]">
                    <Slider {...bannerSlider}>
                        <div className="col px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571024099.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571026325.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571026703.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571028146.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[500px] rounded-lg object-cover' src="../Hotel-imgs/1688571026790.jpg" alt="" />
                                <div className="absolute bottom-0 end-0">
                                    <div className="relative">
                                        <div className="relative z-10 text-end px-3 py-3">
                                            <p className='tx-white fs-4 leading-[10px] font-bold font-raleway'>4.5</p>
                                            <p className='tx-white fs-5 leading-[27px] font-bold font-raleway'>Star</p>
                                            <p className='tx-white fs-9 leading-[10px] font-bold font-raleway'>⭐⭐⭐⭐⭐</p>
                                        </div>
                                        <div className="absolute bottom-0 end-0 bs-mix-pink origin-top-right rotate-[315deg] min-w-[300px] max-w-[300px] max-h-[130px] min-h-[130px] "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className='w-100 md:!w-[30%] flex flex-row md:!flex-col justify- gap-2 '>
                    <Slider className='w-50 md:!w-[100%]' {...bannerSlider}>
                        <div className="col  h-fit px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[200px] rounded-lg object-cover' src="../Hotel-imgs/1688571024099.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col  h-fit px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[200px] rounded-lg object-cover' src="../Hotel-imgs/1688571024099.jpg" alt="" />
                            </div>
                        </div>
                    </Slider>
                    <Slider className='w-50 md:!w-[100%]' {...bannerSlider}>
                        <div className="col  h-fit px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[200px] rounded-lg object-cover' src="../Hotel-imgs/1688571024099.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col  h-fit px-1 rounded-lg overflow-hidden">
                            <div className="w-100 relative overflow-hidden">
                                <img className='w-100 max-h-[200px] rounded-lg object-cover' src="../Hotel-imgs/1688571024099.jpg" alt="" />
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            <div className="w-100 my-3 p-1">
                <p className='w-fit mx-auto pointer px-5 py-1 bs-white benifit-sec-border-mix-pink rounded-[16px] tx-pink font-semibold fs-6'>Show Gallary...</p>
            </div>
        </div>
    )
}
