const dimentionsoption = [
    { value: 'Sq.ft', label: 'Sq.ft' },
    { value: 'Sq.mtr', label: 'Sq.mtr' },
];
const RoomTypes = [
    { value: 'Deluxe', label: 'Deluxe' },
    { value: 'Standard', label: 'Standard' },
    { value: 'Luxury', label: 'Luxury' },
    { value: 'Master', label: 'Master' },
    { value: 'Common', label: 'Common' },
    { value: 'Tent', label: 'Tent' },
    { value: 'Family Room', label: 'Family Room' },
    { value: 'Water Villa', label: 'Water Villa' },
    { value: 'Beach Villa', label: 'Beach Villa' },
    { value: 'For Honeymooners', label: 'For Honeymooners' },
    { value: 'Garden Villa', label: 'Garden Villa' },
    { value: 'other', label: 'other' },
];
const RoomView = [
    { value: 'No View', label: 'No View' },
    { value: 'Sea View', label: 'Sea View' },
    { value: 'Valley View', label: 'Valley View' },
    { value: 'Hill View', label: 'Hill View' },
    { value: 'Pool View', label: 'Pool View' },
    { value: 'Garden View', label: 'Garden View' },
    { value: 'River View', label: 'River View' },
    { value: 'Lake View', label: 'Lake View' },
    { value: 'Palace View', label: 'Palace View' },
    { value: 'Bay View', label: 'Bay View' },
    { value: 'Jungle View', label: 'Jungle View' },
    { value: 'City View', label: 'City View' },
    { value: 'Landmark View', label: 'Landmark View' },
    { value: 'Terrace View', label: 'Terrace View' },
    { value: 'Courtyard View', label: 'Courtyard View' },
    { value: 'Desert View', label: 'Desert View' },
    { value: 'Golf Course View', label: 'Golf Course View' },
    { value: 'Mountain View', label: 'Mountain View' },
    { value: 'Ocean View', label: 'Ocean View' },
    { value: 'Backwater View', label: 'Backwater View' },
    { value: 'Resort View', label: 'Resort View' },
    { value: 'Monument View', label: 'Monument View' },
    { value: 'Park View', label: 'Park View' },
    { value: 'Lagoon View', label: 'Lagoon View' },
    { value: 'Forest View', label: 'Forest View' },
    { value: 'Beach View', label: 'Beach View' },
    { value: 'Airport View', label: 'Airport View' },
    { value: 'Countryside View', label: 'Countryside View' },
    { value: 'Harbor View', label: 'Harbor View' },
    { value: 'Inter-coastal View', label: 'Inter-coastal View' },
    { value: 'Marina View', label: 'Marina View' },
];
const BedType = [
    { value: 'Twin Bed', label: 'Twin Bed' },
    { value: 'King Bed', label: 'King Bed' },
    { value: 'Queen Bed', label: 'Queen Bed' },
    { value: 'Double Bed', label: 'Double Bed' },
    { value: 'Single Bed', label: 'Single Bed' },
    { value: 'Sofa Bed', label: 'Sofa Bed' },
    { value: 'Standard Bed', label: 'Standard Bed' },
    { value: '1 King bed or 2 Twin Bed(s)', label: '1 King bed or 2 Twin Bed(s)' },
    { value: '1 Queen bed or 2 Twin Bed(s)', label: '1 Queen bed or 2 Twin Bed(s)' },
    { value: '1 Double bed or 2 Twin Bed(s)', label: '1 Double bed or 2 Twin Bed(s)' },
    { value: 'Bunk Bed', label: 'Bunk Bed' },
    { value: 'Futon', label: 'Futon' },
    { value: 'Murphy', label: 'Murphy' },
    { value: 'Tatami Mats', label: 'Tatami Mats' },
];
const amenitiesNames = [
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Bathroom Phone' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Bathtub' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Bubble Bath' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Dental Kit' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Geyser/ Water heater' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Hot & Cold Water' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Slippers' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Toiletries' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Shower Cap' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Hammam' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Bathrobes' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Western Toilet Seat' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Shower cubicle' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Weighing Scale' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Shaving Mirror' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Sewing kit' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Bidet' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Toilet with grab rails' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Ensuite Bathroom/Common Bay' },
    { id: 1, amenitiesType: "Bathroom", amenitiesname: 'Jetspray' },

    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Closet" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Blackout curtains" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Center Table" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Charging points" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Couch" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Dining Table" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Fireplace" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Mini Bar" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Mini Fridge" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Sofa" },
    {
        id: 2, amenitiesType: 'Room Features', amenitiesname: "Telephone",
        subcategory: [
            { value: 'Centralized', label: 'Centralized' },
            { value: 'Room controlled', label: 'Room controlled' },
            { value: 'Temperature will be fixed as per Govt.Norms', label: 'Temperature will be fixed as per Govt.Norms' },
            { value: 'Window AC', label: 'Window AC' },
            { value: 'Split AC', label: 'Split AC' },
        ]
    },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Work Desk" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Pillow menu" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Hypoallergenic Bedding" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Living Area" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Dining Area" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Seating Area" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Chair" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Fireplace Guards" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Coffee Machine" },
    { id: 2, amenitiesType: 'Room Features', amenitiesname: "Jaccuzi" },

    { id: 3, amenitiesType: 'Media and Entertainment', amenitiesname: "TV" },
    { id: 3, amenitiesType: 'Media and Entertainment', amenitiesname: "Smart Controls" },
    { id: 3, amenitiesType: 'Media and Entertainment', amenitiesname: "Sound Speakers" },
    { id: 3, amenitiesType: 'Media and Entertainment', amenitiesname: "Smartphone" },

    { id: 4, amenitiesType: 'Food and Drinks', amenitiesname: "BBQ Grill" },
    { id: 4, amenitiesType: 'Food and Drinks', amenitiesname: "Cook & Butler Service" },

    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Dishwasher" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Induction" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Stove/Induction" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Cooking Basics" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Washing machine" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Dishes and Silverware" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Rice Cooker" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Kitchenette" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Refrigerator" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Toaster" },
    { id: 5, amenitiesType: 'Kitchen and Appliances', amenitiesname: "Microwave" },

    { id: 6, amenitiesType: 'Beds and Blanket', amenitiesname: "Blanket" },

    { id: 7, amenitiesType: 'Safety and Security', amenitiesname: "Cupboards with locks" },
    { id: 7, amenitiesType: 'Safety and Security', amenitiesname: "Safe" },

    { id: 8, amenitiesType: 'Childcare', amenitiesname: "Child safety socket covers" },

    { id: 9, amenitiesType: 'Other Facilities', amenitiesname: "Mosquito Net" },
    { id: 9, amenitiesType: 'Other Facilities', amenitiesname: "Newspaper" },
    { id: 9, amenitiesType: 'Other Facilities', amenitiesname: "Balcony" },
    { id: 9, amenitiesType: 'Other Facilities', amenitiesname: "Jacuzzi" },
    { id: 9, amenitiesType: 'Other Facilities', amenitiesname: "Private Pool" },
    { id: 9, amenitiesType: 'Other Facilities', amenitiesname: "Terrace" },
    { id: 9, amenitiesType: 'Other Facilities', amenitiesname: "Fan" },
];
const amenitiesTypes = [
    { id: 1, amenitiesType: "Popular with Guests" },
    { id: 1, amenitiesType: "Bathroom" },
    { id: 2, amenitiesType: 'Room Features' },
    { id: 3, amenitiesType: 'Media and Entertainment' },
    { id: 4, amenitiesType: 'Food and Drinks' },
    { id: 5, amenitiesType: 'Kitchen and Appliances' },
    { id: 6, amenitiesType: 'Beds and Blanket' },
    { id: 7, amenitiesType: 'Safety and Security' },
    { id: 8, amenitiesType: 'Childcare' },
    { id: 9, amenitiesType: 'Other Facilities' },
];
const ratePlansTypes = [
    { id: 1, value: "Accommodation only", label: "Accommodation only" },
    { id: 1, value: "FREE Breakfast", label: "FREE Breakfast" },
    { id: 2, value: 'Free Breakfast and Lunch/Dinner', label: 'Free Breakfast and Lunch/Dinner' },
    { id: 3, value: 'FREE Breakfast, Lunch and Dinner', label: 'FREE Breakfast, Lunch and Dinner' },
    { id: 4, value: 'FREE Cooked Breakfast', label: 'FREE Cooked Breakfast' },
    { id: 5, value: 'FREE Breakfast, Lunch, Dinner and Custom Inclusions', label: 'FREE Breakfast, Lunch, Dinner and Custom Inclusions' },
    { id: 6, value: 'FREE Breakfast and lunch', label: 'FREE Breakfast and lunch' },
    { id: 7, value: 'FREE Breakfast and Dinner', label: 'FREE Breakfast and Dinner' },
];

export { dimentionsoption };
export { RoomTypes };
export { RoomView };
export { BedType };
export { amenitiesNames };
export { amenitiesTypes };
export { ratePlansTypes };