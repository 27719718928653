import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CircularProgress, Card, CardBody, CardFooter, Chip } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { getAllDashBoardDataAction } from '../../../redux/actions/dashBoard';

export default function Dashboard() {

    const { getAllDashBoardData } = useSelector((state) => state?.dashBoardState)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllDashBoardDataAction());
    }, []);

    console.log(getAllDashBoardData,"getAllDashBoardData");

    return (
        <>
            <div className="mx-auto h-100 w-[90%] my-5">
                <div className="w-fit text-start">
                    <p className='text-[1.8rem] leading-[20px] font-bold '>Performance Overview</p>
                    <p className='fs-7 font-semibold opacity-70'>Last updated 6 minutes ago</p>
                </div>
                <div className="flex gap-3 mt-3">
                    <div className="w-50 flex gap-4 max-h-[200px]">
                        <div className="flex flex-col col gap-3 py-0.5">
                            <div className="relative bs-mix-light-blue w-100 rounded-md overflow-hidden">
                                <img className='w-100 h-100 scale-110 object-cover' src="../../../admin/dashboard/hero-line-shape.png" alt="" />
                                <div className="w-100 h-100 p-2 absolute top-0 start-0 z-10 flex flex-col justify-between">
                                    <div className="">
                                        <p className='text-start tx-white font-mono font-semibold'>Today's Room Nights</p>
                                    </div>
                                    <div className="">
                                        <p className='text-end tx-white font-Lobster text-[1.7rem] font-bold'>{getAllDashBoardData?.today_night || 0}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="relative bs-mix-light-blue w-100 rounded-md overflow-hidden">
                                <img className='w-100 h-100 scale-110 object-cover' src="../../../admin/dashboard/hero-line-shape.png" alt="" />
                                <div className="w-100 h-100 p-2 absolute top-0 start-0 z-10 flex flex-col justify-between">
                                    <div className="">
                                        <p className='text-start tx-white font-mono font-semibold'>Total Available Rooms</p>
                                    </div>
                                    <div className="">
                                        <p className='text-end tx-white font-Lobster text-[1.7rem] font-bold'>{getAllDashBoardData?.available_room_for_day || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col col gap-3 py-0.5">
                            <div className="relative bs-mix-light-blue w-100 col rounded-md overflow-hidden">
                                <img className='w-100 h-100 scale-110 object-cover' src="../../../admin/dashboard/hero-line-shape.png" alt="" />
                                <div className="w-100 h-100 p-2 absolute top-0 start-0 z-10 flex flex-col justify-between">
                                    <div className="">
                                        <p className='text-start tx-white font-mono font-semibold'>Today's Revenue</p>
                                    </div>
                                    <div className="">
                                        <p className='text-end tx-white font-Lobster text-[1.7rem] font-bold'>{getAllDashBoardData?.today_revenue || 0}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="relative bs-mix-light-blue w-100 col rounded-md overflow-hidden">
                                <img className='w-100 h-100 scale-110 object-cover' src="../../../admin/dashboard/hero-line-shape.png" alt="" />
                                <div className="w-100 h-100 p-2 absolute top-0 start-0 z-10 flex flex-col justify-between">
                                    <div className="">
                                        <p className='text-start tx-white font-mono font-semibold'>Today's Check-ins</p>
                                    </div>
                                    <div className="">
                                        <p className='text-end tx-white font-Lobster text-[1.7rem] font-bold'>{getAllDashBoardData?.today_checkin || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-50 flex gap-4 max-h-[200px]">
                        <Card className="w-[220px] border-none bg-gradient-to-br from-[#5855e7] to-[#7b79f0]">
                            <CardBody className="justify-start items-center pb-0">
                                <CircularProgress
                                    classNames={{
                                        track: "stroke-white/10",
                                        indicator: "stroke-white",
                                        svg: "w-32 h-32 drop-shadow-md",
                                        value: "text-3xl font-semibold text-white",
                                    }}
                                    value={getAllDashBoardData?.today_average_price || 0}
                                    strokeWidth={4}
                                    showValueLabel={true}
                                />
                            </CardBody>
                            <CardFooter className="justify-center items-center pt-0">
                                <Chip
                                    classNames={{
                                        base: "border-1 border-white/30",
                                        content: "text-white/90 text-small font-semibold",
                                    }}
                                    variant="bordered"
                                >
                                    Today's Average Selling Price
                                </Chip>
                            </CardFooter>
                        </Card>
                        <div className="flex flex-col col gap-3 py-0.5">
                            <div className="relative bs-mix-light-blue w-100 col rounded-md overflow-hidden">
                                <img className='w-100 h-100 scale-110 object-cover' src="../../../admin/dashboard/hero-line-shape.png" alt="" />
                                <div className="w-100 h-100 p-2 absolute top-0 start-0 z-10 flex flex-col justify-between">
                                    <div className="">
                                        <p className='text-start tx-white font-mono font-semibold'>This Month Revenue</p>
                                    </div>
                                    <div className="">
                                        <p className='text-end tx-white font-Lobster text-[1.7rem] font-bold'>{getAllDashBoardData?.month_revenuw || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
