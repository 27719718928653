import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <div className="w-100 h-fit sans-regular font-thin text-white relative overflow-hidden bs-mix-pink rounded-t-3xl">
                {/* <footer className="h-fit sans-regular font-thin text-white relative overflow-hidden"> */}
                {/* <div className="absolute -z-10 w-100 h-100 top-0">
                    <img className='w-100 blur-[7px]' src="https://img.freepik.com/free-vector/elegant-shubh-deepavali-festival-banner-with-text-space-vector_1017-40299.jpg?w=1380&t=st=1693807725~exp=1693808325~hmac=d512d4e36223d47693b133f80e8b4af65715aae11ffc7dd0dade61cde00f53cb" alt="" />
                </div> */}
                <div className="w-100 mx-2 md:mx-5">
                    <div className="w-100 flex flex-col md:!flex-row gap-3 py-4 px-[5%] flex-wrap justify-content-between ">
                        <div className="col md:!w-fit">
                            <h4 className="tx-white font-semibold mt-1 mb-4 ps-2 fs-3">Hotel Everland</h4>
                            <ul className="ps-0 fs-13">
                                <Link to="https://wa.me/+9107035703554" className='text-white'>
                                    <li className="py-2 flex">
                                        <div className="">
                                            <i className="fas fa-phone me-3"></i>
                                        </div>
                                        <div>
                                            <p className='font-medium'>+91 070357 03554</p>
                                        </div>
                                    </li>
                                </Link>
                                <Link to="mailto:hoteleverland@yahoo.com" className='text-white'>
                                    <li className="py-2 flex mb-2">
                                        <div className="">
                                            <i className="fas fa-envelope me-3"></i>
                                        </div>
                                        <div>
                                            <p className='font-medium'>hoteleverland@yahoo.com</p>
                                        </div>
                                    </li>
                                </Link>
                                <Link to="https://www.google.com/maps/" target='_blank' className='text-white'>
                                    <li className="py-2 flex mb-2">
                                        <div className="">
                                            <i className="fas fa-home me-3"></i>
                                        </div>
                                        <div>
                                            <p className='font-medium'>hotel everland, Moti Tanki Chowk, beside eagle travels, Parikshit Nagar, Sadar, Rajkot, Gujarat 360001</p>
                                        </div>
                                    </li>
                                </Link>
                            </ul>
                        </div>
                        <div className="h-fit min-w-[200px]">
                            <div className="my-2 md:!mb-4">
                                <p className='tx-white font-medium fs-5'>More Links</p>
                            </div>
                            <div className="">
                                <Link to="/Aboutus">
                                    <p className='tx-white font-normal'>About Us</p>
                                </Link>
                                <Link to="/Privacy-Policy">
                                    <p className='tx-white font-normal'>Privacy Policy</p>
                                </Link>
                                <Link to="/Terms-And-Conditions">
                                    <p className='tx-white font-normal'>Terms And Conditions</p>
                                </Link>
                                <Link to="/Cancellation-And-Refund">
                                    <p className=' tx-white font-normal'>Cancellation and Refund</p>
                                </Link>
                            </div>
                        </div>
                        <div className="col"></div>
                        <div className="h-fit">
                            <div className=" mb-4">
                                <p className='tx-white font-semibold fs-5'>Get your special offers, coupons & more...</p>
                            </div>
                            <form noValidate="novalidate" className="mx-auto" >
                                <input required="required" id="input-36" placeholder="Your email address" type="email" className="px-3 rounded w-100 p-2 text-black" />

                                <div className="w-100 text-start">
                                    <button type="submit" className="mx-auto my-3 px-4 py-1 border border-white rounded tx-white">
                                        <span className="fs-6 font-normal">Subscribe</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className="row md:mb-5 md:pb-5"> */}
                    <div className="row w-100">
                        <div className="w-100 p-0 border-t-[1px] mx-auto">
                            <div className="text-center py-2 w-100">
                                <p className='text-gray-100 fs-8 font-semibold'>© Design and develop with 🤍 by Grafizen LLC for Hotel Everland Rajkot</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
