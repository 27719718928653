import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar({ sideBarMenu, fullSideBar, halfSideBar }) {
    return (
        <>
            <div className={`${sideBarMenu ? "min-w-[220px] max-w-[220px]" : "min-w-fit max-w-fit"}   h-[calc(100vh-0px)] shadow-md mx-auto text-center rounded-br-lg scroll-d-none overflow-y-scroll`}>
                <div className="flex flex-col gap-2 py-2">
                    <Link to="/admin/dashboard">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                                <i className="fa-duotone fa-house fs-4 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold'>Dashboard</p>}
                        </div>
                    </Link>
                    <Link to="/admin/add-rooms">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                                <i className="fa-duotone fa-grid-2-plus fs-4 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold m-0'>Add Room</p>}
                        </div>
                    </Link>
                    <Link to="/admin/inventory">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                                <i className="fa-duotone fa-hotel fs-4 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold m-0'>Inventory</p>}
                        </div>
                    </Link>
                    <Link to="/admin/bookings">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center"> 
                                <i className="fa-duotone fa-clipboard-list-check fs-4 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold m-0'>Bookings</p>}
                        </div>
                    </Link>
                    <Link to="/admin/payments">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                                <i className="fa-solid fa-shield-halved fs-4 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold m-0'>Payment</p>}
                        </div>
                    </Link>

                    <Link to="/admin/promotion">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                                <i className="fa-solid fa-ticket-perforated fs-5 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold m-0'>Promotion</p>}
                        </div>
                    </Link>

                    <Link to="/admin/user">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                                <i className="fa-solid fa-user fs-5 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold m-0'>User</p>}
                        </div>
                    </Link>

                    <Link to="/admin/settings">
                        <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                            <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                                <i className="fa-duotone fa-gear fs-4 drop-shadow-md tx-blue"></i>
                            </div>
                            {sideBarMenu && <p className='fs-6 font-semibold m-0'>Setting</p>}
                        </div>
                    </Link>
                </div>
            </div>
            <div className={`${sideBarMenu ? "min-w-[220px] max-w-[220px]" : "min-w-fit max-w-fit"}  shadow-md min-h-[30px] max-h-[30px] `}>
                <Link to="/" className="w-100 h-100 mx-auto flex align-items-center justify-center overflow-hidden">
                    <div className={`${sideBarMenu ? "" : "hover:scale-125 duration-300"} flex items-center px-2.5 mx-auto gap-2`}>
                        <div className="p-1 min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center">
                            <i className="fa-solid fa-right-from-bracket mx-auto fs-4 drop-shadow-md tx-blue"></i>
                        </div>
                        {sideBarMenu && <p className='fs-6 font-semibold m-0'>Logout</p>}
                    </div>
                </Link>
            </div>
        </>
    )
}
