import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function RoomDetails() {

    const heroslider = {
        arrows: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        centerPadding: "100px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <div className="w-100 mt-[80px] container flex flex-col items-start gap-3 rounded-lg">
                <div className="w-100 flex mx-auto text-center rounded-tl-lg scroll-d-none overflow-y-scroll">
                    <div className="flex flex-col gap-3 h-100 w-[100%]">
                        <div className="rounded-lg py-3">
                            <div className="col flex gap-3 rounded-md overflow-hidden">
                                <div className="flex w-100 gap-2 justify-center mx-auto overflow-hidden">
                                    {/* <Slider className='w-[70%] mx-auto h-[400px] overflow-hidden' {...heroslider}> */}
                                    <div className='w-100 overflow-hidden'>
                                        <img className='w-100 h-[400px] rounded-lg object-cover object-center'
                                            src="../../../Hotel-imgs/1688571025601.jpg" />
                                    </div>
                                    {/* <div className='w-100 overflow-hidden'>
                                                <img className='w-100 h-[400px] rounded-lg object-cover object-center'
                                                    src="../../../Hotel-imgs/1688571025480.jpg" />
                                            </div> */}
                                    {/* </Slider> */}
                                    <div className='w-fit flex flex-col gap-2 overflow-hidden'>
                                        {/* <Slider className='w-100 col overflow-hidden' {...heroslider}> */}
                                        <div className='w-100 overflow-hidden'>
                                            <img className='w-100 h-[195px] rounded-lg col object-cover'
                                                src="../../../Hotel-imgs/1688571025906.jpg"
                                                alt=""
                                            />
                                        </div>
                                        {/* </Slider>
                                            <Slider className='w-100 col mt-1 overflow-hidden' {...heroslider}> */}
                                        <div className='w-100 overflow-hidden'>
                                            <img className='w-100 h-[195px] rounded-lg col object-cover'
                                                src="../../../Hotel-imgs/1688571024150.jpg"
                                                alt=""
                                            />
                                        </div>
                                        {/* </Slider> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 mb-5 flex flex-col">
                            <div className="flex border-[2px] overflow-hidden rounded border-pink">
                                <div className='min-w-[30%] max-w-[30%] p-3 overflow-hidden'>
                                    <img className='w-100 h-[200px] rounded-lg object-cover'
                                        src="../../../Hotel-imgs/1688571025601.jpg" />
                                    <div className="py-1">
                                        <p className='fs-5 px-1 text-start font-Montserrat font-bold'>Premium Twin Bed Room</p>
                                        <div className="flex items-center gap-2 py-3 px-1">
                                            <i className="fa-light fa-objects-column text-[17px] tx-pink" />
                                            <p className='font-semibold'>120 sq.ft</p>
                                        </div>
                                        <ul className="flex flex-wrap gap-2 px-1 list-disc">
                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Air Conditioning</li>
                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Housekeeping</li>
                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Bathroom</li>
                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Wi-Fi</li>
                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>In-room Dining</li>
                                            <li className='px-1 ms-3 opacity-75 fs-7 font-semibold'>Room Service</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="bs-pink rounded-full my-3 min-w-[2.5px]"></div>
                                <div className="">
                                    {/* Rate plans */}
                                    <div className="flex col h-fit">
                                        <div className="min-w-[50%] max-w-[50%] h-fit p-3 text-start">
                                            <div className="fs-8 border-pink border-2 w-fit px-2 font-semibold rounded">RECOMMENDED</div>
                                            <p className='fs-5 font-bold font-Montserrat px-1 p'>Room With Free Cancellation</p>
                                            <div className="flex gap-1 items-center mt-3">
                                                <i className="fa-sharp fa-solid fa-pot-food min-w-[30px] max-w-[30px] text-center"></i>
                                                <p className='fs-6 font-semibold '>No meals included </p>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <i className="fa-sharp fa-solid fa-badge-check tx-green min-w-[30px] max-w-[30px] text-center"></i>
                                                <p className='fs-6 font-semibold tx-green'>Free Cancellation till check-in</p>
                                            </div>
                                            <p className='fs-6 mt-3 font-bold tx-blue pointer px-3'> MORE DETAILS</p>
                                        </div>
                                        <div className="bs-pink rounded-full min-w-[2.5px]"></div>
                                        <div className="min-w-[50%] max-w-[50%] h-fit text-start">
                                            <div className="relative">
                                                <div className="bs-mix-pink ml-[-2px] relative z-10 py-2 px-3">
                                                    <p className='tx-white fs-7 font-semibold relative'>Deal Applied: MMTBIGSAVER. Use MMTBIGSAVER to get a Discount of INR 6026 off.</p>
                                                </div>
                                                <div className="absolute top-100 start-[20%] origin-center min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rotate-[45deg] mt-[-10px] bs-dpink"></div>
                                            </div>

                                            <div className="col h-fit my-2 font-Montserrat">
                                                <div className="flex ms-auto items-center relative h-[24px] pl-[24px] w-fit overflow-hidden">
                                                    <div className="flex h-[30px] items-center relative z-[1] gap-2 bs-mix-pink">
                                                        <div className="px-2">
                                                            <p className='tx-white fs-7 font-bold'>Per Night</p>
                                                        </div>
                                                    </div>
                                                    <div className="absolute top-0 left-0 origin-center bs-dpink w-[55px] h-[30px] rotate-[490deg] "></div>
                                                </div>
                                                <div className="flex items-end">
                                                    <div className="col ps-3">
                                                        <del className='fs-6 font-semibold'>₹ 7,200</del>
                                                        <p className='fs-5 font-bold'>₹ 3,720</p>
                                                        <p className='fs-6 font-semibold'>+ ₹ 1,034 taxes & fees</p>
                                                    </div>
                                                    <div className="flex mb-2 ms-auto items-center relative h-[27px] pl-[24px] w-fit overflow-hidden">
                                                        <div className="flex h-[30px] items-center relative z-[1] gap-2 bs-mix-pink">
                                                            <Link to="" className="px-2">
                                                                <p className='tx-white fs-7 font-bold'>SELECT ROOM</p>
                                                            </Link>
                                                        </div>
                                                        <div className="absolute top-0 left-0 origin-center bs-dpink w-[62px] h-[30px] rotate-[490deg]"></div>
                                                    </div>
                                                </div>
                                                <p className='fs-8 col my-2 tracking-wider text-gray-500 text-center opacity-70 font-semibold'>To Get this @ INR3,671 or less <span className='  tx-blue'>LOGIN NOW</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Rate plans */}
                                    <div className="bs-pink mx-auto rounded-lg col min-h-[2.5px]"></div>
                                    {/* Rate plans */}
                                    <div className="flex col h-fit">
                                        <div className="min-w-[50%] max-w-[50%] h-fit p-3 text-start">
                                            <div className="fs-8 border-pink border-2 w-fit px-2 font-semibold rounded">RECOMMENDED</div>
                                            <p className='fs-5 font-bold font-Montserrat px-1 p'>Room With Free Cancellation</p>
                                            <div className="flex gap-1 items-center mt-3">
                                                <i className="fa-sharp fa-solid fa-pot-food min-w-[30px] max-w-[30px] text-center"></i>
                                                <p className='fs-6 font-semibold '>No meals included </p>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <i className="fa-sharp fa-solid fa-badge-check tx-green min-w-[30px] max-w-[30px] text-center"></i>
                                                <p className='fs-6 font-semibold tx-green'>Free Cancellation till check-in</p>
                                            </div>
                                            <p className='fs-6 mt-3 font-bold tx-blue pointer px-3'> MORE DETAILS</p>
                                        </div>
                                        <div className="bs-pink rounded-full min-w-[2.5px]"></div>
                                        <div className="min-w-[50%] max-w-[50%] h-fit text-start">
                                            <div className="relative">
                                                <div className="bs-mix-pink ml-[-2px] relative z-10 py-2 px-3">
                                                    <p className='tx-white fs-7 font-semibold relative'>Deal Applied: MMTBIGSAVER. Use MMTBIGSAVER to get a Discount of INR 6026 off.</p>
                                                </div>
                                                <div className="absolute top-100 start-[20%] origin-center min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rotate-[45deg] mt-[-10px] bs-dpink"></div>
                                            </div>

                                            <div className="col h-fit my-2 font-Montserrat">
                                                <div className="flex ms-auto items-center relative h-[24px] pl-[24px] w-fit overflow-hidden">
                                                    <div className="flex h-[30px] items-center relative z-[1] gap-2 bs-mix-pink">
                                                        <div className="px-2">
                                                            <p className='tx-white fs-7 font-bold'>Per Night</p>
                                                        </div>
                                                    </div>
                                                    <div className="absolute top-0 left-0 origin-center bs-dpink w-[55px] h-[30px] rotate-[490deg] "></div>
                                                </div>
                                                <div className="flex items-end">
                                                    <div className="col ps-3">
                                                        <del className='fs-6 font-semibold'>₹ 7,200</del>
                                                        <p className='fs-5 font-bold'>₹ 3,720</p>
                                                        <p className='fs-6 font-semibold'>+ ₹ 1,034 taxes & fees</p>
                                                    </div>
                                                    <div className="flex mb-2 ms-auto items-center relative h-[27px] pl-[24px] w-fit overflow-hidden">
                                                        <div className="flex h-[30px] items-center relative z-[1] gap-2 bs-mix-pink">
                                                            <Link to="" className="px-2">
                                                                <p className='tx-white fs-7 font-bold'>SELECT ROOM</p>
                                                            </Link>
                                                        </div>
                                                        <div className="absolute top-0 left-0 origin-center bs-dpink w-[62px] h-[30px] rotate-[490deg]"></div>
                                                    </div>
                                                </div>
                                                <p className='fs-8 col my-2 tracking-wider text-gray-500 text-center opacity-70 font-semibold'>To Get this @ INR3,671 or less <span className='  tx-blue'>LOGIN NOW</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Rate plans */}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 flex gap-4 p-3">
                            <div className="col rounded-lg ">
                                <div className="">
                                    <div className="text-center">
                                        <p className='fs-5 font-bold tx-red'>Payable Amount</p>
                                    </div>
                                    <div className="flex justify-between gap-3 mt-3">
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>Price Starts At</p>
                                        </div>
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>6998</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between gap-3">
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>Taxes & Fees</p>
                                        </div>
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>+839</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between gap-3">
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>Room Per Night</p>
                                        </div>
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>1</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between gap-3">
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>Guests</p>
                                        </div>
                                        <div className="">
                                            <p className='fs-6 font-bold text-start'>x 2</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-blue min-w-[2px]"></div>
                            <div className="col ">
                                <div className="text-center">
                                    <p className='fs-5 font-bold tx-red'>Food & Dining</p>
                                </div>
                                <div className="flex gap-3 mt-3">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-sharp fa-solid fa-leaf tx-green"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Non-Vegetarian Not Aloud</p>
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-1">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-sharp fa-solid fa-indian-rupee-sign tx-red"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Average meal cost for 1: ₹ 200</p>
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-1">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-solid fa-plate-utensils tx-red"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Cuisines: North Indian, South Indian, Chinese</p>
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-1">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-solid fa-salad tx-red"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Breakfast 7:30 AM - 10:15 AM | Lunch 12 PM - 3 PM | Dinner 8 PM - 10 PM</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-blue min-w-[2px]"></div>
                            <div className="col ">
                                <div className="text-">
                                    <p className='fs-5 font-bold tx-red'>Property Policies</p>
                                </div>
                                <div className="flex gap-3 mt-3">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-solid fa-badge-check tx-green"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Unmarried couples allowed</p>
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-1">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-solid fa-badge-check tx-green"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Local ids are allowed</p>
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-1">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-solid fa-badge-check tx-green"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Meal for One Guest is available</p>
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-1">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-solid fa-circle-exclamation tx-yellow"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Passport, Aadhar, Driving License and Govt. ID are accepted as ID proof(s)</p>
                                    </div>
                                </div>
                                <div className="flex gap-3 mt-1">
                                    <div className="min-w-[30px] max-w-[30px] py-1 flex justify-center">
                                        <i className="fa-solid fa-circle-xmark tx-red"></i>
                                    </div>
                                    <div className="">
                                        <p className='fs-6 font-bold text-start'>Guests below 18 years of age are not allowed at the property.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bs-blue mx-auto rounded-lg max-w-[80%] min-h-[2px]"></div>
                        <div className="w-100 my-4">
                            <div className="flex justify-center gap-4">
                                <div className="w-[30%]">
                                    <div className="text-center">
                                        <p className='fs-5 font-bold tx-red'>Guest Information</p>
                                    </div>
                                    <form>
                                        <div className="col mt-3">
                                            <input
                                                className={`form-control`}
                                                placeholder="Enter Your Full Name"
                                                type="text"
                                                name="email"
                                                id="email"
                                            />
                                        </div>
                                        <div className="col flex gap-3 mt-3">
                                            <div className="col">
                                                <input
                                                    className={`form-control`}
                                                    placeholder="Enter Your E-mail"
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                />
                                            </div>
                                            <div className="col">
                                                <input
                                                    className={`form-control`}
                                                    placeholder="Enter Your Number"
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-3 col">
                                            <textarea
                                                type="text"
                                                name="password"
                                                id="password"
                                                className={`form-control  ? "border-red" : "" }`}
                                                placeholder="Any Requests"
                                            />
                                        </div>
                                        <div className="text-center my-4">
                                            <button type="submit" className="border-2 border-blue rounded-md tx-blue font-bold px-4 py-1 fs-6">
                                                Book Now
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="bs-blue min-w-[2px]"></div>
                                <div className="w-[30%] px-2">
                                    <div className="flex w-100 border-b-2 py-2 border-red">
                                        <div className="w-50 px-2 border-r-2  border- border-red">
                                            <p className='fs-6 tx-blue font-bold'>Check In</p>
                                            <p className='fs-7 font-bold'>25 Dec '23, Mon</p>
                                        </div>
                                        <div className="w-50 px-3">
                                            <p className='fs-6 tx-blue font-bold'>Check In</p>
                                            <p className='fs-7 font-bold'>26 Dec '23, Tue</p>
                                        </div>
                                    </div>

                                    <div className="p-2 border-b-2 border-red">
                                        <div className="flex justify-between">
                                            <div className="">
                                                <p className='fs-6 font-semibold'>No of nights </p>
                                            </div>
                                            <div className="">
                                                <p className='fs-6 font-semibold'>1 Night</p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="">
                                                <p className='fs-6 font-semibold'>Room </p>
                                            </div>
                                            <div className="">
                                                <p className='fs-6 font-semibold'>Kothi</p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="">
                                                <p className='fs-6 font-semibold'>Sub Total </p>
                                            </div>
                                            <div className="">
                                                <p className='fs-6 font-semibold'>₹ 12,000</p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="">
                                                <p className='fs-6 font-semibold'>Taxes and Fees </p>
                                            </div>
                                            <div className="">
                                                <p className='fs-6 font-semibold'>₹ 1500</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-2">
                                        <div className="flex justify-between">
                                            <div className="">
                                                <p className='fs-6 font-semibold'>Grand Total </p>
                                            </div>
                                            <div className="">
                                                <p className='fs-6 font-semibold'>₹ 13,500</p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="">
                                                <p className='fs-6 font-semibold'>Pay Now </p>
                                            </div>
                                            <div className="">
                                                <p className='fs-6 font-semibold'>₹ 13,500</p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="">
                                                <p className='fs-6 font-semibold'>Balance (Pay Later) </p>
                                            </div>
                                            <div className="">
                                                <p className='fs-6 font-semibold'> ₹ 0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
