import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import Loader1 from './components/preloaders/Loader';
import WebsiteLanding from './website/pages/home/WebsiteLanding';
import AdminLogin from './components/modals/AdminLogin';
import Sidebar from './extranet/components/sidebar/Sidebar';
import Booking from './extranet/pages/booking/Booking';
import Dashboard from './extranet/pages/dashboard/Dashboard';
import Inventory from './extranet/pages/inventory/Inventory';
import AddRoom from './extranet/pages/rooms/AddRoom';
import RoomDetails from './extranet/pages/rooms/RoomDetails';
import Rooms from './extranet/pages/rooms/Rooms';
import Settings from './extranet/pages/setting/Settings';
import SiteHeader from './website/components/header/SiteHeader';
import AboutUs from './website/pages/about/AboutUs';
import Gallery from './website/pages/gallery/Gallery';
import BookingReview from './website/pages/rooms/BookingReview';
import RoomList from './website/pages/rooms/RoomList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Voucher from './extranet/pages/voucher/Voucher';
import Header from './extranet/components/header/AdminHeader';
import PaymentList from './extranet/pages/payment/PaymentList';
import Promotion from './extranet/pages/promotion/Promotion';
import User from './extranet/pages/user/User';


function App() {

  const [loading, setLoading] = useState(true);
  const [loginmodalstate, setLoginmodalstate] = useState(false);
  const [registermodalstate, setRegistermodalstate] = useState(false);
  const [routHandler, setRoutHandler] = useState(localStorage.getItem('Routes of pages'));  // Admin & Website Route Manage
  console.log("🚀 ~ App ~ routHandler:", routHandler)
  const [userLoginModal, setUserLoginModal] = useState(false);  // ModalsStates
  const [sideBarMenu, setSideBarMenu] = useState(false);  // SideBar Menu States
  const pathname = useLocation();
  const [Me, setMe] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      setLoading(false);
    };
    fetchData();
  }, [pathname]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setRoutHandler("website")
    }
    if (window.location.pathname === "/admin" || window.location.pathname === "/admin/") {
      setRoutHandler("admin")
    }

  }, [routHandler])

  useEffect(() => {
    localStorage.setItem('Routes of pages', routHandler);
  }, [routHandler]);

  // useEffect(() => {
  //   const users = Array?.isArray(getAllUser) && getAllUser?.filter((user) => {
  //     return (
  //       user?.is_active === true && user?.roleId?.name.toLowerCase() !== "admin"
  //     );
  //   });
  //   setUsers(users);
  //   setMe(getMyProfile?.data);
  // }, [getAllUser, getMyProfile]);

  const toggleSideBar = () => {
    setSideBarMenu(show => !show);
  }
  const fullSideBar = () => {
    setSideBarMenu(true);
  }
  const halfSideBar = () => {
    setSideBarMenu(false);
  }


  const voucherPageActive = window.location.pathname === "/admin/voucher"
  const adminPageActive = window.location.pathname === "/admin/"

  return (
    <>
      <ToastContainer />
      {routHandler === "admin" &&
        <>
          <div className="h-[100vh] w-[calc(100vw-0px)] ease-soft-spring duration-1000 flex flex-col items-start rounded-lg">
            {adminPageActive ? "" : voucherPageActive ? "" :
              <div className="w-100 h-100 ease-soft-spring duration-1000 flex items-center text-center shadow-1 relative z-10">
                <Header toggleSideBar={toggleSideBar} />
              </div>
            }
            <div className={`${adminPageActive ? "" : voucherPageActive ? " ease-soft-spring duration-1000" :  "h-[calc(100vh-45px)] ease-soft-spring duration-1000"}  w-100  bg-[#f8f8f8] ease-in-out duration-700 flex gap-`}>
              {adminPageActive ? "" : voucherPageActive ? "" :
                <div className="h-[100%] flex flex-col gap-1 bs-white rounded-lg  ease-soft-spring duration-1000">
                  <Sidebar sideBarMenu={sideBarMenu} fullSideBar={fullSideBar} halfSideBar={halfSideBar} />
                </div>
              }
              <div className={`${adminPageActive ? " w-[calc(100vw-0px)]" : " w-[calc(100vw-200px)]"}   h-[100%] bg-[#f8f8f8] ease-soft-spring duration-1000 flex mx-auto text-center scroll-d-none overflow-y-scroll`}>
                <Routes>
                  <Route path="/admin" element={<AdminLogin setSideBarMenu={setSideBarMenu} />} />
                  <Route path="/admin/dashboard" element={<Dashboard />} />
                  <Route path="/admin/rooms-list" element={<Rooms />} />
                  <Route path="/admin/add-rooms" element={<AddRoom />} />
                  <Route path="/admin/inventory" element={<Inventory />} />
                  <Route path="/admin/bookings" element={<Booking />} />
                  <Route path="/admin/payments" element={<PaymentList />} />
                  <Route path="/admin/settings" element={<Settings />} />
                  <Route path="/admin/room-details" element={<RoomDetails />} />
                  <Route path="/admin/promotion" element={<Promotion />} />
                  <Route path="/admin/user" element={<User />} />
                  <Route path="/admin/voucher" element={loading ? <Loader1 /> : <Voucher />} />
                </Routes>
              </div>
            </div>
          </div>
        </>
      }
      {routHandler === "website" &&
        <div className='w-100 ease-soft-spring duration-1000'>
          <SiteHeader setUserLoginModal={setUserLoginModal} />
          <Routes>
            <Route path="/" element={loading ? <Loader1 /> : <WebsiteLanding userLoginModal={userLoginModal} setUserLoginModal={setUserLoginModal} />} />
            <Route path="/rooms" element={loading ? <Loader1 /> : <RoomList  />} />
            <Route path="/gallery" element={loading ? <Loader1 /> : <Gallery />} />
            <Route path="/about-Us" element={loading ? <Loader1 /> : <AboutUs />} />
            <Route path="/room-details" element={loading ? <Loader1 /> : <RoomDetails />} />
            <Route path="/booking-review" element={loading ? <Loader1 /> : <BookingReview  />} />
          </Routes>
        </div>
      }
    </>
  );
}

export default App;
