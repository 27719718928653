import React from 'react'
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";


export default function PlayStore() {
    return (
        <>
            <div className="w-100 bg-[#eff3f8] container py-5">
                <div className="bg-[#ffffff] md:!bg-[#eff3f8]  !rounded-xl ">
                    <div className="w-100 md:!w-[45%] mx-auto px-3 py-2 rounded-t-2xl md:!border-x-[2.5px] md:!border-t-[2.5px] border-pink bs-white flex md:!gap-2 justify-between">
                        <div className="col flex flex-col items-center">
                            <div className="">
                                <i className="fa-duotone fa-timer tx-pink fs-3"></i>
                            </div>
                            <div className="text-center">
                                <p className="text-[8px] md:!text-[12px] font-lucida-bright-regular text-nowrap font-semibold">24hr Room Serv...</p>
                            </div>
                        </div>
                        <div className="col flex flex-col items-center">
                            <div className="">
                                <i className="fa-duotone fa-battery-bolt tx-pink fs-3"></i>
                            </div>
                            <div className="text-center">
                                <p className="text-[8px] md:!text-[12px] font-lucida-bright-regular text-nowrap font-semibold">Power Backup</p>
                            </div>
                        </div>
                        <div className="col flex flex-col items-center">
                            <div className="">
                                <i className="fa-duotone fa-escalator tx-pink fs-3"></i>
                            </div>
                            <div className="text-center">
                                <p className="text-[8px] md:!text-[12px] font-lucida-bright-regular text-nowrap font-semibold">Elevator</p>
                            </div>
                        </div>
                        <div className="col flex flex-col items-center">
                            <div className="">
                                <i className="fa-duotone fa-pot-food tx-pink fs-3"></i>
                            </div>
                            <div className="text-center">
                                <p className="text-[8px] md:!text-[12px] font-lucida-bright-regular text-nowrap font-semibold">Restaurant</p>
                            </div>
                        </div>
                        <div className="col flex flex-col items-center">
                            <div className="">
                                <i className="fa-brands fa-internet-explorer tx-pink fs-3"></i>
                            </div>
                            <div className="text-center">
                                <p className="text-[8px] md:!text-[12px] font-lucida-bright-regular text-nowrap font-semibold">Internet Connection</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-100  benifit-sec-border-mix-pink !rounded-xl bs-white py-3 md:!py-4  px-1 lg:!px-5 mx-auto flex flex-col md:!flex-row justify-between bs-white">
                        <div className="flex w-100 mt-2 lg:!mt-0 mx-auto px-3 justify-center md:!justify-start items-center gap-4">
                            <div className="w-fit flex flex-col gap-3">
                                <div className="col max-w-[180px] lg:!w-[150px]">
                                    <img className='w-100' src="../../../download-Section/PlayStores.png" alt="" />
                                </div>
                                <div className="col max-w-[180px] lg:!w-[150px]">
                                    <img className='w-100' src="../../../download-Section/appStore.png" alt="" />
                                </div>
                            </div>
                            <div className="max-w-[120px] lg:!w-[100px]">
                                <img className='w-100' src="../../../download-Section/Dummy-Qr.png" alt="" />
                            </div>
                        </div>
                        <div className="w-100 md:!w-fit flex flex-col justify-between">
                            <div className="w-fit px-3 md:!px-0 flex items-center md:!items-end gap-2">
                                <div className="">
                                    <img className='min-w-[60px] max-w-[60px] md:!min-w-[60px] md:!max-w-[60px]' src="../../../download-Section/App Download .png" alt="" />
                                </div>
                                <div className="py-1 text-justify px-1">
                                    <p className='text-[16px] md:!text-[20px] font-bold leading-[30px]'>Download App Now !</p>
                                    <p className='fs-8 px-0.5 text-gray-400 font-lucida-bright-regular font-semibold'>Use code
                                        <span className='!text-[#000000] font-bold'> WELCOMEMMT </span>
                                        and get
                                        <span className='!text-[#000000] font-bold'> FLAT 12% OFF* </span>
                                        on your first domestic flight booking</p>
                                </div>
                            </div>
                            <div className="w-100 flex mt-3 px-3 md:!px-1 lg:!mx-auto items-center">
                                <div className="flex w-[70%] gap-1 items-center rounded-l-md py-1.5">
                                    <div className="col font-Montserrat w-fit  text-[12px] lg:!text-[16px] mt- fs-7">
                                        {/* <input
                                        type="tel"
                                        name="phone"
                                        placeholder='Enter mobile number'
                                    /> */}
                                        <PhoneInput
                                            country="in"
                                            placeholder='Enter mobile number'
                                            name="mobile"
                                            className="col min-w-[100%] max-w-[100%] md:!max-w-[350px] md:min-w-[300px] focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div className='w-[30%] relative z-10 text-center tracking-wider text-[12px] lg:!text-[14px] whitespace-nowrap py-[6px] border-[1px] px-2 rounded-r-md tx-blue font-lucida-bright-regular text-nowrap font-semibold border-blue'>GET APP LINK</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
