import React from 'react'
import SiteHeader from '../../components/header/SiteHeader'
import SiteHero from '../../components/Sitehero/SiteHero'
import PlayStore from '../../components/downloadSection/PlayStore'
import AttractionMap from '../attractions/AttractionMap'
import Footer from '../../components/footer/Footer'
import Gallery from '../gallery/Gallery'

export default function WebsiteLanding({ userLoginModal, setUserLoginModal }) {
    return (
        <>
            <div className="w-100 bg-[#eff3f8]">
                <SiteHero userLoginModal={userLoginModal} setUserLoginModal={setUserLoginModal} />
                <PlayStore />
                <Gallery />
                {/* <AttractionMap /> */}
                <Footer />
            </div>
        </>
    )
}
